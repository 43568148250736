import axiosInstence from './axiosInstance';

type postMethod = (url: any, data: any, config?: any) => Promise<any>
type getMethod = (url: any, config?: any) => Promise<any>
type deleteMethod = (url: any, config?: any) => Promise<any>
type putMethod = (url: any, data: any, config?: any) => Promise<any>

// get method
export const getMethod: getMethod = (url, config = {}) => {
	return new Promise(async (reslove, reject) => {
		try {
			const response = await axiosInstence.get(url, { ...config });
			// console.log("🚀 ~ file: appService.ts:12 ~ returnnewPromise ~ response", response)
			// console.log("getRequest request==>", response);
			reslove(response);
		} catch (error) {
			// console.log('getRequest error==>', error);
			reject(error)
		}
	})

};

// post method
export const postMethod: postMethod = (url, data = {}, config = {}) => {
	return new Promise(async (resolve, reject) => {
		try {
			const response = await axiosInstence.post(url, data, { ...config });
			// console.log('postRequest response==>', response);
			resolve(response);
		} catch (error) {
			// console.log('getRequest error==>', error);
			reject(error)
		}
	})
};

// delete method
export const deleteMethod: deleteMethod = (url: any, config = {}) => {
	return new Promise(async (resolve, reject) => {
		try {
			const response = await axiosInstence.delete(url, { ...config });
			// console.log('postRequest response==>', response);
			resolve(response);
		} catch (error) {
			// console.log('getRequest error==>', error);
			reject(error)
		}
	})
}

// put method
export const putMethod: putMethod = (url, data = {}, config) => {
	return new Promise(async (resolve, reject) => {
		try {
			const response = await axiosInstence.put(url, data, { ...config })
			resolve(response)
		} catch (error) {
			reject(error)
		}
	})
}
