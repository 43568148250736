import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Link, NavLink, Prompt, useHistory } from 'react-router-dom';
import { CafeNavItems } from '../Navigation/navItems';
import { Online, Offline } from 'react-detect-offline';
import './styles.scss';
import { ROUTES_PATH } from '../../../routes';
import { Col, Container, Dropdown, Row } from 'react-bootstrap';
import { setLocalStorage } from '../../../helpers/common';
import useLoadingIndicator from '../../Basic/loadingIndicator/loadingIndicator';

export default function () {

    const { hideIndicator, showIndicator, loadingIndicatorComponent } = useLoadingIndicator()


    const setStoreCafe = (nav: any) => {
        showIndicator()

        setLocalStorage('login-type', [nav.value])
        window.location.reload()
        hideIndicator()

    }
    return (
        <>
            {loadingIndicatorComponent()}
            <Row>
                <Col xs={"12"} className="nav-links-container mt-5">
                    {CafeNavItems.map((nav, index) => (
                        <button
                            key={index}
                            className='nav-item'
                            onClick={() => { setStoreCafe(nav) }}>
                            <img
                                className='nav-item-img'
                                src={nav.icon}
                                alt='nav-item-img'
                            />
                            {/* <span style={{ position: "absolute", right: "5px", color: "white" }}>{nav.hotKey}</span> */}
                            <span className='nav-item-name'> {nav.name}</span>
                        </button>
                    ))}
                </Col>
            </Row>
        </>
    )
}