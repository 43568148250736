import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { Link, NavLink, Prompt, useHistory } from 'react-router-dom';
import { navItems, cafeItems } from './navItems';
import { Online, Offline } from 'react-detect-offline';
import './styles.scss';
import {
	logout,
	sathibazar,
	userIcon,
	createBill,
	sathibazarLogo,
	syncData,
	syncDataoffline,
} from '../../../assets/icons';
import Modal from '../../Basic/Modal';
import CircularProgressBar from '../../Basic/CircularProgressBar';
import CircularProgressDone from '../../Basic/CircularProgressDone';
import { clearLocalStorage, getLocalStorage, STORAGE_KEYS } from '../../../helpers/common';
import { ROUTES_PATH } from '../../../routes';
import { Col, Container, Dropdown, Row } from 'react-bootstrap';
import ModalPopup from '../../Basic/Modalpopup/modalpopup';
import useToggleHook from '../../../hooks/useToggleHook';

export default function () {
	const [clockState, setClockState] = useState(
		new Date().toLocaleTimeString([], { timeStyle: 'short' })
	);
	const [isSyncDataModalOpen, setSyncDataModalOpen] = useState(false);
	const [isOnline, setIsOnline] = useState(false);
	const [isDBSyncDone, setIsDBSyncDone] = useState(false);
	// history from router
	const history = useHistory()
	// get user details from storage
	const userDetails = useMemo(() => {
		return getLocalStorage(STORAGE_KEYS.USER_INFO)
	}, [])
	// toggle hook for user details modal popup
	const { hideToggle, showToggle, toggle } = useToggleHook()
	// toggle hook for datasync modal popup
	const { toggle: dataSyncToggle, hideToggle: hideDataSyncToggle, showToggle: showDataSyncToggle } = useToggleHook()


	useEffect(() => {
		setInterval(() => {
			const date = new Date();
			setClockState(date.toLocaleTimeString([], { timeStyle: 'short' }));
		}, 1000);
	}, []);

	const getDBSyncStatus = () => {
		setTimeout(() => {
			hideDataSyncToggle();
		}, 2000);
	};

	// when user click logout, clear the state and navigate to login screen
	const logoutHandler = () => {
		// clear localstorage
		clearLocalStorage()
		// navigate to login page
		history.replace(ROUTES_PATH.LOGIN)
	}

	// when user click my account, open the modal popup and show the user details
	const myaccountHandler = () => {
		// show the modalpopup
		showToggle()
	}

	const polling = {
        url:'',
        enabled: true,
        interval: 200000,
        timeout: 200000
      };

	return (
		<Fragment>
			<Row className="d-none">
				{/* logo and user icon , dropdown (contains logout and myaccount), time */}
				<Col xs={"12"} className="logo-container">
					<div><img src={sathibazarLogo} alt='satibazar_logo' /></div>
					{/* row */}
					<Row className='timer-date-userdropdown'>
						{/* sync Image */}
						<Col>
							<Online polling={polling}>
								<img
									className='sync-data'
									src={syncData}
									onClick={() => {
										showDataSyncToggle()
										getDBSyncStatus();
									}}
									alt='sync_data_online'
								/>
							</Online>
							<Offline polling={polling}>
								<img
									className='sync-data'
									src={syncDataoffline}
									alt='sync_data_offline'
								/>
							</Offline>
						</Col>
						{/* clock  */}
						<Col>
							<span>{clockState}</span>
						</Col>
						{/* date */}
						<Col>
							<span>{new Date().toLocaleDateString()}</span>
						</Col>
						{/* user icon and dropdown */}
						<Col className='user-profile-icon'>
							<Dropdown className='text-capitalize'>
								<Dropdown.Toggle>
									{/* <span>{userDetails?.name_of_organization || "Guest"}</span> */}
									<img

										src={userIcon}
									/>
								</Dropdown.Toggle>
								<Dropdown.Menu >
									<Dropdown.Item onClick={myaccountHandler}>my account</Dropdown.Item>
									<Dropdown.Item onClick={logoutHandler}>logout</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						</Col>
					</Row>
				</Col>
			</Row>
			{/* menu links*/}

			<Row>
				<Col xs={"12"} className="nav-links-container">
					{(getLocalStorage(STORAGE_KEYS.LOGIN_TYPE) == 'storeadmin' ? navItems : cafeItems).map((nav, index) => (
						<NavLink
							key={index}
							className='nav-item'
							activeStyle={{ backgroundColor: '#06215f' }}
							// to='/CourseList'
							to={nav.navLink}
						// onClick={() =>
						// 	(window.location.href = nav.navLink)
						// }
						>
							<img
								className='nav-item-img'
								src={nav.icon}
								alt='nav-item-img'
							/>
							<span style={{ position: "absolute", right: "5px", color: "white" }}>{nav.hotKey}</span>
							<span className='nav-item-name'> {nav.name}</span>
						</NavLink>
					))}
				</Col>
			</Row>

			{/* {isSyncDataModalOpen && (
				<Modal
					setOpenModal={isDBSyncDone && setSyncDataModalOpen}
					header={`Database Sync : ${isDBSyncDone ? `Done` : 'Please Wait'
						}`}
					width={'33%'}
				>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							alignContent: 'center',
							textAlign: 'center',
						}}
					>
						{!isDBSyncDone && <CircularProgressBar />}

						{isDBSyncDone && <CircularProgressDone />}
					</div>
				</Modal>
			)} */}

			{/* data sync modal popup */}
			<ModalPopup
				isOpen={dataSyncToggle}
				modalTitle={`Database Sync : ${isDBSyncDone ? `Done` : 'Please Wait'}`}
				onClose={hideDataSyncToggle}
			>
				<Fragment>
					<Container className='d-flex justify-content-center align-items-center'>
						{!dataSyncToggle && <CircularProgressBar />}

						{dataSyncToggle && <CircularProgressDone />}
					</Container>
				</Fragment>
			</ModalPopup>

			{/* show my account details */}
			<ModalPopup isOpen={toggle} modalTitle={"profile"} onClose={hideToggle}>
				<Fragment>
					<Row className='mb-2'>
						{/* user name */}
						<Col xs={"12"} className={"d-flex justify-content-center text-capitalize"}>
							{/* <div>user Name <span>:</span></div> */}
							<span>user name : </span>
							<span>{(userDetails ? userDetails?.name || "Guest" : "Guest")}</span>
							{/* <div></div> */}
						</Col>
					</Row>
					<Row>
						{/* email */}
						<Col xs={"12"} className={"d-flex justify-content-center"}>
							<span className='text-capitalize'>email <span> : </span></span>
							<span>{(userDetails ? userDetails?.email || "guest" : "Guest")}</span>

						</Col>
					</Row>
				</Fragment>
			</ModalPopup>
		</Fragment>
	);

	// return (
	// 	<div className='navigation-container' style={{backgroundColor:"red"}}>
	// 		<div className='navigation-header-sec'>
	// 			<div className='sathibazar-icon'>
	// 				<img src={sathibazarLogo} alt='satibazar_logo' />
	// 			</div>

	// 			<div className='session-details'>
	// 				<Online>
	// 					<img
	// 						className='sync-data'
	// 						src={syncData}
	// 						onClick={() => {
	// 							setSyncDataModalOpen(true);
	// 							getDBSyncStatus();
	// 						}}
	// 						alt='sync_data_online'
	// 					/>
	// 				</Online>
	// 				<Offline>
	// 					<img
	// 						className='sync-data'
	// 						src={syncDataoffline}
	// 						alt='sync_data_offline'
	// 					/>
	// 				</Offline>

	// 				<span>{clockState}</span>
	// 				<span>{new Date().toLocaleDateString()}</span>

	// 				<div className='profile-sec'>
	// 					<img
	// 						style={{
	// 							width: '18px',
	// 							height: '18px',
	// 							marginLeft: '5px',
	// 						}}
	// 						src={userIcon}
	// 					/>
	// 					<span style={{ display: "none" }}>{localStorage.getItem('authUser')}</span>
	// 				</div>

	// 				<img
	// 					onClick={logoutHandler}
	// 					style={{
	// 						width: '18px',
	// 						height: '18px',
	// 						marginLeft: '15px',
	// 						cursor: 'pointer',
	// 					}}
	// 					src={logout}
	// 				/>

	// 			</div>
	// 		</div>

	// 		<div className='navigation-items-sec'>
	// 			{navItems.map((nav, index) => (
	// 				<NavLink
	// 					key={index}
	// 					className='nav-item'
	// 					activeStyle={{ backgroundColor: '#06215f' }}
	// 					// to='/CourseList'
	// 					to={nav.navLink}
	// 				// onClick={() =>
	// 				// 	(window.location.href = nav.navLink)
	// 				// }
	// 				>
	// 					<img
	// 						className='nav-item-img'
	// 						src={nav.icon}
	// 						alt='nav-item-img'
	// 					/>
	// 					<span className='nav-item-name'> {nav.name}</span>
	// 				</NavLink>
	// 			))}
	// 		</div>

	// 		{isSyncDataModalOpen && (
	// 			<Modal
	// 				setOpenModal={isDBSyncDone && setSyncDataModalOpen}
	// 				header={`Database Sync : ${isDBSyncDone ? `Done` : 'Please Wait'
	// 					}`}
	// 				width={'33%'}
	// 			>
	// 				<div
	// 					style={{
	// 						display: 'flex',
	// 						alignItems: 'center',
	// 						justifyContent: 'center',
	// 						alignContent: 'center',
	// 						textAlign: 'center',
	// 					}}
	// 				>
	// 					{!isDBSyncDone && <CircularProgressBar />}

	// 					{isDBSyncDone && <CircularProgressDone />}
	// 				</div>
	// 			</Modal>
	// 		)}
	// 	</div>
	// );
}
