type checkData = (value: any) => boolean
type stringData = (value: string) => boolean

// check wether passing value is null or not
export const isNull: checkData = (value: any) => value == null
// check wether passing value is undefined or not
export const isUndefined: checkData = (value: any) => value == undefined
// check wether value is empty string or not
export const isEmptyString: stringData = (value: string) => value == ""

// storage keys
export const STORAGE_KEYS = Object.freeze({
    USER_INFO: "user_info",
    ACCESS_TOKEN: "access_token",
    USER_DETAILS: "user_details",
    LOGIN_TYPE: "login-type"
})

// store data in localstorage
export const setLocalStorage = (key: string, data: any) => {
    localStorage.setItem(key, JSON.stringify(data))
}

// get data from localstorage, based on key
// if data not present it will return null
// if data present it will convert string to object, then it will return the data
export const getLocalStorage = (key: string) => {
    // based on key get the data
    const data: any = localStorage.getItem(key)
    // return the data
    return !isNull(data) ? JSON.parse(data) : null
}

// clear the localstorage
export const clearLocalStorage = () => {
    localStorage.clear()
}

// hot keys
export const HOT_KEYS = Object.freeze({
    F1: "f1",
    F2: "f2",
    F3: "f3",
    F4: "f4",
    F5:"f5",
    F6: "f6"
})

export const PAYMENTMETHOD: any = Object.freeze({
    CASH: "Cash",
    UPI: "UPI",
    CC_DC: "CC/DC",
})

// bill view type
export const BILL_VIEW_TYPES = Object.freeze({
    BILL_HISTORY: "bill_history",
    CREATE_BILL: "create_bill",
    REFUND_BILL: "refund_bill"
})

// increment or decrement
export const INC_DEC = Object.freeze({
    INCREMENT: "increment",
    DECREMENT: "decrement"
})

// payment status
export const PAYMENT_STATUS = Object.freeze({
    PENDING: "pending",
    PAID: "paid"
})