export const billHistoryData = [
	{
		invoiceNo: 'xxxxxxx1',
		date: '8/25/2022, 12:15:41 PM',
		contactNo: '1234567890',
		customerName: 'Raj Bhagat',
		status: 'Done',
		paymentMethod: 'Cash',
		total: '38',
		productsDetail: {
			subTotal: '700',
			discount: '20',
			tax: '200',
			totalAmount: '880',
			addedProducts: [
				{
					name: 'Rice',
					productId: 'a1214124',
					image: '',
					unitPrice: '20.00',

					unit: 'kg',
					quantitySelected: '2',
					discount: '2.00',
					gst: '2',
					total: '38',
				},
				{
					name: 'Wheat',
					productId: 'a1s4124',

					image: '',
					unitPrice: '20.00',
					unit: 'kg',
					quantitySelected: '2',
					discount: '2.00',
					gst: '2',
					total: '38',
				},
				{
					name: 'Oil',
					productId: 'a121asf4124',

					image: '',
					unitPrice: '20.00',
					unit: 'kg',
					quantitySelected: '2',
					discount: '2.00',
					gst: '2',
					total: '38',
				},
				{
					name: 'Biscuit',
					productId: 'a13214124',

					image: '',
					unitPrice: '20.00',
					unit: 'kg',
					quantitySelected: '2',
					discount: '2.00',
					gst: '2',
					total: '38',
				},
				{
					name: 'Vico',
					productId: 'qwqr124',

					image: '',
					unitPrice: '20.00',
					unit: 'kg',
					quantitySelected: '2',
					discount: '2.00',
					gst: '2',
					total: '38',
				},
				{
					name: 'Potato',
					productId: 'a1214144x',

					image: '',
					unitPrice: '20.00',
					unit: 'kg',
					quantitySelected: '2',
					discount: '2.00',
					gst: '2',
					total: '38',
				},
				{
					name: 'Mango',
					productId: 'aasf2332',

					image: '',
					unitPrice: '20.00',
					unit: 'kg',
					quantitySelected: '2',
					discount: '2.00',
					gst: '2',
					total: '38',
				},
				{
					name: 'Product 5',
					productId: 'a1214asf2z4',

					image: '',
					unitPrice: '20.00',
					unit: 'kg',
					quantitySelected: '2',
					discount: '2.00',
					gst: '2',
					total: '38',
				},
				{
					name: 'Product 5',
					productId: 'zcv1214124',

					image: '',
					unitPrice: '20.00',
					unit: 'kg',
					quantitySelected: '2',
					discount: '2.00',
					gst: '2',
					total: '38',
				},
				{
					name: 'Product 5',
					productId: 'zxf214124',

					image: '',
					unitPrice: '20.00',
					unit: 'kg',
					quantitySelected: '2',
					discount: '2.00',
					gst: '2',
					total: '38',
				},
			],
		},
	},
	{
		invoiceNo: 'xxxxxxx2',
		date: '8/25/2022, 12:15:41 PM',
		contactNo: '6464654640',
		customerName: 'Bikki Bhushan',
		status: 'Pending',
		paymentMethod: '',
		total: '38',
		productsDetail: {
			subTotal: '700',
			discount: '20',
			tax: '200',
			totalAmount: '880',
			addedProducts: [
				{
					name: 'Rice',
					productId: 'a1214124',
					image: '',

					unitPrice: '20.00',
					unit: 'kg',
					quantitySelected: '2',
					discount: '2.00',
					gst: '2',
					total: '38',
				},
				{
					name: 'Wheat',
					productId: 'a1s4124',
					image: '',
					unitPrice: '20.00',
					unit: 'kg',
					quantitySelected: '2',
					discount: '2.00',
					gst: '2',
					total: '38',
				},
				{
					name: 'Oil',
					productId: 'a121asf4124',
					image: '',
					unitPrice: '20.00',
					unit: 'kg',
					quantitySelected: '2',
					discount: '2.00',
					gst: '2',
					total: '38',
				},
				{
					name: 'Biscuit',
					productId: 'a13214124',
					image: '',
					unitPrice: '20.00',
					unit: 'kg',
					quantitySelected: '2',
					discount: '2.00',
					gst: '2',
					total: '38',
				},
			],
		},
	},
];
