import axios from 'axios';
import { getLocalStorage, STORAGE_KEYS } from '../../helpers/common';
import { API_URLS_PATH } from '../urlsConfig';

// import { SuiteLocalStorage, SuiteStorageKeys } from '../../Contracts/index';
// import { RouteStrings } from './../../../Components/Strings/RouteStrings';
// let AUTH_TOKEN = SuiteLocalStorage.get(SuiteStorageKeys.access_token, true);
// let AUTH_TOKEN = 'asfasfafafafsaf';
// let AUTH_TOKEN = 'eyJhbGciOiJSUzI1NiIsImtpZCI6ImIxYTNiYWJmLTFjYjgtNDA3OS05ZmFmLWUxZjc5NDE5MGYwNyJ9.eyJzdWIiOiJiMWM5Njc2YS1kOWY1LTRjZjAtYTNmYy1jMmQ0OWJkZTA2YWUiLCJuYW1lIjoiU3lzdGVtIFVzZXJzIiwiZW1haWwiOiJzeXN0ZW11c2VyQHNraWxsLW1pbmUuY29tIiwiaXN1YiI6IjdiYTYxNzhmLWZmMTItNDMzOS1iYmJmLWQ3NTdjOWZhZmVjNyIsImlzcyI6Imh0dHBzOi8vYWxraGFsZWVqdGVzdC1hY2NvdW50cy1hcGkuc2tpbGwtbWluZS5jb20iLCJhdWQiOiIzMmZjN2QwNy1jOTUzLTRiZTYtOGZjYi1kZjJjMmM5MTI4NzUiLCJleHAiOjE2NTkwNzI0NjEsImlhdCI6MTY1ODk4NjA2MSwibGlhdCI6MTY1ODc0MzE0MiwiYXV0aF90aW1lIjoxNjU4OTg2MDYxLCJhbXIiOlsiMCJdLCJhY3IiOiIxIiwianRpIjoiODljYmUyNWUtZTIyYi00MjBlLWE3NzItMTcxMzk0YTdkZmZiIiwiZ3JvdXBzIjpbeyJyb2xlcyI6WyJhdXRoX2FkbWluIl0sImdyb3VwX2lkIjoiYXV0aF9hZG1pbnMiLCJncm91cF9uYW1lIjoiQURNSU4ifSx7InJvbGVzIjpbImF1dGhfdXNlciJdLCJncm91cF9pZCI6ImF1dGhfdXNlcnMiLCJncm91cF9uYW1lIjoiVVNFUiJ9LHsicm9sZXMiOlsidHJhaW5pbmdfYWRtaW4iLCJzdXBlcl9hZG1pbiJdLCJncm91cF9pZCI6InRyYWluaW5nX29ubGluZV9jb3Vyc2VfdGVzdCIsImdyb3VwX25hbWUiOiJUcmFpbmluZyBPbmxpbmUgQ291cnNlIFRlc3QifSx7InJvbGVzIjpbImFkbWluIl0sImdyb3VwX2lkIjoiY291cnNlX29wZXJhdGlvbiIsImdyb3VwX25hbWUiOiJjb3Vyc2Vfb3BlcmF0aW9uIn0seyJyb2xlcyI6WyJ0cmFpbmluZ19hZG1pbiIsInN1cGVyX2FkbWluIiwiZWNvbW1lcmNlX2FkbWluIl0sImdyb3VwX2lkIjoiZWNvbW1lcmNlX2dyZWVuYmVycnlfdGVzdCIsImdyb3VwX25hbWUiOiJHcmVlbmJlcnJ5IFRlc3QifV0sInNjb3BlcyI6WyJvcGVuaWQiLCJwcm9maWxlIiwiZW1haWwiLCJwaG9uZSJdLCJzaWQiOiJhYTM5M2I3MC1mZmRmLTRiZWQtYmRlYi1kYTE3ZTVjZWY4YTciLCJ1YV9oYXNoIjoiYTA5MDk4MTBhNmQxMzI4MzJlMjhlZjZkYTE4ZWM3N2MiLCJjdHIiOiI1YWE5N2Y0MS1iY2VmLTRkMTMtOGE5YS1mYWI2ZjM3ZDExNDQiLCJkciI6IiJ9.PL8tR6jMdb6sOyosDauGgT9SRVJmPnvpbxK2a648yOe0xPHJc-DzbVOi_-vFVku4ZA-7baUoBp9HZfiV2KImS-Q-NtRtjK9zMFL-Rgc-vxgu0INKXnmtXW4zYHnLiivNbhGWlrI0_c1JiMGbdLZbJKIBviJWW3DFpuSSxf_dR-1MoD9koymesxiFDwk73vUqytKdHp8UhiLpsA9UZ1JD78ZE7ICJtJNL4eDJNbjeO8xQQQ3w8hbOCxM4F1sjzBAiGyfz37T8ozHQYaq7TSSglOuoureWMrAfVeGmPTf72IsRUSFOXL1dYAxwGQbxLWx6xdtNU8sFFaSMoiSf38HbOw';

const axiosInstence = axios.create({
	baseURL: API_URLS_PATH.APP_BASE_URL,
});

// axiosInstence.defaults.headers.common['Authorization'] = `Bearer ${AUTH_TOKEN}`;

axiosInstence.interceptors.response.use(
	(response: any) =>
		new Promise((resolve, reject) => {
			resolve(response.data);
		}),
	(error: any) => {
		if (!error.response) {
			return new Promise((resolve, reject) => {
				reject(error);
			});
		}
		if (error.response.status === 401) {
			// SuiteLocalStorage.deleteAll();
			// window.location.replace(RouteStrings.accessdenied);
		} else {
			return new Promise((resolve, reject) => {
				reject(error);
			});
		}
	}
);

// request
axiosInstence.interceptors.request.use(
	(request: any) => {
		const access_token = getLocalStorage(STORAGE_KEYS.ACCESS_TOKEN)
		request.headers = { 'Authorization': `Bearer ${access_token}` }
		// console.log("🚀 ~ file: axiosInstance.ts:43 ~ request", request)

		return new Promise((resolve, reject) => {
			resolve(request);
		})
	},
	(error: any) => {
		if (!error.request) {
			return new Promise((resolve, reject) => {
				reject(error);
			});
		}
		if (error.request.status === 401) {
			// SuiteLocalStorage.deleteAll();
			// window.location.replace(RouteStrings.accessdenied);
		} else {
			return new Promise((resolve, reject) => {
				reject(error);
			});
		}
	}
);

export default axiosInstence;
