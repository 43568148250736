import React, { useEffect, useRef, useState } from "react";
import { postMethod } from "../api/basic_configs/appService";
import { getLocalStorage } from "../helpers/common";
import { API_END_POINTS } from "../api/urlsConfig";

const useCreateOrderHook = () => {
    const [orderData, setOrderData] = useState({})
    const subID = getLocalStorage('userinfo')?.sub

    type createOrderPayload = { billing_address_id: string ,
        razorpay_order_id: string,
        razorpay_payment_id:string
    
    }
    // trigger the api
    const createOrder = async (payload: createOrderPayload) => {
        try {
            let url = API_END_POINTS.POST_CRETAE_ORDER.replace(":sub", subID)
            const resp = await postMethod(url, payload, { headers: { 'content-Type': 'application/json' } })
            // response data
            const responseData = resp.data
            // show toast message order successfukky created
        } catch (error:any) {
            // show toast message
            // error response
            const errorResponse = error?.response?.data
            // error message
            const errorMessage = errorResponse?.error?.error_description
        }
    }

    // 
    return { createOrder, orderData }
}

export default useCreateOrderHook