import React, { useState } from 'react';
import './SearchBar.css';
import { STORAGE_KEYS, getLocalStorage } from '../../../helpers/common';
// import SearchIcon from '@material-ui/icons/Search';
// import CloseIcon from '@material-ui/icons/Close';

function SearchBar({ placeholder, listData, setValue }: any) {
	const [filteredData, setFilteredData] = useState([]);
	const [wordEntered, setWordEntered] = useState('');
	// console.log("------------------>listdata", listData)
	const isStore = getLocalStorage(STORAGE_KEYS.LOGIN_TYPE).includes("storeadmin");
	const deleteItemFilteredData = listData && listData?.filter((value: any)=> value.is_deleted !== true)
	const handleFilter = (event: any) => {
		const searchWord = event.target.value;
		setWordEntered(searchWord);
		const newFilter = deleteItemFilteredData && deleteItemFilteredData.filter((value: any) => value.product_name ? value?.product_name.toLowerCase()?.includes(searchWord?.toLowerCase()) : value?.name?.toLowerCase().includes(searchWord?.toLowerCase()))
		// return value.product_name ? value.product_name : value.name
		// 	.toLowerCase()
		// 	.includes(searchWord.toLowerCase());
		// );

		// console.log(newFilter, searchWord, 'newFilter')

		if (searchWord === '') {
			setFilteredData([]);
		} else {
			setFilteredData(newFilter);
		}
	};

	const clearInput = () => {
		setFilteredData([]);
		// setWordEntered('');
	};

	return (
		<div className='search'>
			<div className='searchInputs'>
				<input
					type='text'
					placeholder={placeholder}
					value={wordEntered}
					onChange={handleFilter}
				/>
				{/* <div className='searchIcon'>
					{filteredData.length === 0 ? (
						<SearchIcon />
					) : (
						<CloseIcon id='clearBtn' onClick={clearInput} />
					)}
				</div> */}
			</div>
			{filteredData.length != 0 && (
				<div className='dataResult'>
					{filteredData && filteredData.slice(0, 15).map((value: any, key) => {
						const sellerData = isStore?"Brand"+":"+value.brand+", Seller"+":"+value?.seller_details?.name_of_organization:""
						return (
							<span
								onClick={() => {
									setValue(value);
									setWordEntered(value.product_name ? value.product_name+`${isStore?", ":""}`+sellerData: value.name+`${isStore?", ":""}`+sellerData)
									clearInput();
								}}
								className='dataItem'
								key={value.id}
							// target='_blank'
							>
								<p>{value.product_name ? value.product_name+`${isStore?", ":""}`+sellerData: value.name+`${isStore?", ":""}`+sellerData} </p>
							</span>
						);
					})}
				</div>
			)}
		</div>
	);
}

export default SearchBar;
