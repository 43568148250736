import React, { useState, useEffect, useMemo } from "react";
import Modal from "../../Basic/Modal";
import { productData } from "../../../data/dummy/productData";
import { inventory_db, fetchInventoryToDB } from "../../../data/indexedDB";
import SearchBar from "../../Basic/Search/SearchBar";
import BookData from "../../../data/dummy/Data.json";
import { toastNotifications } from "../../../helpers";
import axios from "axios";
import { Offline, Online } from "react-detect-offline";
import { getLocalStorage, STORAGE_KEYS } from "../../../helpers/common";
import { getMethod, postMethod } from "../../../api/basic_configs/appService";
import { API_END_POINTS } from "../../../api/urlsConfig";
import { Button } from "react-bootstrap";
import Select from "react-select";
import BarCodeModal from "./BarCodeModal";

export default function AddProductModal(props: any) {
  const {
    setIsAddProductModalOpen,
    isProductSearchable,
    productModalHeader,
    productAddedSuccessToast,
    setAddedProductList,
    isInventoryListLoading,
    inventoryListData,
    setProductSelectedFromItemCode,
    productSelectedFromItemCode,
    setCreateBill,
    setInventoryId,
    addedproductList,
  } = props;
  const [addedReorderStockList, setAddedReorderStockList] = useState<any>([]);
  const [productSearchString, setProductSearchString] = useState("");
  const [inventoryProducts, setInventoryProducts] = useState([]);

  const [showFullContent, setShowFullContent] = useState(false);
  const [isBarCodeModalOpen, setIsBarCodeModalOpen] = useState(false);
  const [barCodeValue, setBarcodeValue] = useState("");
  const [productSelected, setProductSelected] = useState(() => {
    if (!isProductSearchable) {
      return productSelectedFromItemCode;
    } else {
      return {};
    }
  });
  interface Product {
    _id: string;
    batch_no: string;
    current_qty: number;
    customer_pay: number;
    expiry_date: string;
  }

  interface SelectedOption {
    value: string;
    label: string;
  }
  const [selectedOption, setSelectedOption] = useState<SelectedOption | null>(
    null
  );
  const isStore = getLocalStorage(STORAGE_KEYS.LOGIN_TYPE).includes(
    "storeadmin"
  );
  const [selectedProductQuantity, setSelectedProductQuantity] = useState(1);
  const [currentQty, setCurrentQty] = useState(1);
  let onlineStatus = navigator.onLine;
  const details = useMemo(() => {
    return getLocalStorage(STORAGE_KEYS.USER_DETAILS);
  }, []);

  useEffect(() => {
    setSelectedProductQuantity(1);
    if (getLocalStorage(STORAGE_KEYS.LOGIN_TYPE).includes("storeadmin")) {
      if (addedReorderStockList.current_qty) {
        const a = addedReorderStockList.current_qty - 1;
        setCurrentQty(a);
      } else {
        const a = addedReorderStockList.quantity - 1;
        setCurrentQty(a);
      }
    }
    if (getLocalStorage(STORAGE_KEYS.LOGIN_TYPE).includes("cafeAdmin")) {
      if (
        productSelected.quantity_details &&
        productSelected.quantity_details[0]?.qty
      ) {
        const a =
          productSelected.quantity_details &&
          productSelected.quantity_details[0]?.qty - 1;
        setCurrentQty(a);
      } else {
        const a =
          productSelected.quantity_details &&
          productSelected.quantity_details[0]?.qty - 1;
        setCurrentQty(a);
      }
    }
  }, [addedReorderStockList, productSelected]);
  useEffect(() => {
    // if (onlineStatus) {
    getProductsFromInventory();
    // }
  }, []);

  const getProductsFromInventory = async () => {
    try {
      const data = {
        store_id: details.registeration_id,
      };
      let response;
      let qty_filter_data;
      if (getLocalStorage(STORAGE_KEYS.LOGIN_TYPE) == "storeadmin") {
        response = await postMethod(
          API_END_POINTS.INVENTORY_UTILS_SEARCH,
          data
        );
        qty_filter_data =
          response.data &&
          response.data?.filter((qty: any) => !(qty.current_qty <= 0));
      } else {
        response = await getMethod(
          API_END_POINTS.GET_HOTCOOK_PRODUCTS_QTY.replace(
            ":state",
            details?.location?.state
          ).replace(":store_id", details.registeration_id)
        );
        qty_filter_data =
          response.data &&
          response.data?.filter(
            (qty: any) =>
              !(
                qty?.quantity_details.length == 0 ||
                (qty?.quantity_details && qty?.quantity_details[0]?.qty <= 0)
              )
          );
      }
      setInventoryProducts(qty_filter_data);
    } catch (err) {}
  };

  // when user click add bill
  const onAddBillHandler = () => {
    if (isStore) {
      if (Object.keys(addedReorderStockList).length != 0) {
        setAddedProductList((prevArray: any) => {
          const existingProudctId = prevArray.findIndex(
            (item: any) => item._id === addedReorderStockList._id
          );

          if (existingProudctId !== -1) {
            const updatedArray = [...prevArray];

            const existingQty = Number(
              updatedArray[existingProudctId].quantitySelected
            );
            const newQty = Number(selectedProductQuantity);
            const addedQty = existingQty + newQty;
            updatedArray[existingProudctId].quantitySelected = addedQty;
            return updatedArray;
          } else {
            return [
              ...prevArray,
              {
                ...addedReorderStockList,
                quantitySelected: selectedProductQuantity,
                currentQty:
                  getLocalStorage(STORAGE_KEYS.LOGIN_TYPE) === "storeadmin" ||
                  getLocalStorage(STORAGE_KEYS.LOGIN_TYPE) === "cafeAdmin"
                    ? currentQty
                    : "N/A",
              },
            ];
          }
        });

        setCreateBill((prv: any) => {
          const existingProudctId = prv.add_product.findIndex(
            (item: any) => item._id === addedReorderStockList._id
          );

          if (existingProudctId !== -1) {
            const updatedProductList = [...prv.add_product];
            const existingQty = Number(
              updatedProductList[existingProudctId].quantitySelected
            );
            const newQty = Number(selectedProductQuantity);
            const addedQty = existingQty + newQty;
            updatedProductList[existingProudctId].quantitySelected = addedQty;
            return {
              ...prv,
              add_product: updatedProductList,
            };
          } else {
            return {
              ...prv,
              add_product: [
                ...prv.add_product,
                {
                  ...addedReorderStockList,
                  quantitySelected: selectedProductQuantity,
                  currentQty: currentQty,
                },
              ],
            };
          }
        });
        setIsAddProductModalOpen(false);
        productAddedSuccessToast("Product Added");
      }
    } else {
      if (Object.keys(productSelected).length != 0) {
        setAddedProductList((prevArray: any) => {
          const existingProductId = prevArray.findIndex(
            (item: any) => item._id === productSelected._id
          );
          if (existingProductId !== -1) {
            const updatedProdcut = [...prevArray];

            const existingQty = Number(
              updatedProdcut[existingProductId].quantitySelected
            );
            const newQty = Number(selectedProductQuantity);
            const addedQty = existingQty + newQty;
            updatedProdcut[existingProductId].quantitySelected = addedQty;

            return updatedProdcut;
          } else {
            return [
              ...prevArray,
              {
                ...productSelected,
                quantitySelected: selectedProductQuantity,
                currentQty:
                  getLocalStorage(STORAGE_KEYS.LOGIN_TYPE) == "storeadmin" ||
                  "cafeAdmin"
                    ? currentQty
                    : "N/A",
              },
            ];
          }
        });

        // add products to create bill
        setCreateBill((prv: any) => {
          const existingProudctId = prv.add_product.findIndex(
            (item: any) => item._id === productSelected._id
          );
          if (existingProudctId !== -1) {
            const updatedProductList = [...prv.add_product];

            const existingQty = Number(
              updatedProductList[existingProudctId].quantitySelected
            );
            const newQty = Number(selectedProductQuantity);
            const addedQty = existingQty + newQty;
            updatedProductList[existingProudctId].quantitySelected = addedQty;

            return {
              ...prv,
              add_product: updatedProductList,
            };
          } else {
            return {
              ...prv,
              add_product: [
                ...prv.add_product,
                {
                  ...productSelected,
                  quantitySelected: selectedProductQuantity,
                  currentQty: currentQty,
                },
              ],
            };
          }
        });

        // close the modal popup
        setIsAddProductModalOpen(false);
        // show the toast message
        productAddedSuccessToast("Product Added");
      }
    }
  };
  const options = productSelected?.ReorderedInventoryData?.filter(
    (product: Product) => product.current_qty > 0
  )?.map((product: Product) => {
    const formattedDate = new Date(product.expiry_date).toLocaleDateString();
    return {
      value: product._id,
      label: `Stock: ${product.batch_no} Quantity: ${
        product.current_qty
      } Price: ${product.customer_pay?.toFixed(2)} Exp Date: ${formattedDate}`,
    };
  });

  const handleSelectChange = (selected: any) => {
    setSelectedOption(selected);
    setInventoryId(productSelected._id);
    if (selected != null) {
      const filteredSelectedData: any =
        productSelected?.ReorderedInventoryData.filter((ele: any) => {
          return ele._id === selected.value;
        });
      setAddedReorderStockList(filteredSelectedData && filteredSelectedData[0]);
    } else {
      setAddedReorderStockList(productSelected);
    }
  };
  const toggleContent = () => {
    setIsBarCodeModalOpen(true);
  };

  return (
    <Modal
      header={productModalHeader}
      width={"60%"}
      setOpenModal={setIsAddProductModalOpen}
    >
      {/* {productSearchString} */}
      {!isInventoryListLoading ? (
        <>
          {isProductSearchable && (
            <>
              <div className="d-flex gap-5">
                <div className="w-100">
                  <SearchBar
                    placeholder="Enter product name"
                    listData={
                      inventoryProducts.length !== 0 && inventoryProducts
                    }
                    setValue={setProductSelected}
                    productSelected={productSelected}
                  />
                </div>
                {isStore && (
                  <div className="w-100">
                    <Select
                      className=""
                      value={selectedOption}
                      onChange={handleSelectChange}
                      options={options}
                      isSearchable={true}
                      placeholder="Select Stock"
                      isClearable={true}
                    />
                  </div>
                )}
              </div>
            </>
          )}
          <div className="addproduct-container">
            <div
              className="table-column-header"
              style={{ marginBottom: "50px" }}
            >
              <div className="table-item">Category</div>
              {getLocalStorage(STORAGE_KEYS.LOGIN_TYPE) == "storeadmin" && (
                <div className="table-item">Brand</div>
              )}
              <div className="table-item">Product</div>
              {isStore && <div className="table-item">Bar Code</div>}
              <div className="table-item">Price/Unit(Rs)</div>
            </div>
            {isStore
              ? Object.keys(addedReorderStockList).length != 0 && (
                  <div>
                    <div
                      style={{
                        border: "none",
                        margin: "0 10px",
                      }}
                      className="table-column-header"
                    >
                      <div className="addedproduct-item">
                        {addedReorderStockList.category}
                      </div>
                      {getLocalStorage(STORAGE_KEYS.LOGIN_TYPE) ==
                        "storeadmin" && (
                        <div className="addedproduct-item">
                          {addedReorderStockList.brand
                            ? addedReorderStockList.brand
                            : "-"}
                        </div>
                      )}
                      <div className="addedproduct-item">
                        {addedReorderStockList.product_name
                          ? addedReorderStockList?.product_name?.length > 50
                            ? addedReorderStockList?.product_name?.slice(
                                0,
                                50
                              ) + "..."
                            : addedReorderStockList?.product_name
                          : addedReorderStockList?.name?.length > 50
                          ? addedReorderStockList?.name?.slice(0, 50) + "..."
                          : addedReorderStockList?.name}
                      </div>

                      <div className={`addedproduct-item text-break `}>
                        <div>
                          {addedReorderStockList.barCode.substring(0, 20)}
                          {addedReorderStockList.barCode.length > 20 && (
                            <>...</>
                          )}
                          {addedReorderStockList.barCode.length > 20 && (
                            <span
                              className="fw-bold"
                              onClick={() => {
                                toggleContent();
                                setBarcodeValue(addedReorderStockList.barCode);
                              }}
                            >
                              {"More"}
                            </span>
                          )}
                        </div>
                      </div>
                      <div className="addedproduct-item">
                        {addedReorderStockList?.customer_pay
                          ? addedReorderStockList?.customer_pay?.toFixed(2)
                          : addedReorderStockList?.unitPrice
                          ? addedReorderStockList?.unitPrice?.toFixed(2)
                          : addedReorderStockList?.price?.toFixed(2)}
                        /
                        {addedReorderStockList?.unit
                          ? addedReorderStockList?.unit
                          : "1"}
                      </div>
                    </div>

                    <div className="added-product-quantity">
                      <span>Quantity</span>
                      <div
                        onClick={() => {
                          if (selectedProductQuantity >= 2) {
                            setSelectedProductQuantity(
                              selectedProductQuantity - 1
                            );
                          }
                          if (selectedProductQuantity > 1) {
                            setCurrentQty(currentQty + 1);
                          }
                          if (selectedProductQuantity >= 0) {
                            addedReorderStockList.quantity =
                              parseInt(addedReorderStockList.quantity) + 1;
                          }
                        }}
                      >
                        <button
                          style={{
                            marginBottom: "4px",
                          }}
                          disabled={addedReorderStockList?.current_qty <= 0}
                        >
                          -
                        </button>
                      </div>
                      <input
                        style={{ width: "10%" }}
                        onChange={(e: any) =>
                          setSelectedProductQuantity(e.target.value)
                        }
                        value={selectedProductQuantity}
                        min={1}
                        max={100}
                        type={"number"}
                        className="table-row-item-input"
                      />
                      <div>
                        <button
                          style={{
                            marginBottom: "4px",
                          }}
                          onClick={() => {
                            setSelectedProductQuantity(
                              selectedProductQuantity + 1
                            );
                            if (selectedProductQuantity >= 0) {
                              setCurrentQty(currentQty - 1);
                            }
                            if (selectedProductQuantity >= 0) {
                              addedReorderStockList.quantity =
                                parseInt(addedReorderStockList.quantity) - 1;
                            }
                          }}
                          disabled={
                            addedReorderStockList?.current_qty <= 0 ||
                            currentQty == 0
                          }
                        >
                          +
                        </button>
                      </div>

                      {getLocalStorage(STORAGE_KEYS.LOGIN_TYPE).includes(
                        "storeadmin"
                      ) && (
                        <div style={{ width: "auto", height: "20px" }}>
                          <span
                            style={{
                              //marginBottom: '4px',
                              fontSize: "10px",
                              color: "red",
                              padding: "4px",
                            }}
                          >
                            {addedReorderStockList?.current_qty <= 0
                              ? "Out Of Stock"
                              : `${currentQty}/Lefts`}
                          </span>
                        </div>
                      )}
                      {getLocalStorage(STORAGE_KEYS.LOGIN_TYPE).includes(
                        "cafeAdmin"
                      ) && (
                        <div style={{ width: "auto", height: "20px" }}>
                          <span
                            style={{
                              //marginBottom: '4px',
                              fontSize: "10px",
                              color: "red",
                              padding: "4px",
                            }}
                          >
                            {addedReorderStockList?.quantity_details.length == 0
                              ? "Out Of Stock"
                              : `${currentQty}/Lefts`}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                )
              : Object.keys(productSelected).length != 0 && (
                  <div>
                    <div
                      style={{
                        border: "none",
                        margin: "0 10px",
                      }}
                      className="table-column-header"
                    >
                      <div className="addedproduct-item">
                        {productSelected.category}
                      </div>
                      {getLocalStorage(STORAGE_KEYS.LOGIN_TYPE) ==
                        "storeadmin" && (
                        <div className="addedproduct-item">
                          {productSelected.brand ? productSelected.brand : "-"}
                        </div>
                      )}
                      <div className="addedproduct-item">
                        {productSelected.product_name
                          ? productSelected.product_name
                          : productSelected.name}
                      </div>
                      <div className="addedproduct-item">
                        {productSelected?.customer_pay
                          ? productSelected?.customer_pay?.toFixed(2)
                          : productSelected?.unitPrice
                          ? productSelected?.unitPrice?.toFixed(2)
                          : productSelected?.price?.toFixed(2)}
                        /{productSelected?.unit ? productSelected?.unit : "1"}
                      </div>
                    </div>

                    <div className="added-product-quantity">
                      <span>Quantity</span>
                      <div
                        onClick={() => {
                          if (selectedProductQuantity >= 2) {
                            setSelectedProductQuantity(
                              selectedProductQuantity - 1
                            );
                          }
                          if (selectedProductQuantity > 1) {
                            setCurrentQty(currentQty + 1);
                          }
                          if (selectedProductQuantity >= 0) {
                            productSelected.quantity =
                              parseInt(productSelected.quantity) + 1;
                          }
                        }}
                      >
                        <button
                          style={{
                            marginBottom: "4px",
                          }}
                          disabled={productSelected?.current_qty <= 0}
                        >
                          -
                        </button>
                      </div>
                      <input
                        style={{ width: "10%" }}
                        onChange={(e: any) =>
                          setSelectedProductQuantity(e.target.value)
                        }
                        value={selectedProductQuantity}
                        min={1}
                        max={100}
                        type={"number"}
                        className="table-row-item-input"
                      />
                      <div>
                        <button
                          style={{
                            marginBottom: "4px",
                          }}
                          onClick={() => {
                            setSelectedProductQuantity(
                              selectedProductQuantity + 1
                            );
                            if (selectedProductQuantity >= 0) {
                              setCurrentQty(currentQty - 1);
                            }
                            if (selectedProductQuantity >= 0) {
                              productSelected.quantity =
                                parseInt(productSelected.quantity) - 1;
                            }
                          }}
                          disabled={
                            productSelected?.current_qty <= 0 || currentQty == 0
                          }
                        >
                          +
                        </button>
                      </div>

                      {getLocalStorage(STORAGE_KEYS.LOGIN_TYPE).includes(
                        "storeadmin"
                      ) && (
                        <div style={{ width: "auto", height: "20px" }}>
                          <span
                            style={{
                              //marginBottom: '4px',
                              fontSize: "10px",
                              color: "red",
                              padding: "4px",
                            }}
                          >
                            {productSelected?.current_qty <= 0
                              ? "Out Of Stock"
                              : `${currentQty}/Lefts`}
                          </span>
                        </div>
                      )}
                      {getLocalStorage(STORAGE_KEYS.LOGIN_TYPE).includes(
                        "cafeAdmin"
                      ) && (
                        <div style={{ width: "auto", height: "20px" }}>
                          <span
                            style={{
                              //marginBottom: '4px',
                              fontSize: "10px",
                              color: "red",
                              padding: "4px",
                            }}
                          >
                            {productSelected?.quantity_details.length == 0
                              ? "Out Of Stock"
                              : `${currentQty}/Lefts`}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                )}
          </div>

          <Button
            className="add-to-bill-buttons"
            disabled={
              isStore
                ? addedReorderStockList.current_qty <= 0
                : productSelected.current_qty <= 0
            }
            onClick={onAddBillHandler}
          >
            Add to Bill
          </Button>
        </>
      ) : (
        <div> Loading </div>
      )}
      {isBarCodeModalOpen && (
        <BarCodeModal
          barcode={barCodeValue}
          setIsBarCodeModalOpen={setIsBarCodeModalOpen}
        />
      )}
    </Modal>
  );
}
