import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { BrowserRouter as Router, Redirect, Switch, useHistory } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
// Import Routes all
import { authProtectedRoutes, publicRoutes, ROUTES_PATH } from './routes';

// Import all middleware
import Authmiddleware from './routes/route';

// layouts Format
import LoggedIn from './components/Sections/Layout/LoggedIn';
import LoggedOut from './components/Sections/Layout/LoggedOut';
import hotkeys from 'hotkeys-js';
import 'bootstrap/dist/css/bootstrap.min.css';
import { HOT_KEYS } from './helpers/common';
import useLoadScript from './hooks/loadScript';

const App = (props: any) => {
	const [path, setPath] = useState('')
	const { loadscript } = useLoadScript()
	const history = useHistory();
	useEffect(() => {
		// when user press f1 navigate to create-bill history
		hotkeys(HOT_KEYS.F1, (event, handler) => {
			// Prevent the default refresh event under WINDOWS system
			event.preventDefault()
			// setPath('/create-bill')
			// history.replace(ROUTES_PATH.CREATE_BILL)
			window.location.replace(ROUTES_PATH.CREATE_BILL)

		});
		hotkeys(HOT_KEYS.F2, function (event, handler) {
			// Prevent the default refresh event under WINDOWS system
			event.preventDefault()
			// setPath('/bill-history')
			window.location.replace(ROUTES_PATH.BILL_HISTORY)

		});
		hotkeys(HOT_KEYS.F3, function (event, handler) {
			// Prevent the default refresh event under WINDOWS system
			event.preventDefault()
			// setPath('/inventory')
			window.location.replace(ROUTES_PATH.INVENTORY)
		});
		// hotkeys(HOT_KEYS.F4, function (event, handler) {
		// 	// Prevent the default refresh event under WINDOWS system
		// 	event.preventDefault()
		// 	// setPath('/refund')
		// 	window.location.replace(ROUTES_PATH.REFUND)
		// });
		hotkeys(HOT_KEYS.F4, function (event, handler) {
			// Prevent the default refresh event under WINDOWS system
			event.preventDefault()
			// setPath('/account')
			window.location.replace(ROUTES_PATH.ACCOUNT)
		});
		hotkeys(HOT_KEYS.F5, function (event, handler) {
			// Prevent the default refresh event under WINDOWS system
			event.preventDefault()
			// setPath('/account')
			window.location.replace(ROUTES_PATH.BARCODE)
		});
	}, [])
	useEffect(() => {
		// load razor pay script
		loadscript("https://checkout.razorpay.com/v1/checkout.js")
	  }, []);
	return (
		<React.Fragment>

			<Router>
				{/* <Redirect to={path} /> */}
				<Switch>
					{publicRoutes.map((route: any, idx: any) => (
						<Authmiddleware
							path={route.path}
							layout={LoggedOut}
							component={route.component}
							key={idx}
							isAuthProtected={false}
							exact
						/>
					))}

					{authProtectedRoutes.map((route, idx) => (
						<Authmiddleware
							path={route.path}
							layout={LoggedIn}
							component={route.component}
							key={idx}
							isAuthProtected={true}
							exact
						/>
					))}
				</Switch>
			</Router>
		</React.Fragment>
	);
};
App.propTypes = {
	layout: PropTypes.any,
};
export default App;
