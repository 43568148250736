import React, { useEffect, useState } from 'react';
import { bill_history_db, inventory_db } from '../../../data/indexedDB';
import './styles.scss';
import { ToastContainer } from 'react-toastify';
import posBanner from '../../../assets/images/pos-banner.png';
import { syncData, syncDataoffline } from '../../../assets/icons';
import { login_db, fetchLoginToDb } from '../../../data/indexedDB';
import { toastNotifications } from '../../../helpers/toastNotifications';
import { Offline, Online } from 'react-detect-offline';
import axios from 'axios';
import { Container, Row, Col, Button } from "react-bootstrap"
import { API_END_POINTS, API_URLS_PATH, SECRET } from '../../../api/urlsConfig';
import { useLocation } from 'react-router-dom';
export default function () {
	const [empId, setEmpId] = useState('');
	const [empPass, setEmpPass] = useState('');
	// const [fieldActive, setFieldActive] = useState('id');
	const [userListData, setUserListData] = useState([]);
	const [isListLoading, setIsListLoading] = useState(true);
	const [requestId, setRequestId] = useState("")
	const [isDisableButton, setIsDisableButton] = useState(true)
  const location = useLocation();
  const [errorDescription, setErrorDescription] = useState<string | null>(null);
  const capitalizeFirstLetter = (str: string) => {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  };
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const errorParam = searchParams.get('error');
    const errorDescriptionParam = searchParams.get('error_description');
    if (errorParam && errorDescriptionParam) {
      setErrorDescription(capitalizeFirstLetter(errorDescriptionParam));
    }
  }, [location.search]);
	/* -------------------------------- functions ------------------------------- */
	// useEffect(()=>{
	// 	getProductsFromInventory();
	// },[])

	const getUserListFromDB = async () => {
		let data = await login_db.data.toArray();
		if (data.length != 0) {
			setUserListData(data);
			setIsListLoading(false);
		} else {
			fetchLoginToDb();
			getUserListFromDB();
		}
	};

	const handleLogin = async () => {
		let onlineStatus = navigator.onLine;

		if (checkInputValidation()) {
			if (onlineStatus) {
				//login with backend api
				//check with idb and push data idb accordigly

				if (empId.includes('onid') && empPass.includes('onpass')) {

					toastNotifications.success(
						'Login Successfully with online method'
					);
					localStorage.setItem('authUser', empId);

					window.location.href = '/';

					//check and push to db
					let checkuserIDBPresent = await checkUserCreds();
					if (checkuserIDBPresent == 'login_success') {
					} else {
						login_db.data.add({
							userName: empId,
							userPassword: empPass,
						});
					}
				} else {
					//wrong userdetails
					toastNotifications.error(
						'Wrong Details with online method '
					);
				}
			} else {
				if ((await checkUserCreds()) == 'login_success') {
					localStorage.setItem('authUser', 'id');
					toastNotifications.success(
						'login success with offline method'
					);

					window.location.href = '/';
				}
				if ((await checkUserCreds()) == 'wrong_pass') {
					toastNotifications.error('Error correct password');
				}
				if ((await checkUserCreds()) == 'wrong_id') {
					toastNotifications.warning('Error correct id');
				}
			}
		} else {
			toastNotifications.warning('Enter User Details');
		}
	};

	const checkInputValidation = () => {
		if (empId.length > 0 && empPass.length > 0) {
			return true;
		} else {
			return false;
		}
	};

	const checkUserCreds = async () => {
		let empIdCheck = await login_db.data
			.where('userName')
			.equals(empId)
			.toArray();

		if (empIdCheck.length > 0) {
			if (empPass == empIdCheck[0].userPassword) {
				return 'login_success';
			} else {
				return 'wrong_pass';
			}
		} else {
			return 'wrong_id';
		}

		// return false;
	};

	// old code
	// const logInUser = async () => {
	// 	try {
	// 		let { data } = await axios.post(
	// 			`https://sathibazartest-accounts-api.skill-mine.com/authz-srv/authz/generate`,
	// 			{
	// 				client_id: 'ae379d92-f91b-48fb-8b84-2856c524629e',
	// 				redirect_uri: 'http://localhost:3008/handle/token',
	// 				response_type: 'token',
	// 				scope: 'openid profile email phone',
	// 			}
	// 		);
	// 		window.localStorage.setItem('authUser', "pos_cashier");
	// 		const respData = data.data
	// 		// setRequestId(data.data._id)
	// 		setRequestId(respData._id)
	// 		const responseId = await axios.post
	// 			(`https://sathibazartest-accounts-api.skill-mine.com/public-srv/login/settings/by/requestid/${respData._id}`)

	// 	} catch (error) {
	// 	}
	// }

	// get the requestID
	const logInUser = async () => {
		try {
			const payload = {
				client_id: SECRET.CLIENT_ID,
				redirect_uri: API_URLS_PATH.REDIRECT_URL,
				// redirect_uri: 'http://localhost:3000/handle/token',
				response_type: 'token',
				scope: 'openid profile email phone',
			}
			// send the data
			// console.log(`${API_URLS_PATH.ACCOUNTS_API}${API_END_POINTS.AUTHZ_GENERATE}`)
			let { data } = await axios.post(`${API_URLS_PATH.ACCOUNTS_API}${API_END_POINTS.AUTHZ_GENERATE}`, payload);
			// 
			// window.localStorage.setItem('authUser', "pos_cashier");
			// response data
			const respData = data.data
			// url
			const url = `${API_URLS_PATH.ACCOUNTS_API}${API_END_POINTS.SETTINGS_REQUESTID}`.replace(":reqID", respData._id)
			// send requestID
			const responseId = await axios.post(url)
			// response data
			const responseData = responseId.data
			// update the requestID state
			setRequestId(respData._id)
		} catch (error) {
		}
	}

	/* -------------------------------- useEffts -------------------------------- */

	useEffect(() => {
		// getUserListFromDB();
		logInUser()
	}, []);

	// if employee ID and employee password entered, then enable the login button
	// else disable the login button
	useEffect(() => {
		if (empId != "" && empPass != "") {
			setIsDisableButton(false)
		}
		else {
			setIsDisableButton(true)
		}
	}, [empId, empPass])

	// on submit
	// const onSubmit = () => {
	// 	alert("submit")
	// 	const response = {
	// 		email: empId,
	// 		password: empPass,
	// 	}
	// 	var urlencoded = new URLSearchParams()
	// 	urlencoded.append("username", empId)
	// 	urlencoded.append("password", empPass)
	// 	urlencoded.append("request_id", requestId)
	// 	const myHeaders = new Headers();
	// 	myHeaders.append('Content-Type', 'application/x-www-form-urlencoded; charset=UTF-8');
	// 	myHeaders.get('Content-Type');
	// }
	const polling = {
		url: '',
		enabled: true,
		interval: 100000,
		timeout: 100000
	};

	return (
		<Container fluid={true} className="login-container">
			<ToastContainer
				autoClose={1}
				hideProgressBar={true}
				closeButton={false}
			/>
			<Row className='form-image-container'>
				{/* image */}
				<Col sm={"12"} md={"6"} className="image-view">
					<img
						// style={{ width: '50%', height: '50%' }}
						src={posBanner}
						alt='pos_banner'
					/>
				</Col>
				{/* form */}
				<Col sm={"12"} md={"6"} className="form-view">
					<Row className='sync'>
						{/* sync */}
						<Col xs={"12"}>
							{/* <div className='login-text'> */}
							<Online polling={polling}>
								<img
									className='sync-data'
									src={syncData}
									alt='sync_data_online'
								/>
							</Online>
							<Offline polling={polling}>
								<img
									className='sync-data'
									src={syncDataoffline}
									alt='sync_data_offline'
								/>
							</Offline>
							{/* </div> */}
						</Col>
					</Row>

					{/* login title */}
					<Row className="form-container">
						<Col xs="12" className='wrapper' style={{ height: "100%" }}>
							<Container >
								{/* title */}
								<Row className='form-title'>
									<Col xs="12">
										Log In
									</Col>
								</Row>
								{/* fields */}
								<Row className="fields-container">
									<Col xs={"12"}>
										{/* form */}
										<form
											// action='https://sathibazartest-accounts-api.skill-mine.com/login-srv/login'
											action={API_URLS_PATH.REACT_APP_LOGIN_SRV}
											// action={`${API_URLS_PATH.ACCOUNTS_API}${API_END_POINTS.LOGIN}`}
											method='post'
											className='form-login'
										>
											{/* employee ID */}
											<Row><Col xs={"12"} className='invalid-user-text'>{errorDescription && errorDescription}</Col></Row>
											<Row className='field'>
												<Col xs={"12"}>
													<span className="field-title">Employee ID</span>
													<input
														// onFocus={() => setFieldActive('id')}
														// type={'number'}
														name='username'
														value={empId}
														onChange={(e) => {
															// setFieldActive('id');
															setEmpId(e.target.value);
														}}
														className='input-field'
													/>
												</Col>
											</Row>
											{/* password */}
											<Row className='field'>
												<Col xs={"12"}>
													<span className="field-title">Password</span>
													<input
														// autoComplete={false}
														name='password'
														type={'password'}
														value={empPass}
														// onFocus={() => setFieldActive('pass')}
														onChange={(e) => {
															// setFieldActive('pass');
															setEmpPass(e.target.value);
														}}
														className='input-field'
													/>
													<input type="hidden" value={requestId} name="request_id" />
												</Col>
											</Row>
											{/* login and clear button */}
											<Row className='clear-login-buttons'>
												{/* login button */}
												<Button
													disabled={isDisableButton}
													type='submit'
												>
													Login
												</Button>
												{/* clear button */}
												<Button
													onClick={() => {
														setEmpId('');
														setEmpPass('');
													}}
												>
													Clear
												</Button>
											</Row>
										</form>
									</Col>
								</Row>
							</Container>
						</Col>
					</Row>
				</Col>
			</Row>
		</Container>
	);

	// return (
	// 	<div className='login-container'>
	// 		<ToastContainer
	// 			autoClose={1}
	// 			hideProgressBar={true}
	// 			closeButton={false}
	// 		/>
	// 		<img
	// 			style={{ width: '50%', height: '100vh' }}
	// 			src={posBanner}
	// 			alt='pos_banner'
	// 		/>

	// 		<div className='enter-login-details-header-sec'>
	// 			<div className='login-text'>
	// 				<Online>
	// 					<img
	// 						className='sync-data'
	// 						src={syncData}
	// 						alt='sync_data_online'
	// 					/>
	// 				</Online>
	// 				<Offline>
	// 					<img
	// 						className='sync-data'
	// 						src={syncDataoffline}
	// 						alt='sync_data_offline'
	// 					/>
	// 				</Offline>
	// 				<div
	// 					style={{
	// 						position: 'absolute',
	// 						bottom: 0,
	// 						fontSize: '3em',
	// 					}}
	// 				>
	// 					Log In
	// 				</div>
	// 			</div>
	// 			<form
	// 			  action='https://sathibazartest-accounts-api.skill-mine.com/login-srv/login'
	// 			  method='post'
	// 			  >
	// 				<div className='enter-login-details-sec'>
	// 					<div className='input-field-section'>
	// 						<div className='input-header'>Employee ID</div>
	// 						<input
	// 							// onFocus={() => setFieldActive('id')}
	// 							// type={'number'}
	// 							name='username'
	// 							value={empId}
	// 							onChange={(e) => {
	// 								// setFieldActive('id');
	// 								setEmpId(e.target.value);
	// 							}}
	// 							className='input-field'
	// 						/>
	// 					</div>
	// 					<div
	// 						style={{ marginTop: '20px' }}
	// 						className='input-field-section'
	// 					>
	// 						<span className='input-header'>Password</span>
	// 						<input
	// 							// autoComplete={false}
	// 							name='password'
	// 							type={'password'}
	// 							value={empPass}
	// 							// onFocus={() => setFieldActive('pass')}
	// 							onChange={(e) => {
	// 								// setFieldActive('pass');
	// 								setEmpPass(e.target.value);
	// 							}}
	// 							className='input-field'
	// 						/>
	// 						<input type="hidden" value={requestId} name="request_id"/>
	// 					</div>
	// 					<div className='submit-sec'>
	// 						<button
	// 							className='login-reset-clear'
	// 							onClick={() => {
	// 								setEmpId('');
	// 								setEmpPass('');
	// 							}}
	// 						>
	// 							Clear
	// 						</button>
	// 						<button
	// 							// disabled
	// 							style={
	// 								checkInputValidation()
	// 									? {
	// 										color: '#088bfe',
	// 										borderColor: '#088bfe',
	// 										cursor: 'pointer',
	// 									}
	// 									: {
	// 										borderColor: 'grey',
	// 										color: 'grey',
	// 									}
	// 							}
	// 							className='login-submit'
	// 							// onClick={() => {
	// 							// 	handleLogin();
	// 							// }}
	// 							type='submit'
	// 						>
	// 							Login
	// 						</button>
	// 					</div>
	// 				</div>
	// 			</form>
	// 		</div>
	// 	</div>
	// );
}
