export const inventoryData = [
	{
		name: 'Wheat',
		productId: 'xxxxxx1',
		category: 'Grains',
		brand: 'Ashirvaad',
		quantity: '20',
		unitPrice: '500',
		unit: 'kg',
		gst: '20',
		discount: '20',
		dateAdded: '20/08/2022',
		expiry: '20/08/2023',
		variants: [
			{
				productId: 'asf3143',
				name: 'variant1',
				unit: 'kg',
				unitPrice: '200',
				quantity: '52',
				gst: '5',
				discount: '50',
				expiry: '20/08/2023',
			},
		],
	},
	{
		name: 'Rice',
		productId: 'xxxxxas1',
		category: 'Grains',
		brand: 'Ashirvaad',
		quantity: '20',
		unitPrice: '500',
		unit: 'kg',
		gst: '21',
		discount: '20',
		dateAdded: '20/08/2022',
		expiry: '20/08/2023',
	},
	{
		name: 'Pulses',
		productId: 'xx21xx1',
		category: 'Grains',
		brand: 'Ashirvaad',
		quantity: '20',
		unitPrice: '500',
		unit: 'kg',
		gst: '28',
		discount: '20',
		dateAdded: '20/08/2022',
		expiry: '20/08/2023',
	},
	{
		name: 'Biscuit',
		productId: 'xxas2xx1',
		category: 'Category 1',
		brand: 'Ashirvaad',
		quantity: '20',
		unitPrice: '500',
		unit: 'kg',
		gst: '12',
		discount: '20',
		dateAdded: '20/08/2022',
		expiry: '20/08/2023',
	},
	{
		name: 'Jeera',
		productId: 'xxa13x1',
		category: 'Category 2',
		brand: 'Ashirvaad',
		quantity: '20',
		unitPrice: '500',
		unit: 'kg',
		gst: '42',
		discount: '20',
		dateAdded: '20/08/2022',
		expiry: '20/08/2023',
	},
	{
		name: 'Cold Drink',
		productId: 'xxaq51',
		category: 'Cold Drinks',
		brand: 'Coca',
		quantity: '20',
		unitPrice: '500',
		unit: 'kg',
		gst: '21',
		discount: '20',
		dateAdded: '20/08/2022',
		expiry: '20/08/2023',
	},
	{
		name: 'Product 1',
		productId: 'xxa12q51',
		category: 'Category 7',

		brand: 'Brand1',
		quantity: '20',
		unitPrice: '500',
		unit: 'kg',
		gst: '26',
		discount: '20',
		dateAdded: '20/08/2022',
		expiry: '20/08/2023',
	},
	{
		name: 'Product 2',
		productId: 'xxa12zq51',
		category: 'Category 9',

		brand: 'Brand1',
		quantity: '20',
		unitPrice: '500',
		unit: 'kg',
		gst: '72',
		discount: '20',
		dateAdded: '20/08/2022',
		expiry: '20/08/2023',
	},
	{
		name: 'Product 3',
		productId: 'xxa12m51',
		category: 'Category 78',

		brand: 'Brand1',
		quantity: '20',
		unitPrice: '500',
		unit: 'kg',
		gst: '12',
		discount: '20',
		dateAdded: '20/08/2022',
		expiry: '20/08/2023',
	},
	{
		name: 'Product 4',
		productId: 'xxazzz51',
		category: 'Categor 102',
		brand: 'Brand1',
		quantity: '20',
		unitPrice: '500',
		unit: 'kg',
		gst: '2',
		discount: '20',
		dateAdded: '20/08/2022',
		expiry: '20/08/2023',
	},
];
