import React from 'react';
import Modal from '../../../components/Basic/Modal';
// import ProductTable from '../CreateBill/ProductTable';
// import { billHistoryData } from './billHistoryData';
import BillDetails from '../../../components/Sections/BillDetails';
import RefundDetails from './RefundDetails';

export default function RefundDetailsModal(props: any) {
	const { setIsBillDetailsModalOpen, billData } = props;
	return (
		<Modal
			// style= {}
			// header={`Bill Details ${
			// 	billData.status == 'Pending'
			// 		? '-  Payment ' + billData.status
			// 		: ''
			// }`}
			header={`Bill Details - (Refunded)`}
			width={'80%'}
			height={'auto'}
			setOpenModal={setIsBillDetailsModalOpen}
		>
			<RefundDetails
				billData={billData}
				billViewType={'bill_history'}
				setIsBillDetailsModalOpen={setIsBillDetailsModalOpen}
			/>
		</Modal>
	);
}
