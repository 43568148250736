import React from "react";
import { Modal } from "react-bootstrap";
import "./modalpopupStyle.scss"

type modalPopupProps = {
    modalTitle: string,
    children: JSX.Element,
    isOpen: boolean,
    onClose: () => void
}

const ModalPopup = (props: modalPopupProps) => {
    // destructure the props
    const { children, isOpen, modalTitle, onClose } = props

    return (
        <Modal show={isOpen} className={"modal-popup-container"}>
            {/* modal title */}
            <Modal.Header closeButton onHide={onClose}>
                <Modal.Title className="text-capitalize">{modalTitle}</Modal.Title>
            </Modal.Header>
            {/* modal body */}
            <Modal.Body>
                {children}
            </Modal.Body>
        </Modal>
    )
}

export default ModalPopup