import React from 'react';
import BillDetails from '../../../components/Sections/BillDetails';

export default function () {
	return (
		<div>
			<BillDetails billData={[]} billViewType={'create_bill'} />
		</div>
	);
}
