export const filterBillHistoryListBySearchString = (
	searchString: any,
	data: any
) => {
	let filteredData = data.filter((value: any) => {
		return (
			value.invoiceNo
				.toLowerCase()
				.includes(searchString.toLowerCase()) ||
			value.contactNo
				.toLowerCase()
				.includes(searchString.toLowerCase()) ||
			value.customerName
				.toString()
				.toLowerCase()
				.includes(searchString.toLowerCase()) ||
			value.date
				.toString()
				.toLowerCase()
				.includes(searchString.toLowerCase()) ||
			value.status
				.toString()
				.toLowerCase()
				.includes(searchString.toLowerCase())
		);
	});
	return filteredData;
};
