import React, { useEffect, useState } from "react";
import "./styles.scss";

import removeIcon from "../../../assets/icons/delete.svg";
import {
  calculationBill,
  productGst,
  productTotal,
} from "./billingCalculations";
import { BILL_VIEW_TYPES, INC_DEC } from "../../../helpers/common";
import { getLocalStorage, STORAGE_KEYS } from "../../../helpers/common";

export const calculateoverallItemTax = (products: any, isCafe: boolean) => {
  let totalamountprice = 0;

  products.forEach((product: any) => {
    let gross;
    // const price = product.price ? Number(product.price) : 0;
    if (isCafe) {
      // let commissionValues;
      // let priceCommissionSum;

      // commissionValues = product.nafed_commission
      //   ? Number(product.nafed_commission)
      //   : 0;
      // priceCommissionSum = price + commissionValues;
      let priceCommissionSum = product?.storePay;
      const qtyValue = product.qty ? product.qty : product.quantitySelected;
      gross = priceCommissionSum * qtyValue;
    } else {
      // const commissionValues = product?.commission_values?.map((ele: any) => {
      //   return ele.brokerage_rate;
      // });
      // const sum = commissionValues.reduce(
      //   (accumulator: any, currentValue: any) => accumulator + currentValue,
      //   0
      // );
      // const priceCommissionSum = price + Number(sum);
      let priceCommissionSum = product?.storePay;
      gross =
        priceCommissionSum *
        parseFloat(product.qty ? product.qty : product.quantitySelected);
    }
    let grossDisc =
      parseFloat(product.discount) *
      parseFloat(product.qty ? product.qty : product.quantitySelected);
    let netValue = gross - grossDisc;
    let netGst = gross * (parseFloat(product.gst) / 100);
    let netGsttotal = netGst + gross;

    totalamountprice += netGsttotal; // Accumulate the tax for each product to the totalTax variable
  });

  return totalamountprice.toFixed(2);
};
export const calculateTotal = (products: any[], isCafe: boolean) => {
  let total = 0;
  if (isCafe) {
    products.forEach((product) => {
      // const price = product.price ? Number(product.price) : 0;
      // let commissionValues;
      // let priceCommissionSum;

      // commissionValues = product.nafed_commission
      //   ? Number(product.nafed_commission)
      //   : 0;
      // priceCommissionSum = price + commissionValues;
      let priceCommissionSum = product?.storePay;
      const qtyValue = product.qty ? product.qty : product.quantitySelected;
      total += priceCommissionSum * qtyValue;
    });
  } else {
    products.forEach((product) => {
      // const price = product.price ? Number(product.price) : 0;
      // const commissionValues = product?.commission_values?.map((ele: any) => {
      //   return ele.brokerage_rate;
      // });
      // const sum = commissionValues.reduce(
      //   (accumulator: any, currentValue: any) => accumulator + currentValue,
      //   0
      // );
      // const priceCommissionSum = price + Number(sum);
      let priceCommissionSum = product?.storePay;
      total +=
        priceCommissionSum *
        parseFloat(product.qty ? product.qty : product.quantitySelected);
    });
  }

  return total;
};

export const calculatePerItemTax = (products: any, isCafe: boolean) => {
  let totalTax = 0;

  products.forEach((product: any) => {
    let gross;
    // const price = product.price ? Number(product.price) : 0;
    if (isCafe) {
      // let commissionValues;
      // let priceCommissionSum;

      // commissionValues = product.nafed_commission
      //   ? Number(product.nafed_commission)
      //   : 0;
      // priceCommissionSum = price + commissionValues;
      let priceCommissionSum = product?.storePay;
      const qtyValue = product.qty ? product.qty : product.quantitySelected;
      gross = priceCommissionSum * qtyValue;
    } else {
      // const commissionValues = product?.commission_values?.map((ele: any) => {
      //   return ele.brokerage_rate;
      // });
      // const sum = commissionValues.reduce(
      //   (accumulator: any, currentValue: any) => accumulator + currentValue,
      //   0
      // );
      // const priceCommissionSum = price + Number(sum);
      let priceCommissionSum = product?.storePay;
      gross =
        priceCommissionSum *
        parseFloat(product.qty ? product.qty : product.quantitySelected);
    }

    let grossDisc =
      parseFloat(product.discount) *
      parseFloat(product.qty ? product.qty : product.quantitySelected);
    let netValue = gross - grossDisc;
    let netGst = gross * (parseFloat(product.gst) / 100);

    totalTax += netGst; // Accumulate the tax for each product to the totalTax variable
  });

  return totalTax.toFixed(2);
};
export default function ProductTable(props: any) {
  const {
    productDeletedToast,
    addedproductList,
    clcProductEditFncAvailabilty,
    setAddedProductList,
    getSubTotalAmount,
    productSubDetails,
    billViewType,
    setToBePaid,
    setDeletedData,
    billData,
    totalCalculation,

    setCreateBill,
    createBill,
    metaDataQty,
    onClearBillHandler,
  } = props;
  const isStore = getLocalStorage(STORAGE_KEYS.LOGIN_TYPE).includes(
    "storeadmin"
  );

  return (
    <div className="product-table-container">
      <div className="table-column-header">
        <div style={{ width: "5%" }} className="table-item">
          Sno.
        </div>
        <div className="table-item">Product Name</div>
        {isStore && <div className="table-item">Stock No.</div>}
        <div className="table-item">Price/Unit (Rs)</div>
        <div className="table-item">Qty</div>
        {/* <div className='table-item'>Discount (Rs)</div> */}
        <div className="table-item">GST (%)</div>
        <div className="table-item">Total</div>
        {clcProductEditFncAvailabilty() && (
          <div style={{ width: "5%" }} className="table-item"></div>
        )}
      </div>

      <div className="table-row-details">
        {/* OLD CODE */}

        {/* if we have products the display */}
        {createBill?.add_product && createBill?.add_product?.length > 0 ? (
          <AddedProductTable
            productData={addedproductList}
            productSubDetails={productSubDetails}
            setAddedProductList={setAddedProductList}
            productDeletedToast={productDeletedToast}
            removeIcon={removeIcon}
            clcProductEditFncAvailabilty={clcProductEditFncAvailabilty}
            getSubTotalAmount={getSubTotalAmount}
            billViewType={billViewType}
            setToBePaid={setToBePaid}
            setDeletedData={setDeletedData}
            billData={billData}
            totalCalculation={totalCalculation}
            setCreateBill={setCreateBill}
            createBill={createBill}
            metaDataQty={metaDataQty}
            onClearBillHandler={onClearBillHandler}
          />
        ) : (
          <NoProductsContainer />
        )}
      </div>
    </div>
  );
}

const AddedProductTable = ({
  productData,
  productDeletedToast,
  removeIcon,
  clcProductEditFncAvailabilty,
  setAddedProductList,
  getSubTotalAmount,
  productSubDetails,
  billViewType,
  setToBePaid,
  setDeletedData,
  billData,
  totalCalculation,

  setCreateBill,
  createBill,
  onClearBillHandler,
  metaDataQty,
}: any) => {
  const isCafe = getLocalStorage(STORAGE_KEYS.LOGIN_TYPE).includes("cafeAdmin");
  const handleDeleteProduct = (detail: any) => {
    let tempData = createBill.add_product;
    const deletedData = tempData.filter((el: any) => el._id !== detail._id);

    if (deletedData.length === 0) {
      onClearBillHandler();
    } else {
      setCreateBill({
        ...createBill,
        add_product: deletedData,
      });
    }
  };

  const calculatePerItemSubTotal = (product: any) => {
    let gross =
      parseFloat(product.price ? product.price : product.unitPrice) *
      parseFloat(product.qty ? product.qty : product.quantitySelected);

    return gross;
  };

  const calculatePerItemDiscount = (product: any) => {
    let disc =
      parseFloat(product.discount) *
      parseFloat(product.qty ? product.qty : product.quantitySelected);

    return disc;
  };

  const totalTax = calculatePerItemTax(createBill?.add_product, isCafe);

  const totalamountprice = calculateoverallItemTax(
    createBill?.add_product,
    isCafe
  );
  const calculatePerItemTotal = (product: any) => {
    const gstval = product.storePay;
    const tax = product.gst / 100;
    let gross =
      parseFloat(product.price ? product.price : product.unitPrice) *
      parseFloat(product.qty ? product.qty : product.quantitySelected);
    let grossDisc =
      parseFloat(product.discount) *
      parseFloat(product.qty ? product.qty : product.quantitySelected);
    let netValue = gross - grossDisc;
    let netGst = gross * (parseFloat(product.gst) / 100);
    let totalPay =
      gstval * parseFloat(product.qty ? product.qty : product.quantitySelected);

    return totalPay;
    //return gross;
  };
  //calculation of store price

  const calculateStorePrice = (product: any) => {
    const priceCommissionSum = product?.storePay;
    return priceCommissionSum;
  };

  const tableTotalAmount = (product: any, qty: any) => {
    const priceCommissionSum = calculateStorePrice(product);
    const totalAmt = priceCommissionSum * qty;
    return totalAmt;
  };

  const calculateGst = (product: any) => {
    const price = parseFloat(product.price);
    const gst = parseFloat(product.gst ? product.gst : 0);
    const qty = parseFloat(product.quantitySelected);
    let basePrice = price;
    let storePrice = basePrice;
    //calculate gst amount

    let gstAmount = gst;
    return gstAmount;
  };
  let customerPay = parseFloat(createBill?.add_product[0]?.customer_pay);
  let price = parseFloat(createBill?.add_product[0]?.price);
  let tax = parseFloat(createBill?.tax);
  const calculateTotalAmount = () => {
    calculationBill(productData);

    let total = 0;
    for (let i = 0; i < productData.length; i++) {
      total = total + calculatePerItemTotal(productData[i]);
    }
    setToBePaid(total.toFixed(2));
    return total.toFixed(2);
  };
  const calculateSubTotal = () => {
    // let total = 0;
    // for (let i = 0; i < productData.length; i++) {
    // 	total = total + calculatePerItemTotal(productData[i]);
    // }
    let total: any = calculateTotalAmount();
    let tax: any = calculateTotalTax();
    let subTotal = total - tax;

    return subTotal.toFixed(2);
  };
  const calculateTotalDiscount = () => {
    let total = 0;
    for (let i = 0; i < productData.length; i++) {
      total = total + calculatePerItemDiscount(productData[i]);
    }
    return total.toFixed(2);
  };

  const calculateTotalTax = () => {
    let total = 0;
    for (let i = 0; i < productData.length; i++) {
      total = total + calculateGst(productData[i]);
    }
    return total.toFixed(2);
  };

  // Assuming your createBill variable contains the list of products in the add_product array

  const totalAmount = createBill?.add_product
    ? calculateTotal(createBill.add_product, isCafe)
    : 0;
  // increment and decrement, quantity
  const increment_or_decrement_qty = (
    type: string,
    productItem: any,
    productIndex: number
  ) => {
    switch (true) {
      // if type is increment, increase the qty
      case type == INC_DEC.INCREMENT:
        if (productItem?.quantitySelected)
          productItem.quantitySelected = productItem.quantitySelected + 1;
        else productItem.qty = productItem.qty + 1;
        break;
      // if type is decrement, decrease the qty
      case type == INC_DEC.DECREMENT:
        if (productItem?.quantitySelected)
          productItem.quantitySelected = productItem.quantitySelected - 1;
        else productItem.qty = productItem.qty - 1;
        break;
    }
    let tempArray = [...createBill.add_product];
    // update the index
    tempArray.splice(productIndex, 1, productItem);
    // update state
    setCreateBill({ ...createBill, add_product: tempArray });
  };
  const isStore = getLocalStorage(STORAGE_KEYS.LOGIN_TYPE).includes(
    "storeadmin"
  );
  return (
    <React.Fragment>
      <div className="product-list-container">
        {createBill?.add_product &&
          createBill.add_product.length > 0 &&
          createBill.add_product.map((item: any, index: any) => {
            const detail = item;
            const firstselect = detail?.ReorderedInventoryData?.batch_no;

            return (
              <div key={index} className="product-row-detail">
                <div style={{ width: "5%" }} className="table-row-item">
                  {index + 1}
                </div>
                {/* brand / product_name */}
                <div className="table-row-item">
                  {detail.product_name || detail.name}
                </div>
                {isStore && (
                  <div className="table-row-item">
                    {detail.batch_no || detail.batch_no || firstselect}
                  </div>
                )}
                {/* price field */}
                <div className="table-row-item">
                  {calculateStorePrice(detail).toFixed(2)}
                </div>

                {/* increment the qty and decrement the qty */}
                <div className="table-row-item">
                  {billViewType === BILL_VIEW_TYPES.CREATE_BILL ? (
                    <button
                      className="inc-dec"
                      onClick={() => {
                        increment_or_decrement_qty(
                          INC_DEC.DECREMENT,
                          detail,
                          index
                        );
                      }}
                      disabled={
                        (detail?.quantitySelected
                          ? detail.quantitySelected < 2
                          : detail.qty < 2) || detail.disableDecrement
                      }
                    >
                      -
                    </button>
                  ) : (
                    ""
                  )}

                  {detail.quantitySelected
                    ? detail.quantitySelected
                    : detail.qty}

                  {billViewType === BILL_VIEW_TYPES.CREATE_BILL ? (
                    <button
                      className="inc-dec-left"
                      onClick={() => {
                        increment_or_decrement_qty(
                          INC_DEC.INCREMENT,
                          detail,
                          index
                        );
                      }}
                      disabled={
                        (isCafe
                          ? (detail?.quantitySelected || detail?.qty) >=
                            detail?.quantity_details?.[0]?.qty
                          : detail?.quantitySelected
                          ? detail.quantitySelected >= detail.current_qty
                          : detail.qty >= detail.current_qty) ||
                        detail.disableIncrement
                      }
                    >
                      +
                    </button>
                  ) : (
                    ""
                  )}
                </div>

                {/* old code */}
                <div className="table-row-item">
                  {productGst(
                    detail.price,
                    detail.gst,
                    detail.quantitySelected
                      ? detail.quantitySelected
                      : detail.qty
                  )}
                </div>
                <div className="table-row-item">
                  {tableTotalAmount(
                    detail,
                    detail.quantitySelected
                      ? detail.quantitySelected
                      : detail.qty
                  ).toFixed(2)}

                  {/* {createBill.sub_total} */}
                </div>
                {clcProductEditFncAvailabilty() && (
                  <div
                    style={{ width: "5%" }}
                    className="table-row-item"
                    onClick={() => {
                      handleDeleteProduct(detail);
                      productDeletedToast("Deleted");
                    }}
                  >
                    <img
                      src={removeIcon}
                      style={{
                        width: "20px",
                        height: "20px",
                        cursor: "pointer",
                      }}
                    />
                  </div>
                )}
              </div>
            );
          })}
      </div>

      {/* display total amount, total tax, subtotal */}
      <div className="total-section">
        {/* subtotal */}
        <div>
          Sub Total :
          <span
            style={{
              fontWeight: "bold",
              marginLeft: "50px",
            }}
          >
            {totalAmount.toFixed(2)}
          </span>
        </div>
        {/* tax */}
        <div>
          Tax:
          <span
            style={{
              fontWeight: "bold",
              display: "inline-block",
              marginLeft: "50px",
            }}
          >
            {" "}
            {totalTax}
          </span>
        </div>
        <div>
          Total Amount :
          <span
            style={{
              fontWeight: "bold",
              marginLeft: "50px",
            }}
          >
            {totalamountprice}
          </span>
        </div>
      </div>
    </React.Fragment>
  );
};

const NoProductsContainer = () => (
  <div className="no-products-section">Add Products</div>
);
