import React, { useEffect, useRef, useState } from "react";
// import { get, put, post } from "services/basic_config/api_helper"
import { getLocalStorage } from "../helpers/common";
// import useCreateOrderHook from "./createOrderHook";
import { useHistory } from "react-router-dom"
import { getMethod,postMethod,putMethod } from "../api/basic_configs/appService";
import { API_END_POINTS } from "../api/urlsConfig";
import useCreateOrderHook from "./createOrderHook";
import { toast, ToastContainer } from 'react-toastify';


const useRazorPayHook = () => {
    const history = useHistory()

    const [keys, setKeys] = useState({key_id:''})
    const [isError, setIsError] = useState(false)
    const [error, setError] = useState(null)
    const [orderData, setOrderData] = useState({id:''})
    const [address, setAddress] = useState({_id:'',given_name:'',family_name:'',mobile:''})
    const [totalAmount, setPrice] = useState(0)
    const [upiPaymentSuccess, setUpiPaymentSuccess] = useState(false)
    const [ccPaymentSuccess, setCcPaymentSuccess] = useState(false)
    const [storeDetails, setStoreDetails] = useState<any>()
    const [method, setMethod] = useState("")
    // call create order hook
    const { createOrder, orderData: createOrderData } = useCreateOrderHook()
    // reset the state
    const resetState = () => {
        // setAmount(null)
        setIsError(false)
        setError(null)
        setAddress({_id:'',given_name:'',family_name:'',mobile:''})
        setOrderData({id:''})
    }
    // get payment keys
    const getPaymentKeys = async () => {
        try {
            setError(null)
            setIsError(false)
            // get the payment keys
            const paymentkeys = await getMethod(API_END_POINTS.GET_PAYMENT_KEY, { headers: { 'content-Type': 'application/json' } })
            // const paymentkeys = await http.get(API_ENDPOINTS.GET_PAYMENT_KEYS)
            const response = paymentkeys?.data
            setKeys(response)
        } catch (error:any) {
            setError(error)
            setIsError(true)
        }
    }
    useEffect(() => {
        getPaymentKeys()
    }, [])

    // verify the payment
    const verifyPayment = (payload:any) => {
        const storeData = storeDetails.map((details: any) => ({
            ...details,
            amount: Number(totalAmount),
          }));
       
        return new Promise(async (resolve, reject) => {
            let payloadData = {
                ...payload,
                storeDetails : storeData                
            }
            try {
                const respone = await postMethod(API_END_POINTS.POST_VERIFY_PAYMENT, payloadData, { headers: { 'content-Type': 'application/json' } })
                // response data
                const responseData = respone?.data
                if (responseData) {
                    if(responseData && method == "UPI")
                {setUpiPaymentSuccess(true)}
                else if(responseData && method == "CC/DC")
                {setCcPaymentSuccess(true)}

                toast.success('Payment Success', {
					position: "top-center",
					autoClose: 3000,
					hideProgressBar: false,
					closeOnClick: true,
					pauseOnHover: true,
					draggable: true,
					progress: undefined,
					theme: "light",
				});
                }else{
                    setUpiPaymentSuccess(false);
                    setCcPaymentSuccess(false);
                }
                // return the response
                resolve(responseData)
            } catch (error:any) {
                // payment faield
                reject(error?.response?.data)
            }
        })
    }

    // open razoor pay popup
    const openRazorPayPopup = async () => {
        setAddress(getLocalStorage('userdetails'))
        getPaymentKeys()
        setTimeout(() => {
            // check amount should not be null && keys need to present
            // totalAmount && address && keys?.key_id && orderData
            if (totalAmount && address && keys?.key_id && orderData) {
                // // get order id
                // await getOrderID()
                // razorpay options
                const options = {
                    "key": keys?.key_id, // Enter the Key ID generated from the Dashboard
                    "amount": Math.ceil(Number(totalAmount) * 100), // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
                    "currency": "INR",
                    "name": `${address?.given_name} ${address?.family_name}`,
                    // "description": "Test Transaction",
                    // "image": "https://example.com/your_logo",
                    "order_id": orderData?.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
                    "handler": razorpayResponse,
                    "prefill": {
                        "name": `${address?.given_name} ${address?.family_name}`,
                        "email": getLocalStorage('user_info').email,
                        "contact": `${address?.mobile}`
                    },
                    "hidden": {
                        "email": true
                    },
                    // "notes": {
                    //     "address": "Razorpay Corporate Office"
                    // },
                    "theme": {
                        "color": "#07bc0c",
                        "hide_topbar": "true"
                    }
                };
                if (typeof window !== 'undefined') {
                    // let razorpay = new window.Razorpay(options)
                    let razorpay = new (window as any).Razorpay(options)
                    razorpay.open()
                    razorpay.on('payment.failed', function (response:any) {
                        // alert(response.error.code);
                        // alert(response.error.description);
                        // alert(response.error.source);
                        // alert(response.error.step);
                        // alert(response.error.reason);
                        // alert(response.error.metadata.order_id);
                        // alert(response.error.metadata.payment_id);
                    })
                }
            }
            else {
                // setError((!amount ? "amount error"))
            }
        }, 1000)
    }

    useEffect(() => {
        openRazorPayPopup()
    }, [orderData])

    // handler razorpay
    const razorpayResponse = async (razorpay_response: any) => {
        try {
            const response: any = await verifyPayment(razorpay_response);
    
            // Check if the payment was successful
            if (response.success) {
                // Close the Razorpay modal
                razorpay_response?.close();               
                // Perform any additional actions, such as navigating to a new page
                // ...
            } else {
                // Handle unsuccessful payment
            }
        } catch (error) {
            // Handle errors
        }
    };
    

    // get order id
    const getOrderID = async (customer_pay: any, storeData: any) => {
        try {
          const payload = storeData.map((details: any) => ({
            ...details,
            amount: (Number(customer_pay)),
          }));
      
          const payloadData = {
            amount: Math.ceil(Number(customer_pay)* 100),
            currency: "INR",
            storeDetails: payload,
          };
      
          const response = await postMethod(API_END_POINTS.POST_CRETAE_ORDER_ITEM, payloadData, {
            headers: { 'content-Type': 'application/json' },
          });
      
          const respData = response?.data;
      
          // if order id won't get throw error
          if (response?.data && respData?.id && respData?.id !== "") {
            // set the order state
            setOrderData(respData);
            setTimeout(() => {
              // openRazorPayPopup()
            }, 1000);
          } else {
            throw { response: { data: 'unable to place order' } };
          }
        } catch (error: any) {
          const errorResponse = error?.response?.data;
          // set error to true
          setIsError(true);
          // set error to state
          setError(errorResponse);
        }
      };
      

    // open razorpay
    const openRazorPay = async (customer_pay:any, method: any, payload: any) => {
        let obj = getLocalStorage('user_info')
        // set addess
        setMethod(method)
        setAddress(obj)
        setPrice(customer_pay)
        setStoreDetails(payload)
        try {
            // get order id
            await getOrderID(customer_pay, payload)           
        } catch (error) {
        }
    }
    return { openRazorPay, setAddress, isError, error, upiPaymentSuccess, ccPaymentSuccess, setUpiPaymentSuccess, setCcPaymentSuccess}

}
export default useRazorPayHook
