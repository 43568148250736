// export the url
// all are present in .env files, based on envirnoment this url will vary
export const API_URLS_PATH = {
  REDIRECT_URL: process.env.REACT_APP_REDIRECT_URL,
  CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
  ACCOUNTS_API: process.env.REACT_APP_ACCOUNTS_API_SKILLMINE,
  APP_BASE_URL: process.env.REACT_APP_BASE_URL,
  REACT_APP_LOGIN_SRV: process.env.REACT_APP_LOGIN_SRV,
};
// secrets
export const SECRET = {
  CLIENT_ID: process.env.REACT_APP_CLIENT_ID,
};

// api end points
export const API_END_POINTS = {
  LOGIN: "/login-srv/login",
  SETTINGS_REQUESTID: "/public-srv/login/settings/by/requestid/:reqID",
  AUTHZ_GENERATE: "/authz-srv/authz/generate",
  AUTH_GET_USER_INFO: "/user-srv/user-info/details/:sub",
  GET_ALL_BILL_HISTORY: "/pos-create-bill/all/:store_id",
  GET_STORE_DETAILS: "/registartion-srv/store/:request_id",
  POS_CREATE_BILL_ACCOUNT: "/pos-create-bill/account/:admin_id",
  POS_CAFESTORE_BILL_ACCOUNT:
    "/pos-create-bill/account/:id?login_type=:login_type",
  CREATE_BILL: "/pos-create-bill",
  INVENTORY_SEARCH: "/inventory-srv/inventory/utils/search",
  POS_BILL_COUNT: "/pos-create-bill/pos-orders/count/:store_id",
  USER_INFO: "/users-srv/users/userinfo",
  INVENTORY_UTILS_SEARCH: "/inventory-srv/inventory/utils/search",
  ADD_LOOSE_PRODUCTS:"/loose-products/add",
  INVENTORY_UTILS_SEARCH_CAFE: "/hotcook-products/all",
  GET_HOTCOOK_PRODUCTS_QTY: "hotcook-quantity-details/all/cafe/:state/:store_id",
  REORDERED_INVENTORY:"/reordered-inventory/get/:batch_id",
  CREATE_BILL_SEARCH: "/pos-create-bill/search",
  UPDATE_POS_CREATE_BILL: "/pos-create-bill/:invoice_id",
  QR_CODE_API: "/payment-gateway/create/razorpay/qrcode",
  GET_PAYMENT_KEYS: "/payment-key/all",
  GET_PAYMENT_KEY: "/payment-gateway/key",
  POST_CRETAE_ORDER_ITEM: "/payment-gateway/create/orderitem",
  POST_VERIFY_PAYMENT: "/payment-gateway/payment/verify",

  // create order
  POST_CRETAE_ORDER: "/order-srv/create/order/:sub",

  //
  POST_BARCODE: "inventory-srv/inventory/barcode",
  GET_STATE_ABBREVIATION : "/sathibazar/location/state/:stateName"
};
