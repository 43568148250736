import React, { useState } from "react";
import { Col, Modal, Row } from "react-bootstrap"
import { Watch } from 'react-loader-spinner'

type show_hide_toogle_Indicator = () => void
type loadingIndicator = (loadingText?: string) => JSX.Element

const useLoadingIndicator = () => {
    // show or hide state
    const [isOpen, setIsOpen] = useState<boolean>(false)

    // hide function
    const hideIndicator: show_hide_toogle_Indicator = () => {
        // make state as false
        setIsOpen(false)
    }

    // show function
    const showIndicator: show_hide_toogle_Indicator = () => {
        // make state as true
        setIsOpen(true)
    }

    // toggle function
    const toggleIndicator: show_hide_toogle_Indicator = () => {
        // make state true| false, or vice versa
        setIsOpen(prv => !prv)
    }

    // component
    const loadingIndicatorComponent: loadingIndicator = (loadingText = "loading") => {
        return <Modal
            show={isOpen}
            onHide={hideIndicator}
            backdrop="static"
            keyboard={false}
            centered
        >
            <Modal.Body className="d-flex flex-column justify-content-center align-items-center gap-3">

                <Row>
                    <Col xs="12">
                        <Watch
                            height="100"
                            width="100"
                            radius="48"
                            color="#4fa94d"
                            ariaLabel="watch-loading"
                            wrapperStyle={{}}
                                    // wrapperClassName=""
                            visible={isOpen}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" className="text-capitalize" style={{fontSize:20}}>{loadingText} ...</Col>
                </Row>
            </Modal.Body>
        </Modal>
    }

    return { showIndicator, hideIndicator, toggleIndicator, loadingIndicatorComponent }
}

export default useLoadingIndicator