// import React from 'react';
// import { Redirect } from 'react-router-dom';

import Health from '../components/Sections/Health';
import CreateBill from '../pages/LoggedIn/CreateBill';
import BillingHistory from '../pages/LoggedIn/BillingHistory';
import Barcode from '../pages/LoggedIn/Barcode';

import Report from '../pages/LoggedIn/Inventory';

import Login from '../pages/LoggedOut/Login';
import Account from '../pages/LoggedIn/Account';
import Refund from '../pages/LoggedIn/Refund';
//import { Redirect } from 'react-router-dom';
import LoginSuccess from '../pages/LoggedIn/LoginSuccess';
import HandleToken from '../pages/LoggedOut/HandleToken';
// import { Redirect } from 'react-router-dom';

export const ROUTES_PATH = Object.freeze({
	HOME: "/",
	BILL_HISTORY: "/bill-history",
	//REFUND: "/refund",
	CREATE_BILL: "/create-bill",
	ACCOUNT: "/account",
	INVENTORY: "/inventory",
	HANDLE_TOKEN: "/handle/token",
	LOGIN: "/login",
	HEALTH: "/health",
	BARCODE:"/barcode",
})

const authProtectedRoutes = [

	{ path: ROUTES_PATH.HOME, exact: true, component: LoginSuccess, },

	{ path: ROUTES_PATH.BILL_HISTORY, component: BillingHistory },
	//{ path: ROUTES_PATH.REFUND, component: Refund },
	{ path: ROUTES_PATH.CREATE_BILL, component: CreateBill },
	{ path: ROUTES_PATH.BARCODE, component: Barcode },

	{ path: ROUTES_PATH.ACCOUNT, component: Account },
	{ path: ROUTES_PATH.INVENTORY, component: Report },
	{ path: ROUTES_PATH.HANDLE_TOKEN, component: HandleToken }
];

const publicRoutes = [
	{ path: ROUTES_PATH.LOGIN, component: Login },
	{ path: ROUTES_PATH.HEALTH, component: Health },
	{ path: ROUTES_PATH.HANDLE_TOKEN, component: HandleToken }

];

export { authProtectedRoutes, publicRoutes };
