// import axios from 'axios';
import React, { useEffect, useMemo, useState } from 'react';
import { cardSalesData } from './cardDetailsData';
import { dollar, emptyCart, chart } from '../../../assets/icons';
// import PosNetRevenue from './ComparativeRop';
// import MarketTrends from './MarketTrends';
// import PaymentStatus from './PaymentStatus';
import './styles.scss';
import { getMethod } from '../../../api/basic_configs/appService';
import { API_END_POINTS } from '../../../api/urlsConfig';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { getLocalStorage, STORAGE_KEYS } from '../../../helpers/common';

type cardView = {
	icon: any,
	alt: string,
	backgroundColor?: string,
	key: number,
	heading: string,
	count: string | number
}

// formate function
type formateResponseData = (payload: any) => Array<any>

export default function () {
	// const [accountData, setAccountData] = useState(null as any)

	// const [outOfStock, setOutOfStock] = useState(null as any)
	// const [totalSale, setTotalSale] = useState(null as any)
	// let data = {} as any;
	const [data, setData] = useState<Array<any>>([]);
	const userDetails = useMemo(() => {
		return getLocalStorage(STORAGE_KEYS.USER_DETAILS)
	}, [])

	// console.log("🚀 ~ file: index.tsx:28 ~ userDetails ~ userDetails", userDetails.registeration_id)

	// formate the data

		/**
		 * data: 
			out_of_stock_products :	{heading: 'Out of Stock Products', number: Array(0)}
			pos_net_revenue	: {heading: 'POS Net Revenue', number: Array(0)}
			total_sale_today : {heading: 'Total Sale Today', number: Array(0)}
		 */
			const formateResponseData: formateResponseData = (payload) => {
				const formatedData = Object.keys(payload).map((value: any) => {
				  const obj = payload[value];
				  const heading = obj.heading;
				  let count = 0;
			  
				  if (heading === 'Out of Stock Products') {
					  count = obj?.number[0]?.count || 0;
				  } else {
					const loginType = getLocalStorage(STORAGE_KEYS.LOGIN_TYPE)[0];
					if (loginType === 'cafeAdmin') {
					  count = obj?.number.find((item: { _id?: string[] }) => item?._id?.[0] === 'cafeAdmin')?.count || 0;
					} else if (loginType === 'storeadmin') {
					  count = obj?.number.find((item: { _id?: string[] }) => item?._id?.[0] === 'storeadmin')?.count || 0;
					}
				  }
			  
				  let icon_backgroundColor: any = (heading === "POS Net Revenue" ? { icon: dollar, backgroundColor: "rgb(253, 169, 80)", alt: "dollar image" } :
					(heading === "Total Sale Today" ? { icon: chart, backgroundColor: "rgb(0, 197, 142)", alt: "chart image" } :
					  (heading === "Out of Stock Products" ? { icon: emptyCart, backgroundColor: "rgb(6, 33, 95)", alt: "cart image" } : "")
					));
			  
				  return { heading, count, ...icon_backgroundColor };
				});
			  
				return formatedData;
			  };
			  
			  
			  
			  

	// get the store account data, based on store id
	const getAccountData = async () => {
		try {
		    const login_type = localStorage.getItem("login-type")
			
			if (login_type) {
				const loginTypeArray = JSON.parse(login_type);
			const url = API_END_POINTS.POS_CAFESTORE_BILL_ACCOUNT.replace(":id", userDetails.registeration_id).replace(":login_type", loginTypeArray[0]);
			// console.log("🚀 ~ file: index.tsx:38 ~ getAccountData ~ url", url)
			// get method
			const resp = await getMethod(url)
			// console.log("🚀 ~ file: index.tsx:31 ~ getAccountData ~ resp", resp)
			const respData = resp.data
			// formate the response data
			const formatedData = formateResponseData(respData)
			// console.log("🚀 ~ file: index.tsx:69 ~ getAccountData ~ formatedData", formatedData)
			// set data to state
			setData(formatedData)
		} else {
			console.error("login_type is null");
			// Handle the case where login_type is null, e.g., set a default value or show an error
		}
		}
		catch (error: any) {
			const errorResponseData = error.response.data
			// console.log("🚀 ~ file: index.tsx:44 ~ getAccountData ~ errorResponse", errorResponseData)
			// alert message
			const alertMessage = errorResponseData.code == 404 ? "data not found !" : "something went wrong!"
			// show alert message
			alert(alertMessage)
		}
	}

	useEffect(() => {
		getAccountData()
	}, [])

	// card
	const cardView: (data: cardView) => JSX.Element = ({ heading, count, icon, alt, backgroundColor, key }) => {
		return <Card style={{ backgroundColor: backgroundColor }} key={key}>
			<Card.Body>
				<Row>
					<Col xs="6" className='d-flex justify-content-center align-items-center'>
						<Row className='d-flex flex-column align-items-center text-center gap-1'>
							{/* header */}
							<Col>{heading}</Col>
							{/* count is type of number | string, if it is number convert to string, and pass value as a string to 
							parseFloat function. */}
							<Col>{parseFloat(count.toString()).toFixed(2)}</Col>
						</Row>
					</Col>
					{/* icon */}
					<Col xs="6" className='d-flex justify-content-center align-items-center'>
						<img src={icon} alt={alt} />
					</Col>
				</Row>
			</Card.Body>
		</Card>
	}

	// const getAccountData = async () => {
	// 	try {

	// 		const adminToken = "eyJhbGciOiJSUzI1NiIsImtpZCI6ImIxYTNiYWJmLTFjYjgtNDA3OS05ZmFmLWUxZjc5NDE5MGYwNyJ9.eyJzdWIiOiJiMWM5Njc2YS1kOWY1LTRjZjAtYTNmYy1jMmQ0OWJkZTA2YWUiLCJuYW1lIjoiU3lzdGVtIFVzZXJzIiwiZW1haWwiOiJzeXN0ZW11c2VyQHNraWxsLW1pbmUuY29tIiwiaXN1YiI6IjdiYTYxNzhmLWZmMTItNDMzOS1iYmJmLWQ3NTdjOWZhZmVjNyIsImlzcyI6Imh0dHBzOi8vYWxraGFsZWVqdGVzdC1hY2NvdW50cy1hcGkuc2tpbGwtbWluZS5jb20iLCJhdWQiOiIzMmZjN2QwNy1jOTUzLTRiZTYtOGZjYi1kZjJjMmM5MTI4NzUiLCJleHAiOjE2NTkwNzI0NjEsImlhdCI6MTY1ODk4NjA2MSwibGlhdCI6MTY1ODc0MzE0MiwiYXV0aF90aW1lIjoxNjU4OTg2MDYxLCJhbXIiOlsiMCJdLCJhY3IiOiIxIiwianRpIjoiODljYmUyNWUtZTIyYi00MjBlLWE3NzItMTcxMzk0YTdkZmZiIiwiZ3JvdXBzIjpbeyJyb2xlcyI6WyJhdXRoX2FkbWluIl0sImdyb3VwX2lkIjoiYXV0aF9hZG1pbnMiLCJncm91cF9uYW1lIjoiQURNSU4ifSx7InJvbGVzIjpbImF1dGhfdXNlciJdLCJncm91cF9pZCI6ImF1dGhfdXNlcnMiLCJncm91cF9uYW1lIjoiVVNFUiJ9LHsicm9sZXMiOlsidHJhaW5pbmdfYWRtaW4iLCJzdXBlcl9hZG1pbiJdLCJncm91cF9pZCI6InRyYWluaW5nX29ubGluZV9jb3Vyc2VfdGVzdCIsImdyb3VwX25hbWUiOiJUcmFpbmluZyBPbmxpbmUgQ291cnNlIFRlc3QifSx7InJvbGVzIjpbImFkbWluIl0sImdyb3VwX2lkIjoiY291cnNlX29wZXJhdGlvbiIsImdyb3VwX25hbWUiOiJjb3Vyc2Vfb3BlcmF0aW9uIn0seyJyb2xlcyI6WyJ0cmFpbmluZ19hZG1pbiIsInN1cGVyX2FkbWluIiwiZWNvbW1lcmNlX2FkbWluIl0sImdyb3VwX2lkIjoiZWNvbW1lcmNlX2dyZWVuYmVycnlfdGVzdCIsImdyb3VwX25hbWUiOiJHcmVlbmJlcnJ5IFRlc3QifV0sInNjb3BlcyI6WyJvcGVuaWQiLCJwcm9maWxlIiwiZW1haWwiLCJwaG9uZSJdLCJzaWQiOiJhYTM5M2I3MC1mZmRmLTRiZWQtYmRlYi1kYTE3ZTVjZWY4YTciLCJ1YV9oYXNoIjoiYTA5MDk4MTBhNmQxMzI4MzJlMjhlZjZkYTE4ZWM3N2MiLCJjdHIiOiI1YWE5N2Y0MS1iY2VmLTRkMTMtOGE5YS1mYWI2ZjM3ZDExNDQiLCJkciI6IiJ9.PL8tR6jMdb6sOyosDauGgT9SRVJmPnvpbxK2a648yOe0xPHJc-DzbVOi_-vFVku4ZA-7baUoBp9HZfiV2KImS-Q-NtRtjK9zMFL-Rgc-vxgu0INKXnmtXW4zYHnLiivNbhGWlrI0_c1JiMGbdLZbJKIBviJWW3DFpuSSxf_dR-1MoD9koymesxiFDwk73vUqytKdHp8UhiLpsA9UZ1JD78ZE7ICJtJNL4eDJNbjeO8xQQQ3w8hbOCxM4F1sjzBAiGyfz37T8ozHQYaq7TSSglOuoureWMrAfVeGmPTf72IsRUSFOXL1dYAxwGQbxLWx6xdtNU8sFFaSMoiSf38HbOw"

	// 		const response = await axios({
	// 			method: "GET",
	// 			url: `https://sathibazar-stg-api.skill-mine.com/pos-create-bill/account`,
	// 			headers: {
	// 				Authorization: "Bearer " + adminToken,
	// 			},
	// 		});
	// 		if (response) {
	// 			console.log(response, 'api')
	// 			if (response.data) {
	// 				if (response.data.data) {
	// 					console.log(response.data.data, 'api2222')
	// 					setAccountData(response.data.data.pos_net_revenue)
	// 					setTotalSale(response.data.data.total_sale_today)
	// 					setOutOfStock(response.data.data.out_of_stock_products)
	// 				}
	// 			}

	// 		}
	// 	}
	// 	catch (err) {
	// 		console.log(err)
	// 	}
	// }
	return (
		<Container fluid={true} className="mt-3">
			<Row className='card-container-test mr-0'>
				{data.map((value, index) =>
				{
				return(
					<Col>{cardView({
						heading: value.heading,
						count: value.count,
						icon: value.icon,
						alt: value.alt,
						backgroundColor: value.backgroundColor,
						key: index
					})}</Col>)
				})}
			</Row>
		</Container>
	)
	// return (
	// 	<div className='accounts-container'>
	// 		<div className='card-details-section'>

	// 			<div
	// 				style={{
	// 					backgroundColor: '#FDA950',
	// 				}}
	// 				className='card-container'
	// 			>
	// 				<div className='card-header-value'>
	// 					<span className='card-header'>
	// 						{accountData ? accountData.heading : ''}
	// 					</span>
	// 					<span className='card-value' id='value'>
	// 						{accountData && accountData.number.length > 0 ? accountData.number[0].count : '0'}
	// 					</span>
	// 				</div>

	// 				<img src={dollar} alt='account_card' />
	// 			</div>

	// 			<div
	// 				style={{
	// 					backgroundColor: '#00C58E',
	// 				}}
	// 				className='card-container'
	// 			>
	// 				<div className='card-header-value'>
	// 					<span className='card-header'>
	// 						{totalSale ? totalSale.heading : ''}
	// 					</span>
	// 					<span className='card-value' id='value'>
	// 						{totalSale && totalSale.number.length > 0 ? totalSale.number[0].count : '0'}
	// 					</span>
	// 				</div>

	// 				<img src={chart} alt='account_card' />
	// 			</div>

	// 			<div
	// 				style={{
	// 					backgroundColor: '#06215F',
	// 				}}
	// 				className='card-container'
	// 			>
	// 				<div className='card-header-value'>
	// 					<span className='card-header'>
	// 						{outOfStock ? outOfStock.heading : ''}
	// 					</span>
	// 					<span className='card-value' id='value'>
	// 						{outOfStock && outOfStock.number.length > 0 ? outOfStock.number[0].totalAmount : '0'}
	// 					</span>
	// 				</div>

	// 				<img src={emptyCart} alt='account_card' />
	// 			</div>
	// 		</div>
	// 	</div>
	// );
}
