import Dexie from 'dexie';
import { billHistoryData } from '../dummy/billHistoryData';

export const bill_history_db: any = new Dexie('BillHistoryDB');
bill_history_db.version(1).stores({
	data: 'invoiceNo, date, contactNo, customerName, status, paymentMethod, total, productsDetail',
});

bill_history_db.open().catch((err: any) => {
	console.log(err.stack || err);
});

export const fetchBillHistoryToDB = () => {
	for (let i = 0; i < billHistoryData.length; i++) {
		bill_history_db.data.add(billHistoryData[i]);
	}
};
