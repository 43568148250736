import React from "react";

const useLoadScript = () => {

    // 
    const loadscript = (url: string) => {
        if (typeof window !== 'undefined') {
            var script = document.createElement('script');
            script.src = url;
            script.type = 'text/javascript';

            document.getElementsByTagName("HEAD")[0].appendChild(script);
        }
    }

    return { loadscript }
}

export default useLoadScript