import React, { useState, useMemo, useEffect, Fragment } from "react";
// import BillDetailsModal from './BillDetailsModal';
// import { inventoryData } from '../../../data/dummy/inventoryData';
import {
  inventory_db,
  // fetchInventoryToDB
} from "../../../data/indexedDB";
import axios from "axios";
import Modal from "../../../components/Basic/Modal";
import CircularProgressBar from "../../../components/Basic/CircularProgressBar";
import CircularProgressDone from "../../../components/Basic/CircularProgressDone";
import { Offline, Online } from "react-detect-offline";
import moment from "moment";
import "./styles.scss";
import TableContainer from "../../../components/Basic/TableContainer/TableContainer";
import { setLocalStorage, STORAGE_KEYS } from "../../../helpers/common";
import useToggleHook from "../../../hooks/useToggleHook";
import ModalPopup from "../../../components/Basic/Modalpopup/modalpopup";
import { Container, Button } from "react-bootstrap";
import { getLocalStorage } from "../../../helpers/common";
import { postMethod, getMethod } from "../../../api/basic_configs/appService";
import { API_END_POINTS } from "../../../api/urlsConfig";
import Barcode from "../Barcode";
import TableColumnBarCode from "./TableColumnBarCode";

export default function () {
  /* -------------------------------- useStates ------------------------------- */

  const [inventoryListData, setInventoryListData] = useState([]);
  const [isNewTableVisible, setNewTableVisible] = useState(false);

  const [isListLoading, setIsListLoading] = useState(true);

  const [isSyncDataModalOpen, setSyncDataModalOpen] = useState(false);
  const [isDBSyncDone, setIsDBSyncDone] = useState(false);
  const [inventoryProducts, setInventoryProducts] = useState([]);
  const [reordered, setreordered] = useState([]);

  let onlineStatus = navigator.onLine;
  // toggle hook
  const { hideToggle, showToggle, toggle } = useToggleHook();

  /* -------------------------------- functions ------------------------------- */
  const getDBSyncStatus = () => {
    setTimeout(() => {});
    setTimeout(() => {
      localStorage.setItem("lastSync", String(new Date()));
      setIsDBSyncDone(true);
    }, 2000);
  };

  const details = useMemo(() => {
    return getLocalStorage(STORAGE_KEYS.USER_DETAILS);
  }, []);

  const getInventoryListFromDB = async () => {
    let data = await inventory_db.data.toArray();
    if (data.length != 0) {
      setInventoryListData(data);
      setIsListLoading(false);
    }
    // else {
    // 	fetchInventoryToDB();
    // 	getInventoryListFromDB();
    // }
  };
  function diff_hours(dt2: any, dt1: any) {
    var diff = (dt2.getTime() - dt1.getTime()) / 1000;
    diff /= 60 * 60;
    return Math.abs(Math.round(diff));
  }
  const syncInventoryData = () => {
    // dt1 = new Date('October 13, 2014 08:11:00');
    // dt2 = new Date('October 13, 2014 11:13:00');

    if (localStorage.getItem("lastSync")) {
      let dt1 = new Date(String(localStorage.getItem("lastSync")));
      let dt2 = new Date();
      let diff_hours_value = diff_hours(dt1, dt2);

      if (diff_hours_value > 23) {
        // setSyncDataModalOpen(true);
        showToggle();
        getDBSyncStatus();
      } else {
        // setIsDBSyncDone(true);
        showToggle();
      }
    } else {
      localStorage.setItem("lastSync", String(new Date()));
      // setSyncDataModalOpen(true);
      showToggle();
      getDBSyncStatus();
    }
  };

  /* -------------------------------- useEffts -------------------------------- */

  useEffect(() => {
    if (isDBSyncDone) {
      getInventoryListFromDB();
    }
  }, [isDBSyncDone]);

  useEffect(() => {
    syncInventoryData();
    getProductsFromInventory();
  }, []);

  const getProductsFromInventory = async () => {
    try {
      const data = {
        store_id: details.registeration_id,
        //store_id: "58fa4920-6f8a-418d-8a31-bcb98292fa11"
      };
      let response;
      //const adminToken = "eyJhbGciOiJSUzI1NiIsImtpZCI6ImIxYTNiYWJmLTFjYjgtNDA3OS05ZmFmLWUxZjc5NDE5MGYwNyJ9.eyJzdWIiOiJiMWM5Njc2YS1kOWY1LTRjZjAtYTNmYy1jMmQ0OWJkZTA2YWUiLCJuYW1lIjoiU3lzdGVtIFVzZXJzIiwiZW1haWwiOiJzeXN0ZW11c2VyQHNraWxsLW1pbmUuY29tIiwiaXN1YiI6IjdiYTYxNzhmLWZmMTItNDMzOS1iYmJmLWQ3NTdjOWZhZmVjNyIsImlzcyI6Imh0dHBzOi8vYWxraGFsZWVqdGVzdC1hY2NvdW50cy1hcGkuc2tpbGwtbWluZS5jb20iLCJhdWQiOiIzMmZjN2QwNy1jOTUzLTRiZTYtOGZjYi1kZjJjMmM5MTI4NzUiLCJleHAiOjE2NTkwNzI0NjEsImlhdCI6MTY1ODk4NjA2MSwibGlhdCI6MTY1ODc0MzE0MiwiYXV0aF90aW1lIjoxNjU4OTg2MDYxLCJhbXIiOlsiMCJdLCJhY3IiOiIxIiwianRpIjoiODljYmUyNWUtZTIyYi00MjBlLWE3NzItMTcxMzk0YTdkZmZiIiwiZ3JvdXBzIjpbeyJyb2xlcyI6WyJhdXRoX2FkbWluIl0sImdyb3VwX2lkIjoiYXV0aF9hZG1pbnMiLCJncm91cF9uYW1lIjoiQURNSU4ifSx7InJvbGVzIjpbImF1dGhfdXNlciJdLCJncm91cF9pZCI6ImF1dGhfdXNlcnMiLCJncm91cF9uYW1lIjoiVVNFUiJ9LHsicm9sZXMiOlsidHJhaW5pbmdfYWRtaW4iLCJzdXBlcl9hZG1pbiJdLCJncm91cF9pZCI6InRyYWluaW5nX29ubGluZV9jb3Vyc2VfdGVzdCIsImdyb3VwX25hbWUiOiJUcmFpbmluZyBPbmxpbmUgQ291cnNlIFRlc3QifSx7InJvbGVzIjpbImFkbWluIl0sImdyb3VwX2lkIjoiY291cnNlX29wZXJhdGlvbiIsImdyb3VwX25hbWUiOiJjb3Vyc2Vfb3BlcmF0aW9uIn0seyJyb2xlcyI6WyJ0cmFpbmluZ19hZG1pbiIsInN1cGVyX2FkbWluIiwiZWNvbW1lcmNlX2FkbWluIl0sImdyb3VwX2lkIjoiZWNvbW1lcmNlX2dyZWVuYmVycnlfdGVzdCIsImdyb3VwX25hbWUiOiJHcmVlbmJlcnJ5IFRlc3QifV0sInNjb3BlcyI6WyJvcGVuaWQiLCJwcm9maWxlIiwiZW1haWwiLCJwaG9uZSJdLCJzaWQiOiJhYTM5M2I3MC1mZmRmLTRiZWQtYmRlYi1kYTE3ZTVjZWY4YTciLCJ1YV9oYXNoIjoiYTA5MDk4MTBhNmQxMzI4MzJlMjhlZjZkYTE4ZWM3N2MiLCJjdHIiOiI1YWE5N2Y0MS1iY2VmLTRkMTMtOGE5YS1mYWI2ZjM3ZDExNDQiLCJkciI6IiJ9.PL8tR6jMdb6sOyosDauGgT9SRVJmPnvpbxK2a648yOe0xPHJc-DzbVOi_-vFVku4ZA-7baUoBp9HZfiV2KImS-Q-NtRtjK9zMFL-Rgc-vxgu0INKXnmtXW4zYHnLiivNbhGWlrI0_c1JiMGbdLZbJKIBviJWW3DFpuSSxf_dR-1MoD9koymesxiFDwk73vUqytKdHp8UhiLpsA9UZ1JD78ZE7ICJtJNL4eDJNbjeO8xQQQ3w8hbOCxM4F1sjzBAiGyfz37T8ozHQYaq7TSSglOuoureWMrAfVeGmPTf72IsRUSFOXL1dYAxwGQbxLWx6xdtNU8sFFaSMoiSf38HbOw"
      if (getLocalStorage(STORAGE_KEYS.LOGIN_TYPE) == "storeadmin") {
        response = await postMethod(
          API_END_POINTS.INVENTORY_UTILS_SEARCH,
          data
        );
      } else {
        response = await getMethod(
          API_END_POINTS.GET_HOTCOOK_PRODUCTS_QTY.replace(":state",details?.location?.state).replace(
            ":store_id",
            details.registeration_id
          )
        );
      }
      // const response = await axios({
      // 	method: "POST",
      // 	url: `https://sathibazar-stg-api.skill-mine.com/inventory-srv/inventory/utils/search`,
      // 	data: data,
      // 	headers: {
      // 		Authorization: "Bearer " + adminToken,
      // 	},
      // });
      const respData = response.data;

      let addSNOToResponse;
      if (getLocalStorage(STORAGE_KEYS.LOGIN_TYPE) == "storeadmin") {
        addSNOToResponse = respData.map((value: any, index: number) => {
          return { ...value, SNo: index + 1 };
        });
      } else {
        const filterQtyData =
          respData &&
          respData.filter((qty: any) => qty.quantity_details?.length !== 0);
        addSNOToResponse =
          filterQtyData &&
          filterQtyData.map((value: any, index: number) => {
            return {
              ...value,
              SNo: index + 1,
              qty: value?.quantity_details[0]?.qty,
              customer_pay: value.customer_pay,
              added_date: value.createdAt,
            };
          });
      }
      setInventoryProducts(addSNOToResponse);
    } catch (err: any) {}
  };
  const handleNameClick = async (batchNo: any) => {
    try {
      const response = await getMethod(
        API_END_POINTS.REORDERED_INVENTORY.replace(":batch_id", batchNo)
      );
      const reorderedInventoryData = response.data;
      setreordered(reorderedInventoryData);
    } catch (error) {}
    setNewTableVisible(!isNewTableVisible);
  };

  const ProductFeilds = (cell: any) => {
    return cell.value ? cell.value : "";
  };

  const handleClick = () => {};

  const columns = [
    {
      Header: "S.N0",
      filterable: true,
      accessor: "SNo",
      Cell: (cellProps: any) => {
        return <ProductFeilds {...cellProps} />;
      },
    },
    {
      Header: "Name",
      accessor: "product_name",
      filterable: true,
      Cell: (cellProps: any) => {
        const batchNo = cellProps.row.original._id;
        const cellData = cellProps.value;
        return (
          <div
            className="sathi-green sathi-green:hover underline"
            onClick={() => handleNameClick(batchNo)}
          >
            <ProductFeilds {...cellProps} />
          </div>
        );
      },
    },

    {
      Header: "Category",
      accessor: "category",
      filterable: true,
      Cell: (cellProps: any) => {
        return <ProductFeilds {...cellProps} />;
      },
    },
    {
      Header: "Brand",
      accessor: "brand",
      filterable: true,
      Cell: (cellProps: any) => {
        const cellData = cellProps.value;
        return cellData == "" ? "-" : <ProductFeilds {...cellProps} />;
      },
    },
    {
      Header: "Bar Code",
      accessor: "barCode",
      filterable: true,
      Cell: (cellProps: any) => {
        const cellData = cellProps.value;
        return cellData == "" ? (
          "-"
        ) : (
          <>
            <TableColumnBarCode cellData={cellData} />
          </>
        );
      },
    },

    {
      Header: "Qty Left",
      accessor: "current_qty",
      filterable: true,
      Cell: (cellProps: any) => {
        const cellData = cellProps.value;
        return cellData <= 0 ? (
          <span style={{ color: "red", textTransform: "capitalize" }}>
            out of stock
          </span>
        ) : (
          <ProductFeilds {...cellProps} />
        );
      },
    },
  ];
  const columns2 = [
    {
      Header: "Stock No",
      filterable: true,
      accessor: "batch_no",
      Cell: (cellProps: any) => {
        return <ProductFeilds {...cellProps} />;
      },
    },
    {
      Header: "Category",
      accessor: "category",
      filterable: true,
      Cell: (cellProps: any) => {
        return <ProductFeilds {...cellProps} />;
      },
    },
    {
      Header: "Brand",
      accessor: "brand",
      filterable: true,
      Cell: (cellProps: any) => {
        const cellData = cellProps.value;
        return cellData == "" ? "-" : <ProductFeilds {...cellProps} />;
      },
    },
    {
      Header: "Bar Code",
      accessor: "barCode",
      filterable: true,
      Cell: (cellProps: any) => {
        const cellData = cellProps.value;
        return cellData == "" ? (
          "-"
        ) : (
          <>
            <TableColumnBarCode cellData={cellData} />
          </>
        );
      },
    },
    {
      Header: "Name",
      accessor: "product_name",
      filterable: true,
      Cell: (cellProps: any) => {
        const cellData = cellProps.value;
        return cellData == cellData ? (
          cellData?.charAt(0)?.toUpperCase() + cellData?.slice(1)
        ) : (
          <ProductFeilds {...cellProps} />
        );
      },
    },
    {
      Header: "Qty Left",
      accessor: "current_qty",
      filterable: true,
      Cell: (cellProps: any) => {
        const cellData = cellProps.value;
        return cellData <= 0 ? (
          <span style={{ color: "red", textTransform: "capitalize" }}>
            out of stock
          </span>
        ) : (
          <ProductFeilds {...cellProps} />
        );
      },
    },
    {
      Header: "SP(Tax incl)/Unit",
      accessor: "customer_pay",
      filterable: true,
      Cell: (cellProps: any) => {
        const value = Number(cellProps.value).toFixed(2);
        return <ProductFeilds {...cellProps} value={value} />;
      },
    },
    {
      Header: "Discount(%)",
      accessor: "discount",
      filterable: true,
      Cell: (cellProps: any) => {
        return <ProductFeilds {...cellProps} />;
      },
    },
    {
      Header: "GST(%)",
      accessor: "gst",
      filterable: true,
      Cell: (cellProps: any) => {
        const cellData = cellProps.value;
        return cellData == "" ? 0 : <ProductFeilds {...cellProps} />;
      },
    },
    {
      Header: "Date Added",
      accessor: "added_date",
      filterable: true,
      Cell: (cellProps: any) => {
        const data = { value: cellProps?.value?.split("T")[0] };
        return <ProductFeilds {...data} />;
      },
    },
    {
      Header: "Expiry",
      accessor: "expiry_date",
      filterable: true,
      Cell: (cellProps: any) => {
        const data = { value: cellProps?.value?.split("T")[0] };
        return <ProductFeilds {...data} />;
      },
    },
  ];

  const Cafecolumns = useMemo(
    () => [
      {
        Header: "S.N0",
        filterable: true,
        accessor: "SNo",
        Cell: (cellProps: any) => {
          return <ProductFeilds {...cellProps} />;
        },
      },
      {
        Header: "Category",
        accessor: "category",
        filterable: true,
        Cell: (cellProps: any) => {
          return <ProductFeilds {...cellProps} />;
        },
      },

      {
        Header: "Name",
        accessor: "product_name",
        filterable: true,
        Cell: (cellProps: any) => {
          const cellData = cellProps.value;
          return cellData == cellData ? (
            cellData?.charAt(0)?.toUpperCase() + cellData?.slice(1)
          ) : (
            <ProductFeilds {...cellProps} />
          );
        },
      },
      {
        Header: "SP(Tax incl)/Unit",
        accessor: "customer_pay",
        filterable: true,
        Cell: (cellProps: any) => {
          const value = Number(cellProps.value).toFixed(2);
          return <ProductFeilds {...cellProps} value={value} />;
        },
      },
      {
        Header: "Discount(%)",
        accessor: "discount",
        filterable: true,
        Cell: (cellProps: any) => {
          return <ProductFeilds {...cellProps} />;
        },
      },
      {
        Header: "Quantity",
        accessor: "qty",
        filterable: true,
        Cell: (cellProps: any) => {
          const cellData = cellProps.value;
          return cellData <= 0 ? (
            <span style={{ color: "red", textTransform: "capitalize" }}>
              out of stock
            </span>
          ) : (
            <ProductFeilds {...cellProps} />
          );
        },
      },
      {
        Header: "GST(%)",
        accessor: "gst",
        filterable: true,
        Cell: (cellProps: any) => {
          const cellData = cellProps.value;
          return cellData == "" ? 0 : <ProductFeilds {...cellProps} />;
        },
      },
      {
        Header: "Date Added",
        accessor: "added_date",
        filterable: true,
        Cell: (cellProps: any) => {
          const data = { value: cellProps?.value?.split("T")[0] };
          return <ProductFeilds {...data} />;
        },
      },
    ],
    []
  );
  return (
    <div className="container-fluid">
      <div className="table-row-details"></div>
      {isNewTableVisible ? (
        <Fragment>
          <Button
            className="custom-btn mx-2"
            variant="primary"
            onClick={() => setNewTableVisible(false)}
          >
            Back
          </Button>
          <TableContainer
            columns={columns2}
            data={reordered}
            isGlobalFilter={true}
            handleClick={handleClick}
            PlaceholderValue={"Search by Name, Barcode , or Category"}
          />
        </Fragment>
      ) : (
        <TableContainer
          columns={
            getLocalStorage(STORAGE_KEYS.LOGIN_TYPE) == "storeadmin"
              ? columns
              : Cafecolumns
          }
          data={inventoryProducts}
          isGlobalFilter={true}
          handleClick={handleClick}
          PlaceholderValue={"Search by Name, Barcode , or Category"}
        />
      )}

      {/* {isSyncDataModalOpen && (
				<Modal
					setOpenModal={isDBSyncDone && setSyncDataModalOpen}
					header={`Database Sync : ${isDBSyncDone ? `Done` : 'Please Wait'
						}`}
					width={'33%'}
				>
					<div
						style={{
							display: 'flex',
							alignItems: 'center',
							justifyContent: 'center',
							alignContent: 'center',
							textAlign: 'center',
						}}
					>
						{!isDBSyncDone && <CircularProgressBar />}

						{isDBSyncDone && <CircularProgressDone />}
					</div>
				</Modal>
			)} */}
      {/* data sync modal popup */}
    </div>
  );
}
