import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

const LoggedOut = (props: any) => {
	return <React.Fragment>{props.children}</React.Fragment>;
};

LoggedOut.propTypes = {
	children: PropTypes.any,
	location: PropTypes.object,
};

export default withRouter(LoggedOut);
