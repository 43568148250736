import React, { useState } from "react";

const useToggleHook = () => {
    const [toggle, setToggle] = useState<boolean>(false)

    // show toggle
    const showToggle = () => {
        setToggle(true)
    }

    // hide toggle
    const hideToggle = () => {
        setToggle(false)
    }

    return { toggle, showToggle, hideToggle }
}

export default useToggleHook