import Dexie from 'dexie';
import { loginData } from '../dummy/loginData';

export const login_db: any = new Dexie('LoginDB');

login_db.version(1).stores({
	data: 'userName, userPassword',
});

login_db.open().catch((err: any) => {
	console.log(err.stack || err);
});

export const fetchLoginToDb = () => {
	for (let i = 0; i < loginData.length; i++) {
		login_db.data.add(loginData[i]);
	}
};
