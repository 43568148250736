import React from 'react';
import Modal from '../../../components/Basic/Modal';
// import ProductTable from '../CreateBill/ProductTable';
// import { billHistoryData } from './billHistoryData';
import BillDetails from '../../../components/Sections/BillDetails';
import { BILL_VIEW_TYPES } from '../../../helpers/common';

export default function BillDetailsModal(props: any) {
	const { setIsBillDetailsModalOpen, billData ,storeData} = props;
	// console.log(billData, 'fromHistory')
	return (
		<Modal
			// style= {}
			// header={`Bill Details ${
			// 	billData.status == 'Pending'
			// 		? '-  Payment ' + billData.status
			// 		: ''
			// }`}
			// header={`Bill Details   ${'-  Payment ' + billData.status}`}
			header={'Bill Details'}
			width={'80%'}
			height={'auto'}
			setOpenModal={setIsBillDetailsModalOpen}
		>
			<BillDetails
				setBillModalOpen={setIsBillDetailsModalOpen}
				billData={billData}
				storeData={storeData}
				billViewType={BILL_VIEW_TYPES.BILL_HISTORY}
			/>
		</Modal>
	);
}
