import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import UpperNavigation from '../Navigation';
import CafeNavigation from '../CafeNavigation';
import './styles.scss';
import { Col, Container, Row } from 'react-bootstrap';
import Header from '../Header';
import { getLocalStorage } from '../../../helpers/common';

// const LoggedIn = (props: any) => {
// 	return (
// 		<React.Fragment>
// 			<div className='layout-container' style={{backgroundColor:"blue"}}>
// 				<UpperNavigation />
// 				{props.children}
// 			</div>
// 		</React.Fragment>
// 	);
// };

const LoggedIn = (props: any) => {

	return (
		<React.Fragment>
			<Container fluid={true}>
				<Row>
					<Col xs={"12"} className={"logo-userinfo-navlinks-container"}>
						<Header />
						{(getLocalStorage('login-type').length == 1) ?
							<>
								{(getLocalStorage('login-type') == 'cafeAdmin' || getLocalStorage('login-type') == 'storeadmin') && <>
									<UpperNavigation />
									{props.children}
								</>
								}
							</>
							:
							<>
								{<h4 className='text-center mt-5 text-danger'>{typeof(getLocalStorage('login-type'))=='string'?'Sorry!! You do not have access to POS':''}</h4>}
							</>}
						{(getLocalStorage('login-type').length > 1) ?
							<>
								{(getLocalStorage('login-type').includes('cafeAdmin') || getLocalStorage('login-type').includes('storeadmin')) && <CafeNavigation />}
							</>
							:
							<></>}
					</Col>
					{/* <Col xs="12" style={{ padding: 5 }}>
						{props.children}
					</Col> */}
				</Row>
			</Container>

		</React.Fragment>
	);
};

LoggedIn.propTypes = {
	children: PropTypes.any,
	location: PropTypes.object,
};

export default withRouter(LoggedIn);
