import { useLiveQuery } from "dexie-react-hooks";
import React, { Fragment, useEffect, useMemo, useRef, useState } from "react";
import SearchBar from "../../../components/Basic/Search/SearchBar";
import { bill_history_db, fetchBillHistoryToDB } from "../../../data/indexedDB";
import RefundModal from "../Refund/RefundModal";
import BillDetailsModal from "./BillDetailsModal";
import { filterBillHistoryListBySearchString } from "./billHistoryTableFilter";
import RefundDetails from "./RefundDetails";
import RefundDetailsModal from "./RefundDetailsModal";
import "./styles.scss";
import moment from "moment";
// import { billHistoryData } from './billHistoryData';
import removeIcon from "../../../assets/icons/close.svg";
import axios from "axios";
import { Offline, Online } from "react-detect-offline";
import axiosInstence from "../../../api/basic_configs/axiosInstance";
import TableContainer from "../../../components/Basic/TableContainer/TableContainer";
import { access } from "fs";
import { getMethod } from "../../../api/basic_configs/appService";
import { getLocalStorage, STORAGE_KEYS } from "../../../helpers/common";
import { API_END_POINTS } from "../../../api/urlsConfig";
import { Container } from "react-bootstrap";

export default function () {
  /* -------------------------------- useStates ------------------------------- */
  const [isBillDetailsModalOpen, setIsBillDetailsModalOpen] = useState(false);
  const [billData, setBillData] = useState<any>({});

  const [billHistoryListData, setBillHistoryListData] = useState([]);
  const [billHistoryListDataStatic, setBillHistoryListDataStatic] = useState(
    []
  );
  const [isListLoading, setIsListLoading] = useState(true);
  const [billingHistoryDetails, setBillingHistoryDetails] = useState([]);
  const [searchString, setSearchString] = useState("");
  let onlineStatus = navigator.onLine;
  const [timer, setTimer] = useState<any | null>(null);
  // filter data
  const [filterData, setFilterData] = useState<any>([]);
  const [storeData, setStoreData] = useState<any>({});
  const filterDataRef = useRef<Array<Object>>();
  // userdetails
  const userDetails = useMemo(() => {
    return getLocalStorage(STORAGE_KEYS.USER_DETAILS);
  }, []);

  const isCafe = getLocalStorage(STORAGE_KEYS.LOGIN_TYPE).includes("cafeAdmin");

  const getBillingHistory = async () => {
    try {
      let url = API_END_POINTS.GET_ALL_BILL_HISTORY.replace(
        ":store_id",
        userDetails.registeration_id
      );
      const resp = await getMethod(url);
      const respData = resp.data?.reverse();
      let bilHist: any = [];
      respData.forEach((data: any, i: number) => {
        if (data.login_type[0] == getLocalStorage(STORAGE_KEYS.LOGIN_TYPE)[0]) {
          bilHist.push(data);
        }
      });
      const addSNOToResponse = bilHist.map((value: any, index: number) => {
        return { ...value, SNo: index + 1 };
      });

      setBillingHistoryDetails(addSNOToResponse);

      handleBillHistory(respData);

      filterDataRef.current = respData;
    } catch (err) {}
  };

  const getStoreDetail = async () => {
    try {
      let url = API_END_POINTS.GET_STORE_DETAILS.replace(
        ":request_id",
        userDetails.registeration_id
      );
      const response = await getMethod(url);
      const respData = response.data;
      setStoreData(respData);
    } catch (error) {}
  };

  useEffect(() => {
    getBillingHistory();
    getStoreDetail();
  }, []);

  const handleBillHistory = (respData: Array<Object>) => {
    const filteredBillData = respData.filter(
      (value: any) => value.refund_status == false
    );

    setFilterData(filteredBillData);
    filterDataRef.current = filteredBillData;
  };

  const getBillHistoryDataFromIDB = async () => {
    let data = await bill_history_db.data.toArray();

    if (data.length != 0) {
      setBillHistoryListData(data);
      setBillHistoryListDataStatic(data);
      setIsListLoading(false);
    } else {
      fetchBillHistoryToDB();
      getBillHistoryDataFromIDB();
    }
  };

  function getdate(value: any) {
    let date: any = new Date(value);
    return date.toLocaleDateString();
  }

  const handleSearchAndFilter = () => {
    if (searchString == "") {
      setBillHistoryListData(billHistoryListDataStatic);
    } else {
      let billHistoryList = filterBillHistoryListBySearchString(
        searchString,
        billHistoryListDataStatic
      );
      setBillHistoryListData(billHistoryList);
    }
  };

  const renderBillDetails = () => {
    if (isBillDetailsModalOpen) {
      if (billData.lastRefund) {
        return (
          <RefundDetailsModal
            billData={billData}
            setIsBillDetailsModalOpen={setIsBillDetailsModalOpen}
          />
        );
      } else {
        return (
          <BillDetailsModal
            billData={billData}
            storeData={storeData}
            setIsBillDetailsModalOpen={setIsBillDetailsModalOpen}
          />
        );
      }
    }
  };

  const CategoryFeilds = (cell: any) => {
    return cell.value ? cell.value : "";
  };

  const ProductFeilds = (cell: any) => {
    return cell.value ? cell.value : "-";
  };
  const columns = useMemo(
    () => [
      {
        Header: "S.N0",
        filterable: true,
        accessor: "SNo",
        Cell: (cellProps: any) => {
          return <ProductFeilds {...cellProps} />;
        },
      },
      {
        Header: "Invoice No",
        accessor: "storeInvoiceNumber",
        filterable: true,
        Cell: (cellProps: any) => {
          return (
            <div
              className={`sathi-green sathi-green:hover ${
                cellProps.value ||
                cellProps?.row?.original?.add_product[0]?.storeInvoiceNumber
                  ? "underline"
                  : "text-align-center"
              }`}
            >
              {cellProps.value
                ? cellProps.value
                : cellProps?.row?.original?.add_product[0]?.storeInvoiceNumber
                ? cellProps?.row?.original?.add_product[0]?.storeInvoiceNumber
                : "Pending"}
            </div>
          );
        },
      },
      {
        Header: "Date",
        accessor: "invoice_info.invoice_date",
        filterable: true,
        Cell: (cellProps: any) => {
          const data = { value: cellProps?.value?.split("T")[0] };
          return <ProductFeilds {...data} />;
        },
      },
      {
        Header: "Customer Name",
        accessor: "customer_details.customer_name",
        filterable: true,
        Cell: (cellProps: any) => {
          const cellData = cellProps.value;
          return cellData ? (
            cellData.charAt(0).toUpperCase() + cellData.slice(1)
          ) : (
            <ProductFeilds {...cellProps} />
          );
        },
      },
      {
        Header: "Contact No",
        accessor: "customer_details.mobile_number",
        filterable: true,
        Cell: (cellProps: any) => {
          return <ProductFeilds {...cellProps} />;
        },
      },

      {
        Header: "Payment Method",
        accessor: "payment_method",
        filterable: true,
        Cell: (cellProps: any) => {
          const value = cellProps.row.original.list_of_paymentModes;
          // Extract the payment methods from the list_of_paymentModes array
          const paymentMethods =
            value && value.length !== 0
              ? value.map((payment: any) => Object.keys(payment)[0])
              : "";
          return paymentMethods ? paymentMethods.join(",") : "";
        },
      },
      {
        Header: "Payment Status",
        accessor: "payment_status",
        filterable: true,
        Cell: (cellProps: any) => {
          return <ProductFeilds {...cellProps} />;
        },
      },
      {
        Header: "Total",
        accessor: "total_amount",
        filterable: true,
        Cell: (cellProps: any) => {
          const cellData = cellProps.value;
          return cellData?.toFixed(2);
          return <ProductFeilds {...cellProps} />;
        },
      },
    ],
    []
  );
  const cafeColumns = useMemo(
    () => [
      {
        Header: "S.N0",
        filterable: true,
        accessor: "SNo",
        Cell: (cellProps: any) => {
          return <ProductFeilds {...cellProps} />;
        },
      },
      {
        Header: "Invoice No",
        accessor: "storeInvoiceNumber",
        filterable: true,
        Cell: (cellProps: any) => {
          return (
            <div
              className={`sathi-green sathi-green:hover ${
                cellProps.value ||
                cellProps?.row?.original?.add_product[0]?.storeInvoiceNumber
                  ? "underline"
                  : "text-align-center"
              }`}
            >
              {cellProps.value
                ? cellProps.value
                : cellProps?.row?.original?.add_product[0]?.storeInvoiceNumber
                ? cellProps?.row?.original?.add_product[0]?.storeInvoiceNumber
                : "Pending"}
            </div>
          );
        },
      },
      {
        Header: "Date",
        accessor: "invoice_info.invoice_date",
        filterable: true,
        Cell: (cellProps: any) => {
          const data = { value: cellProps?.value?.split("T")[0] };
          return <ProductFeilds {...data} />;
        },
      },
      {
        Header: "Customer Name",
        accessor: "customer_details.customer_name",
        filterable: true,
        Cell: (cellProps: any) => {
          const cellData = cellProps.value;
          return cellData ? (
            cellData.charAt(0).toUpperCase() + cellData.slice(1)
          ) : (
            <ProductFeilds {...cellProps} />
          );
        },
      },
      {
        Header: "Contact No",
        accessor: "customer_details.mobile_number",
        filterable: true,
        Cell: (cellProps: any) => {
          return <ProductFeilds {...cellProps} />;
        },
      },

      {
        Header: "Payment Method",
        accessor: "payment_method",
        filterable: true,
        Cell: (cellProps: any) => {
          const value = cellProps.row.original.list_of_paymentModes;
          // Extract the payment methods from the list_of_paymentModes array
          const paymentMethods =
            value && value.length !== 0
              ? value.map((payment: any) => Object.keys(payment)[0])
              : "";
          return paymentMethods ? paymentMethods.join(",") : "";
        },
      },
      {
        Header: "Payment Status",
        accessor: "payment_status",
        filterable: true,
        Cell: (cellProps: any) => {
          return <ProductFeilds {...cellProps} />;
        },
      },
      {
        Header: "Total",
        accessor: "total_amount",
        filterable: true,
        Cell: (cellProps: any) => {
          const cellData = cellProps.value;
          return cellData?.toFixed(2);
          return <ProductFeilds {...cellProps} />;
        },
      },
    ],
    []
  );
  /* ------------------------------- useEffects ------------------------------- */
  useEffect(() => {
    getBillHistoryDataFromIDB();
  }, []);

  useEffect(() => {
    handleSearchAndFilter();
  }, [searchString]);

  return (
    <Fragment>
      <Container fluid={true}>
        <TableContainer
          columns={isCafe ? cafeColumns : columns}
          data={billingHistoryDetails}
          // storedata={storeData}
          isGlobalFilter={true}
          className="custom-header-css"
          handleClick={(detail: any) => {
            setIsBillDetailsModalOpen(true);
            setBillData(detail);
          }}
          PlaceholderValue={
            "Search By Invoice Number, Customer Name, Contact Number and Payment Status"
          }
        />

        {/* modal popup */}
        {renderBillDetails()}
      </Container>
    </Fragment>
  );

  // return (
  // 	<>
  // 		<div className='product-table-container'>

  // 			{/* <div className="switch-field" data-toggle="buttons">
  // 				<input type="radio" id="radio-one" name="switch-one" value="yes" checked onClick={() => { handleBillHistory(billingHistoryDetails) }} />
  // 				<label htmlFor="radio-one">Bill History</label>

  // 				<input type="radio" id="radio-two" name="switch-one" value="no" onClick={(e) => handleRefundHistory(billingHistoryDetails)} />
  // 				<label htmlFor="radio-two">Refund History</label>

  // 			</div> */}

  // 			<div className='mb-3'></div>
  // 			<TableContainer
  // 				columns={columns}
  // 				data={billingHistoryDetails}
  // 				isGlobalFilter={true}
  // 				className="custom-header-css"
  // 				handleClick={(detail: any) => {
  // 					setIsBillDetailsModalOpen(true);
  // 					setBillData(detail);
  // 				}}
  // 			/>

  // 			{/* modal popup */}
  // 			{renderBillDetails()}
  // 		</div>
  // 	</>

  // )
}
const FilterSelect = (props: any) => {
  const { searchString, setSearchString } = props;
  return (
    <div className="bill-history-filter">
      {/* <span>Filter</span> */}
      <select
        value={searchString}
        onChange={(e) => {
          setSearchString(e.target.value);
        }}
      >
        <option value={""}>Payment Status</option>
        <option value="Done">Paid</option>
        {/* <option value='Done'>Refunded</option> */}
        <option value="Pending">Pending</option>
      </select>

      {/* <select
				onChange={(e) => {
					setSearchString(e.target.value);
				}}
			>
				<option value={''}>Payment Method</option>

				<option value='Cash'>Cash</option>
				<option value='CC/DC'>CC/DC</option>
				<option value='UPI'>UPI</option>
				<option value='Internet Banking'>Internet Banking</option>
			</select> */}
    </div>
  );
};

// <div className='bill-history-search-and-filter'>
// 	<div className='bill-history-search'>
// 		{/* {searchString} */}
// 		<input
// 			onChange={(e) => handleSearch(e)}
// 			//onKeyDown={(e) => {  }}
// 			placeholder='Search by Customer Name, Customer Contact, Invoice No'
// 			value={searchString}
// 		/>
// 		<img
// 			src={removeIcon}
// 			onClick={() => setSearchString('')}
// 			style={{
// 				width: '20px',
// 				height: '20px',
// 				cursor: 'pointer',
// 				marginLeft: '-1.5rem',
// 				marginTop: '5px'
// 			}}
// 		/>
// 	</div>

// 	{/* <FilterSelect
// 					searchString={searchString}
// 					setSearchString={setSearchString}
// 				/> */}

// </div>
{
  /* <div
					style={{ marginTop: '10px' }}
					className='table-column-header'
				>
					<div className='table-item'>Invoice No</div>
					<div className='table-item'>Date</div>
					<div className='table-item'>Customer Name</div>
					<div className='table-item'>Contact No</div>
					<div className='table-item'>Payment Method</div>
					<div className='table-item'>Total</div>
				</div>

				<div className='table-row-details'>
					<div
						style={{ height: '60vh' }}
						className='product-list-container'
					>
						{!isListLoading ? (
							(onlineStatus ? (billingHistoryDetails && billingHistoryDetails) : (billHistoryListData && billHistoryListData)).map((item: any, index) => {
								let detail: any;
								if (item.customer_details) {
									detail = item
								} else {
									detail = item
								}
								return (<div
									key={index}
									onClick={() => {
										setIsBillDetailsModalOpen(true);
										setBillData(detail);
									}}
									style={{
										cursor: 'pointer',
									}}
									className='product-row-detail'
								>
									<div className='table-row-item'>
										{detail.invoice_info ? detail.invoice_info.invoice_number : detail.invoiceNo}
									</div>
									<div className='table-row-item'>
										{detail.invoice_info ? moment(detail.invoice_info.createdAt).format('DD/MM/YYYY, h:mm:ss a') : getdate(detail.date)}
									</div>
									<div className='table-row-item'>
										{detail.customer_details && detail.customer_details.customer_name
											? detail.customer_details.customer_name
											: detail.customerName}
									</div>
									<div className='table-row-item'>
										{detail.customer_details && detail.customer_details.mobile_number
											? detail.customer_details.mobile_number
											: detail.contactNo}
									</div>
									<div className='table-row-item'>
										{
											detail.payment_method ? detail.payment_method : detail.paymentMethod
										}
										{/* {detail.status == 'Done'
										? `Paid (${detail.paymentMethod}) `
										: detail.status}

									{detail.lastRefund && '(Refunded)'} */
}
{
  /* </div>
									<div className='table-row-item'>
										{detail.total_amount ? detail.total_amount : detail.total}
									</div>
								</div>
								)
							})
						) : (
							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
									marginTop: '15vh',
									fontSize: '20px',
								}}
							>
								Loading
							</div>
						)}
					</div>
				</div>  */
}
{
  /* {!isListLoading ? (
					(onlineStatus ? (billingHistoryDetails && billingHistoryDetails) : (billHistoryListData && billHistoryListData)).map((item: any, index) => {
						let detail: any;
						if (item.customer_details) {
							detail = item
						} else {
							detail = item
						}
						return (<div
							key={index}
							onClick={() => {
								setIsBillDetailsModalOpen(true);
								setBillData(detail);
							}}
							style={{
								cursor: 'pointer',
							}}
							className='product-row-detail'
						>

						</div>
						)
					})
				) : (
					<div
						style={{
							display: 'flex',
							justifyContent: 'center',
							marginTop: '15vh',
							fontSize: '20px',
						}}
					>
						Loading
					</div>
				)} */
}
