import { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { bill_history_db, fetchBillHistoryToDB } from '../../../data/indexedDB';
import { ROUTES_PATH } from '../../../routes';

export default function () {
	const [billData, setBillData] = useState<any>({});
	const [billHistoryListData, setBillHistoryListData] = useState([]);

	const [isBillListLoading, setIsBillListLoading] = useState(true);
	
	const getBillHistoryDataFromIDB = async () => {
		let data = await bill_history_db.data.toArray();

		if (data.length != 0) {
			setBillHistoryListData(data);

			setIsBillListLoading(false);
		} else {
			// console.log('fetching ');
			fetchBillHistoryToDB();
			getBillHistoryDataFromIDB();
		}
	};

	useEffect(() => {
		/* ---------------------------- billhistory Fetch --------------------------- */
		getBillHistoryDataFromIDB();
		/* -------------------------- inventory list fetch -------------------------- */
		/* -------------------------- account sales data list fetch -------------------------- */
	}, []);

	return (
		<div>
			{!isBillListLoading ? (
				<Redirect to={ROUTES_PATH.CREATE_BILL} />
			) : (
				<span>Fetching data</span>
			)}
		</div>
	);
}
