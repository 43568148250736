import Dexie from 'dexie';
import { inventoryData } from '../dummy/inventoryData';

export const inventory_db: any = new Dexie('InventoryDB');




inventory_db.version(1).stores({
	data: 'productId, category, name, brand, quantity, unitPrice, unit, gst, discount, dateAdded, expiry',
});

// inventory_db.version(1).stores({
// 	data: 'added_date, category, current_qty, discount, expiry_date, gst, id, image, price, product_name, seller_details, seller_id,store_detail,store_id,_id',
// });
inventory_db.open().catch((err: any) => {
	console.log(err.stack || err);
});

export const fetchInventoryToDB = () => {
	for (let i = 0; i < inventoryData.length; i++) {
		inventory_db.data.add(inventoryData[i]);
	}
};
