import { useEffect } from "react"
import axios from "axios"
import { clearLocalStorage, getLocalStorage, setLocalStorage, STORAGE_KEYS } from "../../../helpers/common"
import { API_END_POINTS, API_URLS_PATH } from "../../../api/urlsConfig"
import { getMethod } from "../../../api/basic_configs/appService"
import { useHistory } from "react-router"
import { ROUTES_PATH } from "../../../routes"
import useLoadingIndicator from "../../../components/Basic/loadingIndicator/loadingIndicator"
import { toastNotifications } from '../../../helpers';

export default function Token() {

  const history = useHistory()
  // loading indicator
  const { hideIndicator, showIndicator, loadingIndicatorComponent } = useLoadingIndicator()

  // get acces token from the url
  const getAccessToken = async () => {
    // show loading indicator
    showIndicator()
    // check whether hash is present or not
    if (window.location.hash) {
      // get access token from the url
      const access_token: any = new URLSearchParams(window.location.hash).get("access_token")
      // store the access token in local storage
      setLocalStorage(STORAGE_KEYS.ACCESS_TOKEN, access_token)
      // call the get userinfo api, pass access token to that function
      await getuserInfo(access_token)
    }
    // if access token not present in url
    else {
    }
    // hide the indicator
    hideIndicator()
  }

  // get the userinfo
  const getuserInfo = async (access_token: any) => {
    try {
      // headers
      const headers = {
        // Authorization: 'Bearer ' + access_token
        Authorization: `Bearer ${access_token}`
      }
      // trigger the api, to get the userinfo
      const resp = await axios.post(`${API_URLS_PATH.APP_BASE_URL}${API_END_POINTS.USER_INFO}`, {}, {
        headers
      });
      // get data from response
      const respData = resp.data.data
      const metaData = resp.data.meta_data.expiry
      const tokenExpiryTime = metaData * 1000; // Convert seconds to milliseconds
      const timer = tokenExpiryTime - Date.now();
      // Schedule logout when token expires
      setLocalStorage("ExpiryTimer",timer)
      // store data in localstorage
      setLocalStorage(STORAGE_KEYS.USER_INFO, respData)
      // get user details and store in localstorage, pass subID to this function
      const userDetailsData = await getUserDetailsBasedOnSubId(respData.sub)
      // store user details in localstorage
      setLocalStorage(STORAGE_KEYS.USER_DETAILS, userDetailsData)
      const obj = getLocalStorage(STORAGE_KEYS.USER_DETAILS)
      if (obj.login_type == undefined) {
        toastNotifications.warning(
          'you do not have access to POS'
        );
        logoutHandler()
      } else {
        setLocalStorage(STORAGE_KEYS.LOGIN_TYPE, obj.login_type)
      }

      // redirect to home page
      // window.location.href = "/"
      history.replace(ROUTES_PATH.HOME)
    }
    catch (error) {
      alert("login failed")
      // redirect to login page
      history.replace(ROUTES_PATH.LOGIN)
    }
  }

  // get userDetails based on sub id
  const getUserDetailsBasedOnSubId = (subID: any) => {
    return new Promise(async (resolve, reject) => {
      try {
        let url = API_END_POINTS.AUTH_GET_USER_INFO.replace(":sub", subID)
        // const resp = await axios.get("https://sathibazar-stg-api.skill-mine.com"+AUTH_GET_USER_INFO.replace(":sub", subID))
        const resp = await getMethod(url)
        const respData = resp.data
        resolve(respData)
      } catch (error) {
        reject(error)
      }
    })
  }
  
  // when user click logout, clear the state and navigate to login screen
  const logoutHandler = () => {
    // clear localstorage
    clearLocalStorage()
    // navigate to login page
    history.replace(ROUTES_PATH.LOGIN)
  }
  useEffect(() => {
    getAccessToken()
  }, [])

  return (
    <>
      {loadingIndicatorComponent()}
    </>
  )
}