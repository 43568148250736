import React, { useState, useEffect, useMemo, useRef } from "react";
import "./styles.scss";
import { bar, addIcon, bill } from "../../../assets/icons";
import ProductTable from "./ProductTable";
import AddProductModal from "./AddProductModal";
import Modal from "../../Basic/Modal";
import { toast, ToastContainer } from "react-toastify";
import { toastNotifications } from "../../../helpers";
import {
  bill_history_db,
  fetchInventoryToDB,
  inventory_db,
} from "../../../data/indexedDB";
import { randomString } from "../../../helpers/randomString";
import {
  calculateSubTotal,
  calculateTotalAmount,
  calculateTotalDiscount,
  calculateTotalTax,
  calculationBill,
} from "./billingCalculations";
import { Prompt, useHistory, useLocation } from "react-router-dom";
import { getDifferenceInArrays } from "../../../helpers/getDifferenceInArrays";
// import Button from '../../Basic/Button';
import { Oval } from "react-loader-spinner";
import { Offline, Online } from "react-detect-offline";
import axios from "axios";
import moment from "moment";
import {
  BILL_VIEW_TYPES,
  getLocalStorage,
  PAYMENTMETHOD,
  PAYMENT_STATUS,
  STORAGE_KEYS,
} from "../../../helpers/common";
import {
  postMethod,
  putMethod,
  getMethod,
} from "../../../api/basic_configs/appService";
import { API_END_POINTS } from "../../../api/urlsConfig";
import { PDFGenerate } from "../../Basic/Pdfgenerate/pdfgenerate";
import { Button } from "react-bootstrap";
import { ROUTES_PATH } from "../../../routes";
import useRazorPayHook from "../../../hooks/razorPayhook";
import Select from "react-select";
import {
  calculateoverallItemTax,
  calculatePerItemTax,
  calculateTotal,
} from "./ProductTable";
//
// eslint-disable-next-line import/no-anonymous-default-export
export default function (props: any) {
  interface Product {
    _id: string;
    batch_no: string;
    current_qty: number;
    customer_pay: number;
    expiry_date: string;
  }

  interface SelectedOption {
    value: string;
    label: string;
  }
  const [selectedOption, setSelectedOption] = useState<SelectedOption | null>(
    null
  );
  const {
    billData,
    billViewType,
    setBillModalOpen,
    setIsRefundBillDetailModalOpen,
    storeData,
  } = props;
  const { state } = useLocation<any>();
  // resuming the code
  const resumingData = state?.billData;
  const [inventoryId, setInventoryId] = useState("");
  const [reorderinventoryId, setreorderInventoryId] = useState("");

  const [isBillingInProgress, setIsBillingInProgress] = useState(false);
  const [inVoiceNumber, setinVoiceNumber] = useState("");
  const [storeDetails, setStoreDetails] = useState<any>([]);
  const [refundComment, setRefundComment] = useState("");
  /* -------------------------- //input field states -------------------------- */
  const [invoiceNo, setInvoiceNo] = useState(() => {
    if (billViewType == "create_bill") {
      return resumingData != undefined
        ? resumingData?.invoice_info?.invoice_number
        : randomString(5);
    } else {
      return billData.invoice_info.invoice_number;
    }
  });
  const isCafe = getLocalStorage(STORAGE_KEYS.LOGIN_TYPE).includes("cafeAdmin");
  const isStore = getLocalStorage(STORAGE_KEYS.LOGIN_TYPE).includes(
    "storeadmin"
  );

  const [customerName, setCustomerName] = useState(() => {
    if (billViewType == "create_bill") {
      return resumingData != undefined
        ? resumingData?.customer_details?.customer_name
        : "";
    } else {
      return billData.customerName;
    }
  });

  const [customerContact, setCustomerContact] = useState(() => {
    if (billViewType == "create_bill") {
      return resumingData != undefined
        ? resumingData?.customer_details?.mobile_number
        : "";
    } else {
      return billData.contactNo;
    }
  });

  const [customerAddress, setCustomerAddress] = useState(() => {
    if (billViewType == "create_bill") {
      return "";
    } else {
      return billData.customerAdd;
    }
  });

  const [internalNote, setInternalNote] = useState(() => {
    if (billViewType == "create_bill") {
      return "";
    } else {
      return billData.internalNotes;
    }
  });

  const [publicNote, setPublicNote] = useState(() => {
    if (billViewType == "create_bill") {
      return "";
    } else {
      return billData.publicNotes;
    }
  });
  const [isGSTAvailable, setIsGSTAvailable] = useState(
    billData?.customer_details?.gst ? true : false
  );

  // const [isRazorpayAvailable, setisRazorpayAvailable] = useState(
  //   billData?.customer_details?.gst ? true : false
  // );
  const [isHomeDelivery, setIsHomeDelivery] = useState(
    billData?.customer_details?.address ? true : false
  );
  const [customerGST, setCustomerGST] = useState("");
  //const [customerAddress, setCustomerAddress] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  /* --------------------------- functionalitystates -------------------------- */
  const [isAddProductModalOpen, setIsAddProductModalOpen] = useState(false);
  const [productModalHeader, setProductModalHeader] = useState("");
  const [isProductSearchable, setIsProductSearchable] = useState(false);
  const [cashIn, setCashIn] = useState(null as any);
  const [upiIn, setUpiIn] = useState(null as any);
  const [ccIn, setCcIn] = useState(null as any);
  const [calcTrue, setCalcTrue] = useState(true);
  const [toBePaid, setToBePaid] = useState(null as any);
  const [deletedData, setDeletedData] = useState([]);
  const [totalCalculation, setTotalCalculation] = useState<any>({
    totalAmount: 0,
    totalGstAmount: 0,
    subTotal: 0,
  });
  const [amountPaid, setAmountPaid] = useState(false);
  // const [storeData, setStoreData] =useState<any>({});
  const [upiFlag, setUpiFlag] = useState(false);
  const [qrImage, setQRImage] = useState();
  const [paymentObj, setPaymentObj] = useState({} as any);
  const [keys, setKeys] = useState();
  const [singlePaymentError, setSinglePaymentError] = useState(false);
  const [doublePaymentError, setDoublePaymentError] = useState(false);
  const [multiplePaymentError, setMultiplePaymentError] = useState(false);
  const [addedReorderStockList, setAddedReorderStockList] = useState<any>([]);
  useEffect(() => {
    QRAPI();
  }, [upiFlag === true]);

  const QRAPI = async () => {
    // Call API when UPI method
    const headers = {
      auth: {
        username: paymentObj?.key_id,
        password: paymentObj?.key_secret,
      },
    };
    const details = getLocalStorage(STORAGE_KEYS.USER_INFO);
    const store_name = details.name;
    const reqObj = {
      type: "upi_qr",
      name: store_name,
      usage: "single_use",
      fixed_amount: true,
      payment_amount: createBill.total_amount * 100,
    };

    // const response = await postMethod(API_END_POINTS.QR_CODE_API, reqObj, headers);
    // if (response && response.code === 200 && response.success === true) {
    // 	setQRImage(response.data);
    // 	setTimeout(() => {
    // 		setUpiFlag(false);
    // 	}, 15000)
    // }
  };
  const {
    openRazorPay,
    upiPaymentSuccess,
    ccPaymentSuccess,
    setUpiPaymentSuccess,
    setCcPaymentSuccess,
  } = useRazorPayHook();

  // const [paymentMethod, setPaymentMethod] = useState(() => {
  // 	if (billViewType == 'create_bill') {
  // 		return '';
  // 	} else {
  // 		return billData.paymentMethod;
  // 	}
  // });
  const [paymentMethod, setPaymentMethod] = useState<any>([]);
  const [addedproductList, setAddedProductList] = useState(() => {
    if (billViewType == "create_bill") {
      return resumingData != undefined ? resumingData?.add_product : billData;
    } else {
      return billData.add_product
        ? billData.add_product
        : billData.productsDetail.addedProducts;
    }
  });
  const [inventoryListData, setInventoryListData] = useState([]);
  const [inventoryData, setInventoryData] = useState([]);
  const [isInventoryListLoading, setIsInventoryListLoading] = useState(true);
  const [stateAbbreviation, setStateAbbreviation] = useState("");
  const [productSelectedFromItemCode, setProductSelectedFromItemCode] =
    useState({});
  const [itemCode, setItemCode] = useState("");

  const details = useMemo(() => {
    return getLocalStorage(STORAGE_KEYS.USER_DETAILS);
  }, []);
  const loginType = useMemo(() => {
    return getLocalStorage(STORAGE_KEYS.LOGIN_TYPE);
  }, []);
  // new state
  const [amounts, setAmounts] = useState<any>({
    Cash: "",
    UPI: "",
    CC_DC: "",
  });
  const [BillCreated, setBillCreated] = useState(false);

  const [createBill, setCreateBill] = useState({
    public_note: "",
    internal_note: "",
    mobile_number: "",
    invoice_number: "",
    invoice_date: new Date().toString(),
    isGstChecked: false,
    isHomeDeliveryChecked: false,
    customer_name: "",
    gst: "",
    address: "",
    razorpay_payment_id: "",
    razorpay_order_id: "",
    // payment_method: "",
    list_of_paymentModes: [],
    payment_status: "",
    sub_total: 0,
    tax: 0,
    total_amount: 0,
    store_id: "",
    store_name: "",
    seller_id: "",
    discount: 0,
    hsn: "",
    storePay: 0,
    bill_date: "",
    add_product: [],
    nafed_commission: "",
    nafed_percentage: "",
    cafeDiscountedPrice: "",
    totalAmt: 0,
    batch_no: "",
  });
  const totalAmountCalc = parseFloat(
    calculateoverallItemTax(createBill.add_product, isCafe)
  );
  // payment status
  const payment_status_ref = useRef("");
  // navigate
  const navigate = useHistory();
  // location
  const location = useLocation<any>();

  useEffect(() => {
    // check wether products contains or not
    let totalAmount = 0,
      totalGstAmount = 0,
      subTotal = 0;
    // if we have products calculate the total
    if (createBill.add_product.length > 0) {
      const {
        totalAmount: ta,
        totalGstAmount: tgst,
        subTotal: st,
      } = calculationBill(createBill.add_product);
      totalAmount = ta;
      totalGstAmount = tgst;
      subTotal = st;
      setCreateBill({
        ...createBill,
        sub_total: subTotal,
        total_amount: totalAmount,
        tax: totalGstAmount,
      });
    }
  }, [createBill.add_product]);

  useEffect(() => {
    getPaymentKeys();
  }, []);

  useEffect(() => {
    if (BillCreated === true) {
      const timeoutId = setTimeout(() => {
        getCountOfBills();
      }, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [BillCreated]);

  const getPaymentKeys = async () => {
    try {
      const paymentkeys = await getMethod(API_END_POINTS.GET_PAYMENT_KEYS);
      const { data = [] } = paymentkeys;
      const filterPayment =
        data &&
        data.length &&
        data.filter((item: any) => item.is_default === true);
      if (filterPayment && filterPayment.length) {
        setPaymentObj(filterPayment[0]);
      }
    } catch (error) {}
  };
  const callgetstoreAbbreviationAPI = async () => {
    try {
      let url = API_END_POINTS.GET_STATE_ABBREVIATION.replace(
        ":stateName",
        details.location?.state
      );
      const response = await getMethod(url);
      if (response?.data) {
        setStateAbbreviation(response.data?.[0]?.state_name_abbrevation);
      }
    } catch (err) {}
  };
  useEffect(() => {
    callgetstoreAbbreviationAPI();
  }, [details]);

  useEffect(() => {
    if (
      billViewType == BILL_VIEW_TYPES.BILL_HISTORY ||
      Object.keys(billData).length > 0 ||
      (resumingData ? Object.keys(resumingData).length > 0 : false)
    ) {
      // update the bill state
      const data = resumingData ? resumingData : billData;
      const viewData = {
        public_note: data.public_note,
        internal_note: data.internal_note,
        bill_date: data.bill_date,
        discount: data.discount,
        hsn: data?.hsn,
        nafed_commission: data?.nafed_commission,
        nafed_percentage: data?.nafed_percentage,
        cafeDiscountedPrice: data?.cafeDiscountedPrice,
        storePay: data?.storePay,
        payment_method: data.payment_method,
        payment_status: data.payment_status,
        store_id: data.store_id,
        seller_id: data.seller_id,

        sub_total: data.sub_total,
        tax: data.tax,
        total_amount: data.total_amount,
        invoice_number: data.invoice_info.invoice_number,
        invoice_date: new Date(data.invoice_info.invoice_date).toString(),
        customer_name: data.customer_details.customer_name,
        mobile_number: data.customer_details.mobile_number,
        gst: data.customer_details.gst,
        address: data.customer_details.address,
        isGstChecked: data.customer_details.gst != "" ? true : false,
        isHomeDeliveryChecked:
          data.customer_details.address != "" ? true : false,
        add_product: data.add_product,
        razorpay_payment_id: details.razorpay_payment_id,
        razorpay_order_id: details.razorpay_order_id,
      };

      setCreateBill({
        ...createBill,
        ...viewData,
      });
    }
  }, [billData]);

  const putUpdateBill = async (sendPayload: any) => {
    try {
      const url = API_END_POINTS.UPDATE_POS_CREATE_BILL.replace(
        ":invoice_id",
        resumingData ? resumingData._id : ""
      );
      const response = await putMethod(url, sendPayload);
      toast.success("Success", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      throw error;
    }
  };

  const resetOrClearBill = () => {
    payment_status_ref.current = "";
    setCashIn(null);
    setCreateBill({
      public_note: "",
      internal_note: "",
      mobile_number: "",
      invoice_number: "",
      invoice_date: new Date().toString(),
      isGstChecked: false,
      isHomeDeliveryChecked: false,
      customer_name: "",
      gst: "",
      address: "",
      razorpay_order_id: "",
      razorpay_payment_id: "",
      // payment_method: "",
      list_of_paymentModes: [],
      payment_status: "",
      sub_total: 0,
      storePay: 0,
      nafed_commission: "",
      nafed_percentage: "",
      cafeDiscountedPrice: "",
      tax: 0,
      total_amount: 0,
      store_id: "",
      store_name: "",
      seller_id: "",
      discount: 0,
      hsn: "",
      bill_date: "",
      add_product: [],
      totalAmt: 0,
      batch_no: "",
    });

    navigate.replace(ROUTES_PATH.CREATE_BILL, {});
  };

  const onClearBillHandler = () => {
    resetOrClearBill();
    setAmountPaid(false);
    setPaymentMethod([]);
    setAddedProductList([]);
    setAmounts({});
    setItemCode("");
    setUpiPaymentSuccess(false);
    setCcPaymentSuccess(false);

    toastNotifications.warning("Bill Cleared");
  };

  const onAddProductButtonHandler = () => {
    setIsAddProductModalOpen(true);
    setProductModalHeader("Add Product");
    setIsProductSearchable(true);
  };

  const calculateRefundedItems = () => {
    let data = getDifferenceInArrays(
      addedproductList,
      billData.productsDetail.addedProducts
    );

    return data;
  };

  const getProductsFromInventory = async () => {
    try {
      const data = {
        store_id: details.registeration_id,
      };
      const response = await postMethod(API_END_POINTS.INVENTORY_SEARCH, data);
      if (response) {
        if (response.data) {
          setInventoryData(response.data);
        }
      }
    } catch (err) {}
  };

  useEffect(() => {
    getInventoryListFromDB();
    getProductsFromInventory();
    getCountOfBills();
  }, []);

  const getCountOfBills = async () => {
    try {
      const data = {
        store_id: details.registeration_id,
      };
      const url = API_END_POINTS.POS_BILL_COUNT.replace(
        ":store_id",
        data.store_id
      );

      const response = await getMethod(url);
      let countBil: any;
      if (getLocalStorage(STORAGE_KEYS.LOGIN_TYPE) == "storeadmin") {
        countBil = response.data?.storeAdminCount;
      } else {
        countBil = response.data?.cafeAdminCount;
      }
      let bill_Invoice_Number = "";
      if (countBil == 0) {
        bill_Invoice_Number = "IN000001";
      } else if (countBil > 0 && countBil < 9) {
        bill_Invoice_Number = "IN00000" + (countBil + 1);
      } else if (countBil > 8 && countBil < 99) {
        bill_Invoice_Number = "IN0000" + (countBil + 1);
      } else if (countBil > 98 && countBil < 999) {
        bill_Invoice_Number = "IN000" + (countBil + 1);
      } else if (countBil > 998 && countBil < 9999) {
        bill_Invoice_Number = "IN00" + (countBil + 1);
      } else if (countBil > 9998 && countBil < 99999) {
        bill_Invoice_Number = "IN0" + (countBil + 1);
      } else {
        bill_Invoice_Number = "IN" + (countBil + 1);
      }
      setinVoiceNumber(bill_Invoice_Number);
    } catch (err) {}
  };

  const calculateClearButtonVisibility = () => {
    if (billData.status == "Done") {
      return false;
    } else return true;
  };

  const calculateSaveButtonVisibility = () => {
    if (billData.status == "Done" || billViewType == "create_bill") {
      return true;
    } else return false;
  };

  const clcProductEditFncAvailabilty = () => {
    if (
      billViewType == "create_bill" ||
      billViewType == "refund_bill" ||
      billData.status == "Pending"
    ) {
      return true;
    } else return false;
  };

  const clcAddProductFncAvailabilty = () => {
    if (billViewType == "create_bill" || billData.status == "Pending") {
      return true;
    } else return false;
  };

  const getInventoryListFromDB = async () => {
    let data = await inventory_db.data.toArray();
    if (data.length != 0) {
      setInventoryListData(data);
      setIsInventoryListLoading(false);
    } else {
      fetchInventoryToDB();

      getInventoryListFromDB();
    }
  };

  const noValidation = () => {
    const mobileRegEx = new RegExp("^[6-9][0-9]{9}$");
    let result = mobileRegEx.test(customerContact);

    return true;
  };

  const nameValidation = () => {
    const nameReg = new RegExp("^[A-Za-z- ]+$");

    let result = nameReg.test(customerName);

    return result;
  };

  const gstValidation = () => {
    if (isGSTAvailable) {
      if (customerGST.length > 0) {
        return true;
      } else return false;
    } else return true;
  };

  const handleCashCalc = () => {
    if (cashIn) {
      setCalcTrue(false);
      setAmountPaid(true);
      const paymentData: any = [{ Cash: cashIn }];
      setCreateBill({ ...createBill, list_of_paymentModes: paymentData });
    }
  };
  const storeInvoiceNoToBackend = async () => {
    let dataArray: any = [];
    const commission_values = [
      {
        _id: "",
        brokerage_head: "",
        brokerage_rate: "",
        state_name: "",
      },
    ];
    const state_commissions = [
      {
        _id: "",
        brokerage_head: "",
        brokerage_rate: "",
        state_name: "",
      },
    ];
    createBill.add_product.forEach((item: any) => {
      const obj = {
        product_name: item.product_name ? item.product_name : item.name,
        seller_id: item.seller_id ? item.seller_id : item.seller_id,
        category: item.category,
        inventory_id: item.inventory_id,
        // product: item.product_name ? item.product_name : item.name,
        price_unit: item.price ? item.price : item.unitPrice,
        brand: item.brand ? item.brand : "",
        qty: item.quantitySelected ? item.quantitySelected : item.qty,
        variant: "",
        discount: item.discount,
        hsn: item?.hsn,
        nafed_commission: item?.nafed_commission,
        nafed_percentage: item?.nafed_percentage,
        cafeDiscountedPrice: item?.discounted_price,
        storePay: item?.storePay,
        commission_values: item.commission_values,
        state_commissions: item.state_commissions,
        gst:
          item?.gst != "" && item?.gst != null && item?.gst != undefined
            ? item?.gst
            : 0,
        ...(getLocalStorage(STORAGE_KEYS.LOGIN_TYPE) == "storeadmin"
          ? { inventory_id: item?.parent_invnetory_ref || item?.inventory_id }
          : null),
        ...(getLocalStorage(STORAGE_KEYS.LOGIN_TYPE) == "cafeAdmin"
          ? { inventory_id: item.product_ref || item?.inventory_id }
          : null),
        // {getLocalStorage(STORAGE_KEYS.LOGIN_TYPE) == 'storeadmin'?{inventory_id: item.id}:''},
        unit: item.unit ? item.unit : "",
        customer_pay: item?.customer_pay,
        price: item?.price ? item?.price : 0,
        seller_name: item?.seller_details?.name_of_organization
          ? item?.seller_details?.name_of_organization
          : "",
        expiry_date: item?.expiry_date ? item?.expiry_date : "",
        seller_details: {
          email_id: item?.seller_details?.email_id
            ? item?.seller_details?.email_id
            : "",
          mobile: item?.seller_details?.mobile,
          name_of_organization: item?.seller_details?.name_of_organization,
          type_of_organization: item?.seller_details?.state,
          state: item?.seller_details?.state,
          city_district: item?.seller_details?.city_district,
          organization_address: item?.seller_details?.organization_address,
          pincode: item?.seller_details?.pincode,
          gst_number: item?.seller_details?.gst_number,
        },
        batch_no: item.batch_no
          ? item.batch_no
          : item?.ReorderedInventoryData?.batch_no,
        reorderedInventoryRef: item.reorderedInventoryRef
          ? item.reorderedInventoryRef
          : item._id,
        current_qty: item.current_qty,
        quantity_details: item.quantity_details,
        product_id: item._id,
        disableDecrement: item.disableDecrement,
        disableIncrement: item.disableIncrement,
        ...(getLocalStorage(STORAGE_KEYS.LOGIN_TYPE) == "storeadmin" && {
          sellerStateAbbreviation:
            item?.seller_state_abbreviation || item?.sellerStateAbbreviation,
        }),
        storeStateAbbreviation:
          stateAbbreviation || item?.storeStateAbbreviation,
      };
      dataArray.push(obj);
    });
    let loginTypeVariable;

    if (getLocalStorage(STORAGE_KEYS.LOGIN_TYPE)[0] == "storeadmin") {
      loginTypeVariable = "storeAdmin";
    } else if (getLocalStorage(STORAGE_KEYS.LOGIN_TYPE)[0] == "cafeAdmin") {
      loginTypeVariable = "cafeAdmin";
    }
    const sendPayload = [
      {
        invoice_info: {
          invoice_number:
            billViewType == "create_bill" && resumingData != undefined
              ? invoiceNo
              : inVoiceNumber,
          invoice_date: createBill.invoice_date,
        },
        login_type: getLocalStorage(STORAGE_KEYS.LOGIN_TYPE),
        // seller_details: {
        //   email_id: addedproductList[0]?.seller_details?.email_id
        //     ? addedproductList[0]?.seller_details?.email_id
        //     : "",
        //   mobile: addedproductList[0]?.seller_details?.mobile,
        //   name_of_organization:
        //     addedproductList[0]?.seller_details?.name_of_organization,
        //   type_of_organization: addedproductList[0]?.seller_details?.state,
        //   state: addedproductList[0]?.seller_details?.state,
        //   city_district: addedproductList[0]?.seller_details?.city_district,
        //   organization_address:
        //     addedproductList[0]?.seller_details?.organization_address,
        //   pincode: addedproductList[0]?.seller_details?.pincode,
        //   gst_number: addedproductList[0]?.seller_details?.gst_number,
        // },
        customer_details: {
          customer_name: createBill.customer_name,
          mobile_number: createBill.mobile_number,
          gst: createBill.gst,
          address: createBill.address,
        },
        add_product: dataArray,
        sub_total: createBill?.add_product
          ? calculateTotal(createBill.add_product, isCafe)
          : 0,
        tax: calculatePerItemTax(createBill?.add_product, isCafe),
        discount: createBill.discount,
        hsn: createBill?.hsn,
        nafed_commisiion: createBill?.nafed_commission,
        nafed_percentage: createBill?.nafed_percentage,
        cafeDiscountedPrice: createBill?.cafeDiscountedPrice,
        storePay: createBill?.storePay,
        total_amount: calculateoverallItemTax(createBill.add_product, isCafe),
        list_of_paymentModes:
          payment_status_ref.current != PAYMENT_STATUS.PENDING
            ? createBill.list_of_paymentModes
            : [],
        public_note: createBill.public_note,
        internal_note: createBill.internal_note,
        store_id: details.registeration_id,
        store_name: details.name_of_organization,
        seller_id: details.seller_id,
        payment_status: payment_status_ref.current,
        razorpay_order_id: createBill.razorpay_order_id,
        razorpay_payment_id: createBill.razorpay_payment_id,
        orderStore_type: loginTypeVariable,
        storeStateAbbreviation: stateAbbreviation,
        state: details.location.state,
      },
    ];
    try {
      if (resumingData) {
        await putUpdateBill([
          {
            ...resumingData,
            add_product: dataArray,
            payment_status: payment_status_ref.current,
            list_of_paymentModes:
              payment_status_ref.current != PAYMENT_STATUS.PENDING
                ? createBill.list_of_paymentModes
                : [],
            sub_total: createBill?.add_product
              ? calculateTotal(createBill.add_product, isCafe)
              : 0,
            tax: calculatePerItemTax(createBill?.add_product, isCafe),
            discount: createBill.discount,
            total_amount: calculateoverallItemTax(
              createBill.add_product,
              isCafe
            ),
            public_note: createBill.public_note,
            internal_note: createBill.internal_note,
          },
        ]);
      }
      // call post method
      else {
        const response: any = await postMethod(
          API_END_POINTS.CREATE_BILL,
          sendPayload
        );
        if (response.code === 200 && response.data.length > 0) {
          setBillCreated(true);
        }
      }
      // clear the state

      resetOrClearBill();

      if (payment_status_ref.current == PAYMENT_STATUS.PAID) {
        toast.success("Success", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (err) {}
  };
  const handleSaveAction = () => {
    /* ------------- save to bill history with payment status - pending  ------------ */
    let onlineStatus = navigator.onLine;
    // when it is online, send data to backend
    if (onlineStatus) {
      storeInvoiceNoToBackend();
    }
    // save in indexDB
    else {
      let data = {
        invoiceNo: invoiceNo,
        date: new Date().toLocaleString(),
        contactNo: customerContact,
        customerName: customerName,
        customerAdd: customerAddress,
        publicNotes: publicNote,
        internalNotes: internalNote,
        status: payment_status_ref.current,
        // paymentMethod: (payment_status_ref.current != PAYMENT_STATUS.PENDING ? createBill.payment_method : ''),
        list_of_paymentModes:
          payment_status_ref.current != PAYMENT_STATUS.PENDING
            ? createBill.list_of_paymentModes
            : "",
        total: calculateTotalAmount(addedproductList),
        productsDetail: {
          subTotal: calculateSubTotal(addedproductList),
          discount: calculateTotalDiscount(addedproductList),

          tax: calculateTotalTax(addedproductList),
          totalAmount: calculateTotalAmount(addedproductList),
          addedProducts: addedproductList,
        },
      };

      switch (true) {
        // if it is create bill
        case billViewType == BILL_VIEW_TYPES.CREATE_BILL:
          // save data in indexDB
          bill_history_db.data.add(data);
          toastNotifications.success("Bill Saved");
          // clear the state
          resetOrClearBill();
          break;
        // bill history
        case billViewType == BILL_VIEW_TYPES.BILL_HISTORY:
          // update the bill history indexDB
          bill_history_db.data
            .update(billData.invoiceNo, data)
            .then(() => {
              toastNotifications.success("Bill Updated");
              setAddedProductList([]);
              setCustomerContact("");
              setPublicNote("");
              setInternalNote("");
              setCustomerGST("");
              setCustomerAddress("");
              setCustomerName("");
              setCashIn("");
              setCalcTrue(false);
              setPaymentMethod([]);
            })
            .catch((error: any) => {});
          break;
      }
    }
  };
  const gstvalidation2 = (data: any) => {
    const gstRegEx = new RegExp(
      "^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$"
    );
    let result = gstRegEx.test(data);
    return result;
  };
  const mobile_number_Validation = (data: any) => {
    const mobileRegEx = new RegExp("^[6-9][0-9]{9}$");
    let result = mobileRegEx.test(data);
    return result;
  };
  const [checkboxValues, setCheckboxValues] = useState({
    [PAYMENTMETHOD.CASH]: false,
    [PAYMENTMETHOD.UPI]: false,
    [PAYMENTMETHOD.CC_DC]: false,
  });
  // when user click, confirm button
  const onConfirmHandler = () => {
    // check wether customername and mobile number, data is present or not
    const {
      customer_name,
      isGstChecked,
      isHomeDeliveryChecked,
      mobile_number,
      gst,
      address,
    } = createBill;
    // wether customer name is present or not
    const isCustomerNameEmpty = customer_name == "";
    // // const isMobileNumberEmpty = mobile_number == ""
    const isMobileNumberEmpty = mobile_number_Validation(mobile_number);
    // if it is checked, then check wether gst is empty or not
    // const isGstExist = (isGstChecked ? gst != "" : true)
    const isGstExist = isGstChecked ? gstvalidation2(gst) : true;
    // if it is checked, then check wether address is empty or not
    const isAddressExist = isHomeDeliveryChecked ? address != "" : true;
    // check wether payment method, is selected or not
    const isPaymentMethodSelected = createBill.list_of_paymentModes.length == 0;
    const isSinglePaymentSuccess =
      paymentListLength == 1
        ? cashIn || upiPaymentSuccess || ccPaymentSuccess
        : false;
    const isDoublePaymentSuccess =
      paymentListLength == 2
        ? (amounts.Cash && upiPaymentSuccess) ||
          (amounts.Cash && ccPaymentSuccess) ||
          (ccPaymentSuccess && upiPaymentSuccess)
        : false;
    const isMultiplePaymentSuccess =
      paymentListLength == 3
        ? amounts.Cash && upiPaymentSuccess && ccPaymentSuccess
        : false;
    // // customer name should not be empty
    if (
      !isCustomerNameEmpty &&
      isMobileNumberEmpty &&
      isAddressExist &&
      isGstExist &&
      !isPaymentMethodSelected &&
      ((paymentListLength == 1 ? isSinglePaymentSuccess : "") ||
        (paymentListLength == 2 ? isDoublePaymentSuccess : "") ||
        (paymentListLength == 3 ? isMultiplePaymentSuccess : ""))
    ) {
      // payment status is paid
      payment_status_ref.current = PAYMENT_STATUS.PAID;

      handleSaveAction();
      toast.success("Bill Generated Successfully!", {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      setCheckboxValues({
        [PAYMENTMETHOD.CASH]: false,
        [PAYMENTMETHOD.UPI]: false,
        [PAYMENTMETHOD.CC_DC]: false,
        // Add more payment methods if needed
      });
      setPaymentMethod([]);
      setAmounts([]);
      setAmountPaid(false);
      setUpiPaymentSuccess(false);
      setCcPaymentSuccess(false);
      setItemCode("");
      setAddedReorderStockList([]);
      setSelectedOption(null);
    } else {
      // payment status is pending
      payment_status_ref.current = PAYMENT_STATUS.PENDING;

      let toastMessage = "";
      switch (true) {
        case isCustomerNameEmpty:
          toastMessage = "Enter customer name";
          break;
        // mobile number
        case !isMobileNumberEmpty:
          toastMessage =
            mobile_number == ""
              ? "Enter mobile number"
              : "Enter Valid Mobile Number";
          break;
        // gst field
        // if isGstExist return false, then show toast
        case !isGstExist:
          toastMessage = gst == "" ? "Enter GST" : "Enter Valid GST";
          break;
        // addres field
        case !isAddressExist:
          toastMessage = "Enter address";
          break;
        // payment method not seleceted
        case isPaymentMethodSelected:
          toastMessage = "Choose payement method";
          break;

        case !isSinglePaymentSuccess ||
          !isDoublePaymentSuccess ||
          !isMultiplePaymentSuccess:
          toastMessage = "Payement is not successfull";
          break;
      }
      // show toast message
      toastNotifications.warning(toastMessage);
    }
  };

  // when user click hold button
  const onHoldHandler = () => {
    // check wether customer_name and mobile number enter or not
    const iscustomerName = createBill.customer_name != "";
    const isMobileNumberExist = createBill.mobile_number != "";
    if (iscustomerName && isMobileNumberExist) {
      // payment status change to pending
      payment_status_ref.current = PAYMENT_STATUS.PENDING;
      // send data to backend
      handleSaveAction();
      setItemCode("");
      toastNotifications.warning("Pending");
    } else {
      let message = "";
      switch (true) {
        // customer name empty
        case createBill.customer_name == "":
          message = "Enter customer name";
          break;
        // mobile number empty
        case createBill.mobile_number == "":
          message = "Enter mobile number";
          break;
      }
      // show toast message
      toastNotifications.warning(message);
    }
  };
  const validInput = (inputValue: any) => {
    const inputTest = /^\d*\.?\d{0,2}$/.test(inputValue);
    return inputTest;
  };
  const handlePaymentOnchange = (method: any) => {
    if (paymentMethod.includes(method)) {
      setPaymentMethod(paymentMethod.filter((item: any) => item !== method));
    } else {
      setPaymentMethod([...paymentMethod, method]);
    }
  };
  useEffect(() => {
    if (addedproductList.length > 0) {
      let newCafeDetails: any[] = [
        {
          store_id: details?.registeration_id,
          customer_name: createBill.customer_name,
        },
      ];
      let newStoreDetails: any[] = [];
      addedproductList.forEach((newProduct: any, index: any) => {
        const existingSeller = newStoreDetails.find(
          (item) => item.seller_id === newProduct.seller_id
        );
        if (!existingSeller) {
          const newData = {
            store_id: index === 0 ? newProduct.store_id : undefined,
            customer_name: createBill.customer_name,
            seller_id: newProduct.seller_id,
            amount: newProduct?.customer_pay,
          };
          newStoreDetails.push(newData);
        }
      });
      const combinedDetails = loginType.includes("storeadmin")
        ? newStoreDetails
        : newCafeDetails;
      // Update storeDetails after the loop is completed
      setStoreDetails(combinedDetails);
    }
  }, [addedproductList]);

  useEffect(() => {
    setStoreDetails((prevStoreDetails: any) =>
      prevStoreDetails.map((prevData: any) => ({
        ...prevData,
        customer_name: createBill.customer_name,
      }))
    );
  }, [createBill.customer_name]);

  const handleProceed = (method: any) => {
    const { customer_name, mobile_number } = createBill;
    const isCustomerNameEmpty = customer_name == "";
    const isMobileNumberEmpty = mobile_number_Validation(mobile_number);
    if (!isCustomerNameEmpty && isMobileNumberEmpty) {
      //payload based on selected payment methods and amounts
      if (paymentMethod.length == 1 && method == "UPI") {
        openRazorPay(totalAmountCalc, method, storeDetails);
        setAmounts({ ...amounts, UPI: totalAmountCalc });
      }
      if (paymentMethod.length == 1 && method == "CC/DC") {
        openRazorPay(totalAmountCalc, method, storeDetails);
        setAmounts({ ...amounts, CC_DC: totalAmountCalc });
      }
      if (
        paymentMethod.length == 2 &&
        paymentMethod.includes("UPI") &&
        paymentMethod.includes("Cash")
      ) {
        openRazorPay(amounts.UPI, "UPI", storeDetails);
      } else if (
        paymentMethod.length == 2 &&
        paymentMethod.includes("CC/DC") &&
        paymentMethod.includes("Cash")
      ) {
        openRazorPay(amounts.CC_DC, "CC/DC", storeDetails);
      }
      if (
        paymentMethod.length == 2 &&
        paymentMethod.includes("CC/DC") &&
        paymentMethod.includes("UPI") &&
        method == "UPI"
      ) {
        openRazorPay(amounts.UPI, method, storeDetails);
      } else if (
        paymentMethod.length == 2 &&
        paymentMethod.includes("CC/DC") &&
        paymentMethod.includes("UPI") &&
        method == "CC/DC"
      ) {
        openRazorPay(amounts.CC_DC, method, storeDetails);
      }
      if (paymentMethod.length == 3 && method == "UPI") {
        openRazorPay(amounts.UPI, method, storeDetails);
      } else if (paymentMethod.length == 3 && method == "CC/DC") {
        openRazorPay(amounts.CC_DC, method, storeDetails);
      }
    } else {
      let toastMessage = "";
      switch (true) {
        case isCustomerNameEmpty:
          toastMessage = "Enter customer name";
          break;
        // mobile number
        case !isMobileNumberEmpty:
          toastMessage =
            mobile_number == ""
              ? "Enter mobile number"
              : "Enter Valid Mobile Number";
          break;
      }
      // show toast message
      toastNotifications.warning(toastMessage);
    }
  };
  const handlePay = () => {
    const list_of_paymentModes = paymentMethod.map((method: any) => {
      const key = method === "CC/DC" ? "CC_DC" : method;
      return {
        [key]: amounts[key],
      };
    });
    setCreateBill({
      ...createBill,
      list_of_paymentModes: list_of_paymentModes,
    });
    setAmountPaid(true);
  };
  const paymentListLength = createBill.list_of_paymentModes.length;
  const confirmDisabledCondition: boolean = paymentListLength == 0;
  useEffect(() => {
    if (paymentMethod.length == 2) {
      if (amounts.Cash && amounts.UPI) {
        if (
          parseFloat(amounts.Cash) + parseFloat(amounts.UPI) <
          totalAmountCalc
        ) {
          setDoublePaymentError(true);
        } else {
          setDoublePaymentError(false);
        }
      } else if (amounts.Cash && amounts.CC_DC) {
        if (
          parseFloat(amounts.Cash) + parseFloat(amounts.CC_DC) <
          totalAmountCalc
        ) {
          setDoublePaymentError(true);
        } else {
          setDoublePaymentError(false);
        }
      } else if (amounts.UPI && amounts.CC_DC) {
        if (
          parseFloat(amounts.UPI) + parseFloat(amounts.CC_DC) <
          totalAmountCalc
        ) {
          setDoublePaymentError(true);
        } else {
          setDoublePaymentError(false);
        }
      }
    }
    if (paymentMethod.length == 3) {
      if (amounts.Cash && amounts.UPI && amounts.CC_DC) {
        if (
          parseFloat(amounts.Cash) +
            parseFloat(amounts.UPI) +
            parseFloat(amounts.CC_DC) <
          totalAmountCalc
        ) {
          setMultiplePaymentError(true);
        } else {
          setMultiplePaymentError(false);
        }
      }
    }
  }, [amounts]);
  const totalPayment = createBill.list_of_paymentModes
    .reduce((total: any, payment: any) => {
      const paymentValue = parseFloat(Object.values(payment)[0] as string) || 0;
      return total + paymentValue;
    }, 0)
    .toFixed(2);
  const [qtySelected, setQtySelected] = useState();

  const handleBarcodeOrValueRead = async (inputValue: any) => {
    if (inputValue != "" && inputValue?.length > 0) {
      try {
        const data = {
          barCode: inputValue,
          store_id: details.registeration_id,
        };
        const response: any = await postMethod(
          API_END_POINTS.POST_BARCODE,
          data
        );

        if (response) {
          if (response.data) {
            let quantitySelected = response.meta_data.quantity;
            if (quantitySelected === 0) {
              quantitySelected = 1;
              setAddedReorderStockList(response.data[0].ReorderedInventoryData);
            }
            let responseData: any = {
              ...response.data[0],
              quantitySelected: quantitySelected,
            };
            if (isStore) {
              setQtySelected(quantitySelected);
              setInventoryId(response.data[0]?._id);
            } else {
              setCreateBill((data: any) => ({
                ...data,
                add_product: [...data.add_product, responseData],
              }));
            }
          }
        }
        if (response && response.data && response.data.length > 0) {
          const quantitySelected = response.meta_data.quantity;
          const firstselect = response.data[0].ReorderedInventoryData;
          let remainingQuantity = quantitySelected;
          firstselect.forEach((item: any) => {
            if (item.current_qty > 0 && remainingQuantity > 0) {
              let updatedQuantitySelected = item.current_qty;
              if (remainingQuantity < item.current_qty) {
                updatedQuantitySelected = remainingQuantity;
              }
              let reorderedInventoryRef = item._id;
              setreorderInventoryId(reorderedInventoryRef);
              const prevQty = item.current_qty - updatedQuantitySelected;
              const addedQty = updatedQuantitySelected - item.current_qty;
              const storePay = item.storePay;

              const responseData: any = {
                ...response.data[0],
                ReorderedInventoryData: item,
                quantitySelected: updatedQuantitySelected,
                prevQty: prevQty,
                addedQty: addedQty,
                disableDecrement: true,
                disableIncrement: true,
                storePay: storePay,
                reorderedInventoryRef: reorderedInventoryRef,
                parent_invnetory_ref: item?.parent_invnetory_ref,
              };
              const isExistAddProduct = createBill?.add_product?.some(
                (product: any) =>
                  product?._id == response.data[0]?._id ||
                  product?.product_id == response.data[0]?._id
              );
              if (isExistAddProduct) {
                toast.error("Product is already added!", {
                  position: "top-center",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "light",
                });
              } else {
                setCreateBill((data: any) => ({
                  ...data,
                  add_product: [...data.add_product, responseData],
                }));
              }
              remainingQuantity -= updatedQuantitySelected;
              if (remainingQuantity <= 0) {
                return;
              }
            }
          });
        }
      } catch (err) {}
    } else {
      setAddedReorderStockList([]);
      setSelectedOption(null);
    }
  };

  const options = addedReorderStockList
    ?.filter((product: Product) => product.current_qty > 0)
    .map((product: Product) => {
      const formattedDate = new Date(product.expiry_date).toLocaleDateString();
      return {
        value: product._id,
        label: `Stock : ${product.batch_no} - Quantity : ${
          product.current_qty
        } - Price : ${product?.customer_pay?.toFixed(
          2
        )} - Expiry Date : ${formattedDate}`,
      };
    });

  const handleSelectChange = (selected: any) => {
    setSelectedOption(selected);

    if (selected != null) {
      const filteredSelectedData: any = addedReorderStockList.filter(
        (ele: any) => {
          return ele._id === selected.value;
        }
      );
      const reorderedInventoryRef = selected.value;
      const isExistAddProduct = createBill?.add_product?.some(
        (product: any) =>
          product?._id == selected?.value ||
          product?.product_id == selected?.value
      );
      if (isExistAddProduct) {
        toast.error("Product is already added!", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      } else {
        setreorderInventoryId(reorderedInventoryRef);
        setCreateBill((data: any) => ({
          ...data,
          add_product: [
            ...data.add_product,
            {
              ...filteredSelectedData[0],
              quantitySelected: qtySelected,
              reorderedInventoryRef: reorderedInventoryRef,
            },
          ],
        }));
      }
    } else {
      setCreateBill((data: any) => ({
        ...data,
        add_product: [...data.add_product],
      }));
    }
  };
  return (
    <div className="bill-container">
      <ToastContainer
        autoClose={1}
        hideProgressBar={true}
        closeButton={false}
      />
      <div className="product-enter-details-container">
        <div className="product-enter-details-sec">
          <div>
            <span className="product-enter-details-sec-header product-subdetails-sec">
              Customer Details:
            </span>
            {billViewType !== "bill_history" ? (
              <div>
                <div className="d-flex flex-row">
                  <input
                    disabled={billViewType == BILL_VIEW_TYPES.BILL_HISTORY}
                    onChange={(e) => {
                      let isChecked = e.target.checked;
                      setIsGSTAvailable(!isGSTAvailable);
                      // if checkbox is false then clear the gst data
                      setCreateBill({
                        ...createBill,
                        isGstChecked: isChecked,
                        gst: isChecked ? createBill.gst : "",
                      });
                    }}
                    type={"checkbox"}
                    checked={createBill.isGstChecked}
                  />
                  <span
                    style={{
                      fontSize: "12px",
                      marginRight: "10px",
                      paddingLeft: "5px",
                    }}
                  >
                    GST Available ?
                  </span>
                </div>
                <div className="d-flex flex-row">
                  <input
                    disabled={billViewType == BILL_VIEW_TYPES.BILL_HISTORY}
                    type={"checkbox"}
                    onChange={(e) => {
                      let isChecked = e.target.checked;
                      setIsHomeDelivery(!isHomeDelivery);
                      setCreateBill({
                        ...createBill,
                        isHomeDeliveryChecked: isChecked,
                        address: isChecked ? createBill.address : "",
                      });
                    }}
                    checked={createBill.isHomeDeliveryChecked}

                    // checked={billViewType == "bill_history" ? (billData?.customer_details?.address ? true : isHomeDelivery): isHomeDelivery}
                  />
                  <span style={{ fontSize: "12px", paddingLeft: "5px" }}>
                    Home Delivery ?
                  </span>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>

          <div className="product-subdetails-sec">
            <div className="product-subdetails-item-sec">
              <span>
                Customer Name <span style={{ color: "red" }}>*</span>
              </span>
              <input
                type={"text"}
                disabled={billViewType == BILL_VIEW_TYPES.BILL_HISTORY}
                value={createBill.customer_name}
                onChange={(e) => {
                  const value = e.target.value;
                  const trimmedValue = value.replace(/[^a-zA-Z\s]/g, "");
                  setCustomerName(trimmedValue);
                  setCreateBill({ ...createBill, customer_name: trimmedValue });
                }}
              />
            </div>
            <div className="product-subdetails-item-sec">
              <span>
                Mobile Number (+91)<span style={{ color: "red" }}>*</span>
              </span>
              <input
                type="tel"
                maxLength={10}
                disabled={billViewType == BILL_VIEW_TYPES.BILL_HISTORY}
                onChange={(e) => {
                  const value = e.target.value;
                  const sanitizedValue = value.replace(/\D/g, "");
                  const truncatedValue = sanitizedValue.slice(0, 10);
                  setCreateBill({
                    ...createBill,
                    mobile_number: truncatedValue,
                  });
                }}
                value={createBill.mobile_number}
              />
            </div>

            {/* when user select, isgstavailable checkbox then it will be appear */}
            {createBill.isGstChecked && (
              <div className="product-subdetails-item-sec">
                <span>GST</span>
                <input
                  type={"text"}
                  maxLength={15}
                  disabled={billViewType == BILL_VIEW_TYPES.BILL_HISTORY}
                  value={createBill.gst}
                  onChange={(e) => {
                    let value = e.target.value;
                    value = value.replace(/[^a-zA-Z0-9]/g, "");
                    value = value.substring(0, 15);
                    setCreateBill({ ...createBill, gst: value });
                  }}
                />
              </div>
            )}
            {/* when user selected, home delivery checkbox then it will appear */}
            {createBill.isHomeDeliveryChecked && (
              <div className={`product-subdetails-item-sec`}>
                <span>Address</span>
                <textarea
                  disabled={billViewType == BILL_VIEW_TYPES.BILL_HISTORY}
                  value={createBill.address}
                  onChange={(e) => {
                    const value = e.target.value;
                    // setCustomerAddress(value)
                    setCreateBill({ ...createBill, address: value });
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      {clcAddProductFncAvailabilty() && (
        <div
          className={`add-product-sec ${
            isStore ? "" : "d-flex justify-content-end"
          } `}
        >
          {isStore && (
            <div className="itemcode-barcode">
              <input
                placeholder="Enter Product CODE or Scan Product"
                className="itemcode-barcode-input"
                onChange={(e) => {
                  handleBarcodeOrValueRead(e.target.value);
                  setItemCode(e.target.value);
                }}
                value={itemCode}
              />
            </div>
          )}
          {/* add product button */}
          {isStore && (
            <div className="stock-dropdown mt-1">
              <Select
                className="add-batch-sec w-80"
                value={selectedOption}
                onChange={handleSelectChange}
                options={options}
                isSearchable={true}
                placeholder="Select Stock"
                isClearable={true}
              />
            </div>
          )}
          <div
            onClick={onAddProductButtonHandler}
            className="add-new-product-sec"
          >
            <img src={addIcon} alt="add_icon" />
            <span>Add Product</span>
          </div>
        </div>
      )}

      {/* display products data */}
      <div className="added-products-details-sec">
        <ProductTable
          // addedproductList={addedproductList}
          // setAddedProductList={setAddedProductList}
          productDeletedToast={toastNotifications.warning}
          clcProductEditFncAvailabilty={clcProductEditFncAvailabilty}
          totalCalculation={totalCalculation}
          billViewType={billViewType}
          setToBePaid={setToBePaid}
          setDeletedData={setDeletedData}
          billData={
            billViewType == BILL_VIEW_TYPES.BILL_HISTORY ? createBill : ""
          }
          // set create bill
          setCreateBill={setCreateBill}
          createBill={createBill}
          onClearBillHandler={onClearBillHandler}
        />
      </div>

      {/* if we have products then display internal and public note */}

      {/* Split payment */}
      {billViewType == "create_bill" &&
      createBill.add_product.length > 0 &&
      paymentMethod?.length > 0 ? (
        <>
          {amountPaid == false ? (
            <div className="cash-input-div">
              <div>
                {/* single selection */}
                {paymentMethod.length == 1 && paymentMethod.includes("UPI") && (
                  <>
                    <div className="d-flex cash-input-div mb-2">
                      <div className="mt-1">
                        Payable amount :{" "}
                        {calculateoverallItemTax(
                          createBill.add_product,
                          isCafe
                        )}
                      </div>
                      <button
                        className="mx-2 btn btn-primary btn-sm"
                        onClick={() => {
                          handleProceed("UPI");
                        }}
                        disabled={upiPaymentSuccess}
                      >
                        {upiPaymentSuccess ? "Paid" : "Pay with UPI"}
                      </button>
                    </div>
                  </>
                )}
                {paymentMethod.length == 1 &&
                  paymentMethod.includes("CC/DC") && (
                    <>
                      <div className="d-flex cash-input-div mb-2">
                        <div className="mt-1">
                          Payable amount :{" "}
                          {calculateoverallItemTax(
                            createBill.add_product,
                            isCafe
                          )}
                        </div>
                        <button
                          className="mx-2 btn btn-primary btn-sm"
                          onClick={() => {
                            handleProceed("CC/DC");
                          }}
                          disabled={ccPaymentSuccess}
                        >
                          {ccPaymentSuccess ? "Paid" : "Pay with CC/DC"}
                        </button>
                      </div>
                    </>
                  )}
                {paymentMethod.length == 1 &&
                  paymentMethod.includes("Cash") && (
                    <div className="cash-func">
                      {calcTrue ? (
                        <div className="cash-input-div">
                          <div className="product-subdetails-item-sec">
                            <label>Cash</label>
                            <input
                              name="cashin"
                              value={cashIn}
                              type="number"
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                if (validInput(inputValue) == true) {
                                  setCashIn(inputValue);
                                }
                              }}
                              placeholder="Enter the amount"
                            />
                            {/* display error */}
                            {cashIn && cashIn < totalAmountCalc ? (
                              <span
                                className="text-danger text-capitalize mt-1"
                                style={{ fontSize: "12px" }}
                              >
                                amount should be greater than {totalAmountCalc}
                              </span>
                            ) : null}
                          </div>
                          <div>
                            <Button
                              className="calc-btn text-center text-capitalize d-flex justify-content-center align-items-center"
                              disabled={cashIn < totalAmountCalc}
                              onClick={handleCashCalc}
                            >
                              Calculate
                            </Button>
                          </div>
                        </div>
                      ) : cashIn ? (
                        <div className="calc-data">
                          <div className="calc-data-item">
                            Cash-in :{" "}
                            <span className="calc-data-item-value">
                              {cashIn}
                            </span>{" "}
                          </div>
                          <div className="calc-data-item">
                            Paid :{" "}
                            <span className="calc-data-item-value">
                              {totalAmountCalc}
                            </span>{" "}
                          </div>
                          <div className="calc-data-item">
                            Cash-out :{" "}
                            <span className="calc-data-item-value">
                              {totalAmountCalc}
                              {/* {cashIn &&
                                (
                                  Number(cashIn) - createBill.total_amount
                                ).toFixed(2)} */}
                            </span>{" "}
                          </div>
                        </div>
                      ) : null}
                    </div>
                  )}
                {/* double selection */}
                {paymentMethod.length == 2 &&
                  paymentMethod.includes("Cash") && (
                    <>
                      <div className="d-flex cash-input-div">
                        <span className="px-3">Split Amount :</span>
                        {/* {paymentMethod.map((method: any) => ( */}
                        <div
                          key={"Cash"}
                          className="product-subdetails-item-sec"
                        >
                          <label>{"Cash"} </label>
                          <input
                            className="payment-inputs"
                            name={"Cash"}
                            type="number"
                            value={amounts.Cash}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (validInput(inputValue) == true) {
                                setAmounts({
                                  ...amounts,
                                  Cash: inputValue,
                                });
                              }
                            }}
                            placeholder={`Enter the amount`}
                          />
                        </div>
                        {paymentMethod.includes("UPI") && (
                          <div
                            key={"UPI"}
                            className="product-subdetails-item-sec"
                          >
                            <label>{"UPI"} </label>
                            <input
                              className="payment-inputs"
                              name={"UPI"}
                              type="number"
                              value={amounts.UPI}
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                if (validInput(inputValue) == true) {
                                  setAmounts({
                                    ...amounts,
                                    UPI: inputValue,
                                  });
                                }
                              }}
                              placeholder={`Enter the amount`}
                            />
                          </div>
                        )}
                        {paymentMethod.includes("CC/DC") && (
                          <div
                            key={"CC/DC"}
                            className="product-subdetails-item-sec"
                          >
                            <label>{"CC/DC"} </label>
                            <input
                              className="payment-inputs"
                              name={"CC/DC"}
                              type="number"
                              value={amounts.CC_DC}
                              onChange={(e) => {
                                const inputValue = e.target.value;
                                if (validInput(inputValue) == true) {
                                  setAmounts({
                                    ...amounts,
                                    CC_DC: inputValue,
                                  });
                                }
                              }}
                              placeholder={`Enter the amount`}
                            />
                          </div>
                        )}
                        {/* ))} */}
                        <button
                          className="mx-2 btn btn-primary btn-sm"
                          onClick={() => handleProceed("")}
                          disabled={
                            !(
                              (amounts.UPI && amounts.Cash) ||
                              (amounts.CC_DC && amounts.Cash)
                            ) ||
                            upiPaymentSuccess ||
                            ccPaymentSuccess ||
                            doublePaymentError
                          }
                        >
                          {(paymentMethod.includes("UPI") &&
                            upiPaymentSuccess) ||
                          (paymentMethod.includes("CC/DC") && ccPaymentSuccess)
                            ? "Paid"
                            : paymentMethod.includes("UPI")
                            ? "Pay With UPI"
                            : paymentMethod.includes("CC/DC")
                            ? "Pay With CC/DC"
                            : ""}
                        </button>
                      </div>
                      <div className="d-flex justify-content-center mb-2">
                        {doublePaymentError ? (
                          <span className="text-danger text-capitalize mt-1 amount-error">
                            amount should be greater than {totalAmountCalc}
                          </span>
                        ) : null}
                      </div>
                    </>
                  )}
                {paymentMethod.length == 2 &&
                  paymentMethod.includes("UPI") &&
                  paymentMethod.includes("CC/DC") && (
                    <>
                      <div className="d-flex cash-input-div">
                        <span className="px-3">Split Amount :</span>
                        <div
                          key={"UPI"}
                          className="product-subdetails-item-sec"
                        >
                          <label>{"UPI"} </label>
                          <input
                            className="payment-inputs"
                            name={"UPI"}
                            type="number"
                            value={amounts.UPI}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (validInput(inputValue) == true) {
                                setAmounts({
                                  ...amounts,
                                  UPI: inputValue,
                                });
                              }
                            }}
                            placeholder={`Enter the amount`}
                          />
                        </div>
                        <div
                          key={"CC/DC"}
                          className="product-subdetails-item-sec"
                        >
                          <label>{"CC/DC"} </label>
                          <input
                            className="payment-inputs"
                            name={"CC/DC"}
                            type="number"
                            value={amounts.CC_DC}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (validInput(inputValue) == true) {
                                setAmounts({
                                  ...amounts,
                                  CC_DC: inputValue,
                                });
                              }
                            }}
                            placeholder={`Enter the amount`}
                          />
                        </div>
                        {/* ))} */}
                        <button
                          className="mx-2 btn btn-primary btn-sm"
                          onClick={() => {
                            handleProceed("UPI");
                          }}
                          disabled={
                            !amounts.UPI ||
                            upiPaymentSuccess ||
                            doublePaymentError
                          }
                        >
                          {upiPaymentSuccess ? "Paid" : "Pay with UPI"}
                        </button>
                        <button
                          className="mx-2 btn btn-primary btn-sm"
                          onClick={() => handleProceed("CC/DC")}
                          disabled={
                            !amounts.CC_DC ||
                            ccPaymentSuccess ||
                            doublePaymentError
                          }
                        >
                          {ccPaymentSuccess ? "Paid" : "Pay with CC/DC"}
                        </button>
                      </div>
                      <div className="d-flex justify-content-center mb-2">
                        {doublePaymentError ? (
                          <span className="text-danger text-capitalize mt-1 amount-error">
                            amount should be greater than {totalAmountCalc}
                          </span>
                        ) : null}
                      </div>
                    </>
                  )}
                {/* Triple selection */}
                {paymentMethod.length === 3 && (
                  <>
                    <div className="d-flex cash-input-div">
                      <span className="px-3">Split Amount :</span>
                      {/* {paymentMethod.map((method: any) => ( */}
                      <div key={"Cash"} className="product-subdetails-item-sec">
                        <label>{"Cash"} </label>
                        <input
                          className="payment-inputs"
                          name={"Cash"}
                          type="number"
                          value={amounts.Cash}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            if (validInput(inputValue) == true) {
                              setAmounts({
                                ...amounts,
                                Cash: inputValue,
                              });
                            }
                          }}
                          placeholder={`Enter the amount`}
                        />
                      </div>
                      <div key={"UPI"} className="product-subdetails-item-sec">
                        <label>{"UPI"} </label>
                        <input
                          className="payment-inputs"
                          name={"UPI"}
                          value={amounts.UPI}
                          type="number"
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            if (validInput(inputValue) == true) {
                              setAmounts({
                                ...amounts,
                                UPI: inputValue,
                              });
                            }
                          }}
                          placeholder={`Enter the amount`}
                        />
                      </div>
                      <div
                        key={"CC/DC"}
                        className="product-subdetails-item-sec"
                      >
                        <label>{"CC/DC"} </label>
                        <input
                          className="payment-inputs"
                          name={"CC/DC"}
                          type="number"
                          value={amounts.CC_DC}
                          onChange={(e) => {
                            const inputValue = e.target.value;
                            if (validInput(inputValue) == true) {
                              setAmounts({
                                ...amounts,
                                CC_DC: inputValue,
                              });
                            }
                          }}
                          placeholder={`Enter the amount`}
                        />
                      </div>
                      {/* ))} */}
                      <button
                        className="mx-2 btn btn-primary btn-sm"
                        onClick={() => handleProceed("UPI")}
                        disabled={
                          !amounts.UPI ||
                          upiPaymentSuccess ||
                          multiplePaymentError
                        }
                      >
                        {upiPaymentSuccess ? "Paid" : "Pay with UPI"}
                      </button>
                      <button
                        className="mx-2 btn btn-primary btn-sm"
                        onClick={() => handleProceed("CC/DC")}
                        disabled={
                          !amounts.CC_DC ||
                          ccPaymentSuccess ||
                          multiplePaymentError
                        }
                      >
                        {ccPaymentSuccess ? "Paid" : "Pay with CC/DC"}
                      </button>
                    </div>
                    <div className="d-flex justify-content-center mb-2">
                      {multiplePaymentError ? (
                        <span className="text-danger text-capitalize mt-1 amount-error">
                          amount should be greater than {totalAmountCalc}
                        </span>
                      ) : null}
                    </div>
                  </>
                )}
              </div>
              <div className="mb-2">
                {(paymentMethod.length == 1
                  ? upiPaymentSuccess || ccPaymentSuccess
                  : paymentMethod.length == 2
                  ? (amounts.Cash && upiPaymentSuccess) ||
                    (amounts.Cash && ccPaymentSuccess) ||
                    (upiPaymentSuccess && ccPaymentSuccess)
                  : paymentMethod.length == 3
                  ? amounts.Cash && upiPaymentSuccess && ccPaymentSuccess
                  : "") && (
                  <button
                    className="mx-2 btn btn-primary btn-sm"
                    onClick={handlePay}
                  >
                    Submit
                  </button>
                )}
              </div>
            </div>
          ) : (
            <>
              <div className="d-flex justify-content-end">
                {paymentListLength > 0 && (
                  <div className="calc-data">
                    <div className="calc-data-item">
                      {createBill.list_of_paymentModes.map((payment: any) => {
                        const paymentKey = Object.keys(payment)[0];
                        const paymentValue = payment[paymentKey];
                        return `${paymentKey}:  ${paymentValue + " "}`;
                      })}
                    </div>
                    <div className="calc-data-item">
                      Paid :{" "}
                      <span className="calc-data-item-value">
                        {totalPayment}
                      </span>{" "}
                    </div>
                    <div className="calc-data-item">
                      Cash-out :{" "}
                      <span className="calc-data-item-value">
                        {totalPayment &&
                          (parseFloat(totalPayment) - totalAmountCalc).toFixed(
                            2
                          )}
                      </span>{" "}
                    </div>
                  </div>
                )}
              </div>
            </>
          )}
        </>
      ) : null}

      {createBill.add_product.length > 0 && (
        <div
          className={
            billViewType == "refund_bill" ? "payment-draft-container" : "mb-2"
          }
        >
          {billViewType == "refund_bill" && (
            <input
              className="refund-comment-input"
              placeholder="Add Comment"
              value={refundComment}
              onChange={(e) => setRefundComment(e.target.value)}
              name="refund"
            />
          )}

          {/* payment options */}
          <div className="payment-draft-sec">
            <div className="note-sec">
              {createBill.add_product.length > 0 && (
                <>
                  <div className="product-subdetails-item-sec">
                    <span>Public Note</span>
                    <textarea
                      disabled={billViewType == BILL_VIEW_TYPES.BILL_HISTORY}
                      value={createBill.public_note}
                      onChange={(e) =>
                        setCreateBill({
                          ...createBill,
                          public_note: e.target.value,
                        })
                      }
                    />
                  </div>
                  <div className="product-subdetails-item-sec">
                    <span>Internal Note</span>
                    <textarea
                      disabled={billViewType == BILL_VIEW_TYPES.BILL_HISTORY}
                      value={createBill.internal_note}
                      onChange={(e) =>
                        setCreateBill({
                          ...createBill,
                          internal_note: e.target.value,
                        })
                      }
                    />
                  </div>
                </>
              )}
            </div>
            <div className="d-flex align-items-center">
              {Object.keys(PAYMENTMETHOD).map((option: string) => (
                <>
                  <input
                    key={PAYMENTMETHOD[option]}
                    disabled={
                      (billViewType == "refund_bill" ||
                        billViewType == "bill_history" ||
                        billData.status == "Done" ||
                        amountPaid) &&
                      true
                    }
                    hidden={billViewType == "bill_history"}
                    value={checkboxValues[PAYMENTMETHOD[option]].toString()}
                    onChange={(e) => {
                      const value = e.target.checked;
                      handlePaymentOnchange(PAYMENTMETHOD[option]);
                      setCheckboxValues((prevValues) => ({
                        ...prevValues,
                        [PAYMENTMETHOD[option]]: value,
                      }));
                      setCalcTrue(
                        PAYMENTMETHOD.CASH === PAYMENTMETHOD[option] ||
                          checkboxValues[PAYMENTMETHOD[option]] == true
                      );
                    }}
                    type={"checkbox"}
                    id={PAYMENTMETHOD[option]}
                  />
                  <label
                    htmlFor={PAYMENTMETHOD[option]}
                    className="checkbox-label"
                    hidden={billViewType == "bill_history"}
                  >
                    {PAYMENTMETHOD[option]}
                  </label>
                </>
              ))}
              {billViewType == BILL_VIEW_TYPES.BILL_HISTORY && (
                <>
                  <div className="mx-4">
                    Payment Methods:{" "}
                    <span className="gap-2">
                      {billData.list_of_paymentModes &&
                      billData.list_of_paymentModes.length !== 0
                        ? billData.list_of_paymentModes.map((payment: any) => {
                            const paymentKey = Object.keys(payment)[0];
                            const paymentValue = payment[paymentKey];
                            return `${paymentKey}: ${paymentValue + " "}`;
                          })
                        : ""}
                    </span>
                  </div>
                </>
              )}
            </div>
            {/* 
            {/* clear button */}
            {calculateClearButtonVisibility() &&
              billViewType === BILL_VIEW_TYPES.CREATE_BILL && (
                <Button
                  onClick={onClearBillHandler}
                  className="text-capitalize"
                  disabled={upiPaymentSuccess || ccPaymentSuccess || cashIn}
                >
                  Clear Bill
                </Button>
              )}

            {/* hold button */}
            {calculateSaveButtonVisibility() ? (
              <Button
                onClick={onHoldHandler}
                className="text-capitalize"
                disabled={upiPaymentSuccess || ccPaymentSuccess || cashIn}
              >
                {/* {billViewType == 'bill_history'
									? 'Update'
									: 'Hold'} */}
                hold
              </Button>
            ) : (
              ""
            )}

            {/* confirm button */}
            {billViewType == BILL_VIEW_TYPES.CREATE_BILL && (
              <Button
                onClick={onConfirmHandler}
                disabled={
                  confirmDisabledCondition ||
                  doublePaymentError ||
                  multiplePaymentError
                }
                className="text-capitalize"
              >
                confirm
              </Button>
            )}

            {/* pdf print */}
            {billViewType == BILL_VIEW_TYPES.BILL_HISTORY &&
              billData?.payment_method != "" &&
              billData.payment_status == PAYMENT_STATUS.PAID && (
                <PDFGenerate billData={billData} />
              )}

            {/* in bill history modal popup, if payment is empty then display this resume buttonI */}
            {billViewType == BILL_VIEW_TYPES.BILL_HISTORY &&
              (billData?.payment_method == "" ||
                billData.payment_status == PAYMENT_STATUS.PENDING) && (
                <button
                  onClick={() => {
                    navigate.push(ROUTES_PATH.CREATE_BILL, { billData });
                  }}
                >
                  Resume
                </button>
              )}
          </div>
        </div>
      )}

      {isAddProductModalOpen && (
        <AddProductModal
          productModalHeader={productModalHeader}
          isProductSearchable={isProductSearchable}
          setIsAddProductModalOpen={setIsAddProductModalOpen}
          productAddedSuccessToast={toastNotifications.success}
          setAddedProductList={setAddedProductList}
          isInventoryListLoading={isInventoryListLoading}
          inventoryListData={inventoryListData}
          productSelectedFromItemCode={productSelectedFromItemCode}
          setProductSelectedFromItemCode={setProductSelectedFromItemCode}
          setCreateBill={setCreateBill}
          setInventoryId={setInventoryId}
          addedproductList={addedproductList}
        />
      )}

      {upiFlag && qrImage ? (
        <Modal header={"QR CODE"} width={"30%"} setOpenModal={setUpiFlag}>
          <img src={qrImage} alt="QR CODE" style={{ maxHeight: "20%" }} />
          <button
            className="calc-btn text-center text-capitalize d-flex justify-content-center align-items-center"
            onClick={() => setUpiFlag(false)}
          >
            Pay
          </button>
        </Modal>
      ) : null}
    </div>
  );
}
