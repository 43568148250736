export const calculatePerItemTotal = (product: any) => {
	let gross =
		parseFloat(product.unitPrice) * parseFloat(product.quantitySelected);
	let grossDisc =
		parseFloat(product.discount) * parseFloat(product.quantitySelected);
	let netValue = gross - grossDisc;
	let netGst = netValue * (parseFloat(product.gst) / 100);
	let totalPay = netValue + netGst;
	return totalPay;
};

export const calculatePerItemSubTotal = (product: any) => {
	let gross =
		parseFloat(product.unitPrice) * parseFloat(product.quantitySelected);

	return gross;
};
export const calculatePerItemDiscount = (product: any) => {
	let disc =
		parseFloat(product.discount) * parseFloat(product.quantitySelected);

	return disc;
};
export const calculatePerItemTax = (product: any) => {
	let gross =
		parseFloat(product.unitPrice) * parseFloat(product.quantitySelected);
	let grossDisc =
		parseFloat(product.discount) * parseFloat(product.quantitySelected);
	let netValue = gross - grossDisc;
	let netGst = netValue * (parseFloat(product.gst) / 100);

	return netGst;
};

export const calculateTotalAmount = (productData: any) => {
	let total = 0;
	for (let i = 0; i < productData.length; i++) {
		total = total + calculatePerItemTotal(productData[i]);
	}
	return total.toFixed(2);
};

export const calculateSubTotal = (productData: any) => {
	let total = 0;
	for (let i = 0; i < productData.length; i++) {
		total = total + calculatePerItemSubTotal(productData[i]);
	}
	return total.toFixed(2);
};
export const calculateTotalDiscount = (productData: any) => {
	let total = 0;
	for (let i = 0; i < productData.length; i++) {
		total = total + calculatePerItemDiscount(productData[i]);
	}
	return total.toFixed(2);
};
export const calculateTotalTax = (productData: any) => {
	let total = 0;
	for (let i = 0; i < productData.length; i++) {
		total = total + calculatePerItemTax(productData[i]);
	}
	return total.toFixed(2);
};
