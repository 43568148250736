import React, { useState, useEffect } from 'react';
import Modal from '../../../../components/Basic/Modal';
import { productData } from '../../../../data/dummy/productData';
import { inventory_db, fetchInventoryToDB } from '../../../../data/indexedDB';
import SearchBar from '../../../../components/Basic/Search/SearchBar';

import { toastNotifications } from '../../../../helpers';

export default function AddProductModal(props: any) {
	const {
		setIsAddProductModalOpen,
		isProductSearchable,
		productModalHeader,
		productAddedSuccessToast,
		setAddedProductList,
		isInventoryListLoading,
		inventoryListData,
		setProductSelectedFromItemCode,
		productSelectedFromItemCode,
	} = props;
	const [productSearchString, setProductSearchString] = useState('');
	const [productSelected, setProductSelected] = useState(() => {
		if (!isProductSearchable) {
			return productSelectedFromItemCode;
		} else {
			return {};
		}
	});
	const [selectedProductQuantity, setSelectedProductQuantity] = useState(1);
	return (
		<Modal
			header={productModalHeader}
			width={'40%'}
			setOpenModal={setIsAddProductModalOpen}
		>
			{/* {productSearchString} */}
			{!isInventoryListLoading ? (
				<>
					{isProductSearchable && (
						<SearchBar
							placeholder='Enter product name'
							data={inventoryListData}
							setValue={setProductSelected}
						/>
					)}
					<div className='addproduct-container'>
						<div className='table-column-header'>
							<div className='table-item'>Category</div>
							<div className='table-item'>Product</div>
							<div className='table-item'>
								Price/unit(Rs)
							</div>
							<div className='table-item'>Brand</div>
						</div>
						{Object.keys(productSelected).length != 0 && (
							<div>
								<div
									style={{
										border: 'none',
									}}
									className='table-column-header'
								>
									<div className='addedproduct-item'>
										{productSelected.category}
									</div>
									<div className='addedproduct-item'>
										{productSelected.name}
									</div>
									<div className='addedproduct-item'>
										{productSelected.unitPrice}
									</div>
									<div className='addedproduct-item'>
										{productSelected.brand}
									</div>
								</div>

								<div className='added-product-variant'>
									<select

									// placeholder='Payment method'
									>
										<option value={''}>
											Select Variant
										</option>

										<option value='cc/dc'>
											Variant 1
										</option>
										<option value='upi'>
											Variant 2
										</option>
										<option value='internet'>
											Variant 3
										</option>
									</select>
								</div>
								<div className='added-product-quantity'>
									<span>Quantity</span>
									<div
										onClick={() => {
											if (
												selectedProductQuantity >=
												2
											) {
												setSelectedProductQuantity(
													selectedProductQuantity -
														1
												);
											}
										}}
									>
										<span
											style={{
												marginBottom: '4px',
											}}
										>
											-
										</span>
									</div>
									<input
										style={{ width: '10%' }}
										onChange={(e: any) =>
											setSelectedProductQuantity(
												e.target.value
											)
										}
										value={
											selectedProductQuantity
										}
										min={1}
										max={100}
										type={'number'}
										className='table-row-item-input'
									/>
									<div>
										<span
											style={{
												marginBottom: '4px',
											}}
											onClick={() =>
												setSelectedProductQuantity(
													selectedProductQuantity +
														1
												)
											}
										>
											+
										</span>
									</div>
								</div>
							</div>
						)}
					</div>

					<button
						className='add-to-bill-button'
						onClick={() => {
							if (
								Object.keys(productSelected).length != 0
							) {
								setAddedProductList(
									(prevArray: any) => [
										...prevArray,
										{
											...productSelected,
											quantitySelected:
												selectedProductQuantity,
										},
									]
								);
								setIsAddProductModalOpen(false);
								productAddedSuccessToast(
									'Product Added'
								);
							}
						}}
						style={
							Object.keys(productSelected).length == 0
								? {
										backgroundColor: '#7aabe2',
								  }
								: {
										backgroundColor: '#171f68',
								  }
						}
					>
						Add to Bill
					</button>
				</>
			) : (
				<div> Loading </div>
			)}
		</Modal>
	);
}
